import React from "react";

import { Route, Switch, Redirect } from "react-router-dom";

import Header from "./components/Header";
import LeftMenu from "./components/LeftMenu";
import Footer from "./components/Footer";
import MobileHeader from "./components/MobileHeader";
import UserPanel from "./components/UserPanel";
import ScrollTop from "./components/ScrollTop";

import Overview from "./Overview";
import Sales from "./Sales";
import ManageVendors from "./ManageVendors";
import ManageDrivers from "./ManageDrivers";
import ManageCustomers from "./ManageCustomers";
import OrderHistory from "./OrderHistory";
import CancelledOrders from "./CancelledOrders";
import ProductLibrary from "./ProductLibrary";
import DeliverySettings from "./DeliverySettings";
import PaymentsHistory from "./PaymentsHistory";
import DriversPayments from "./DriversPayments";
import Settings from "./Settings";

import AddDriver from "./AddDriver";
import AddStore from "./AddStore";

import DriverDetails from "./DriverDetails";
import VendorDetails from "./VendorDetails";
import VendorOrdersTable from "./components/tables/manage-vendors/VendorOrdersTable";
import StoreDetails from "./StoreDetails";
import CustomerDetails from "./CustomerDetails";

import StoreSettings from "./StoreSettings";
import AddVendorSettings from "./AddVendorSettings";
import EditBranding from "./EditBranding";

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        <MobileHeader />
        <div className="d-flex flex-column flex-root">
          <div className="d-flex flex-row flex-column-fluid page">
            <LeftMenu />

            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="kt_wrapper"
            >
              <Header />

              <div
                className="content d-flex flex-column flex-column-fluid"
                id="kt_content"
                style={{ transform: "translateY(-4rem)" }}
              >
                <div className="d-flex flex-column-fluid">
                  <Switch>
                    <Route exact path="/" component={Overview} />

                    <Route exact path="/sales" component={Sales} />

                    <Route
                      exact
                      path="/manage-vendors"
                      component={ManageVendors}
                    />
                    <Route
                      exact
                      path="/manage-drivers"
                      component={ManageDrivers}
                    />
                    <Route
                      exact
                      path="/manage-customers"
                      component={ManageCustomers}
                    />

                    <Route
                      exact
                      path="/order-history"
                      component={OrderHistory}
                    />
                    <Route
                      exact
                      path="/cancelled-orders"
                      component={CancelledOrders}
                    />
                    <Route
                      exact
                      path="/product-library"
                      component={ProductLibrary}
                    />
                    <Route
                      exact
                      path="/delivery-settings"
                      component={DeliverySettings}
                    />
                    <Route
                      exact
                      path="/payments-history"
                      component={PaymentsHistory}
                    />
                      <Route
                      exact
                      path="/drivers-payments"
                      component={DriversPayments}
                    />
                    <Route
                      exact
                      path="/manage-vendors/add-vendor"
                      component={AddVendorSettings}
                    />
                    <Route exact path="/add-driver" component={AddDriver} />
                    <Route
                      exact
                      path="/manage-vendors/:vendorId/add-store"
                      component={AddStore}
                    />

                    <Route
                      exact
                      path="/manage-vendors/:vendorId"
                      component={VendorDetails}
                    />                   
                    <Route
                      exact
                      path="/manage-vendors/:vendorId/stores/:venueId/orders"
                      component={VendorOrdersTable}
                    />
                    <Route
                      exact
                      path="/manage-venues/:venueId"
                      component={StoreDetails}
                    />
                    <Route
                      exact
                      path="/manage-drivers/:driverId"
                      component={DriverDetails}
                    />
                    <Route
                      exact
                      path="/manage-customers/:customerId"
                      component={CustomerDetails}
                    />
                    <Route
                      exact
                      path="/manage-vendors/:vendorId/edit-branding"
                      component={EditBranding}
                    />

                    <Route
                      exact
                      path="/manage-vendors/:vendorId/stores/:venueId"
                      component={StoreSettings}
                    />

                    <Route exact path="/settings" component={Settings} />

                    <Redirect to="/404" />
                  </Switch>
                </div>
              </div>
              <Footer />
            </div>
            <UserPanel />
          </div>
          <ScrollTop />
        </div>
      </>
    );
  }
}

import React, { Component } from "react";

import { withRouter } from "react-router-dom";

import moment from "moment";

import FlatPickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.min.css";

import CustomButton from "../../CustomButton";

import Backend from "../../../../utils/Backend";
import General from "../../../../utils/General";
import Notify from "../../../../utils/Notify";

import LocationInput from "../../common/LocationInput";

import PhoneInput from "../../PhoneInput";

let DAYS_OPTIONS = [
  {
    day: "monday",
    starts_at: null,
    ends_at: null,
  },
  {
    day: "tuesday",
    starts_at: null,
    ends_at: null,
  },
  {
    day: "wednesday",
    starts_at: null,
    ends_at: null,
  },
  {
    day: "thursday",
    starts_at: null,
    ends_at: null,
  },
  {
    day: "friday",
    starts_at: null,
    ends_at: null,
  },
  {
    day: "saturday",
    starts_at: null,
    ends_at: null,
  },
  {
    day: "sunday",
    starts_at: null,
    ends_at: null,
  },
];

class EditStoreDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        company: this.props.vendorId,
        title: "",
        phone_number: "",
        phone_country_code: "+353",
        address: null,
        description: "",
        opening_hours: [],
        days: DAYS_OPTIONS,
      },
      isLoading: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  componentDidMount() {
    this._getVenue();
  }

  _getVenue() {
    let venueId = this.props.venueId;

    this.setState({
      isLoading: true,
    });

    Backend.getVenue(venueId)
      .then((data) => {
        data = this._setHours(data);
        this.setState({ data, isLoading: false });
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  _handleChange(e) {
    let { data } = this.state;

    data[e.target.name] = e.target.value;
    this.setState({ data });
  }

  _isFormValid() {
    const { data } = this.state;

    let { title, phone_number, address, description } = data;

    let error = null;

    if (!title) {
      error = "Please enter a valid company name.";
    } else if (!address) {
      error = "Please enter a valid address.";
    } else if (!description) {
      error = "Please enter a valid description.";
    } else if (!phone_number) {
      error = "Please enter a valid phone number.";
    }

    if (error) {
      Notify.error(error);
      return false;
    }

    return true;
  }

  _setHours(data) {
    DAYS_OPTIONS = DAYS_OPTIONS.map((dayItem) => {
      const openingDay = data.opening_hours.find(
        (openingHour) =>
          openingHour.day.toLowerCase() === dayItem.day.toLowerCase()
      );

      return openingDay || dayItem;
    });
    data.opening_hours = DAYS_OPTIONS;

    return data;
  }

  _updateHours(days) {
    let { data } = this.state;

    let openingHours =
      days &&
      days?.map((dayItem, index) => {
        return {
          key: General.uuid(),
          day: dayItem.day,
          starts_at: days[index].starts_at,
          ends_at: days[index].ends_at,
        };
      });

    data.opening_hours = openingHours;

    this.setState({ data });
  }

  _getOpeningHours() {
    let { data } = this.state;
    let { days, starts_at, ends_at } = data;

    days = DAYS_OPTIONS;
    data.opening_hours = days.map((dayItem, index) => {
      let day = data.opening_hours.find(
        (openingHour) => openingHour.day === dayItem.day
      );

      return day || dayItem;
    });

    return data.opening_hours.map((dayItem, index) => {
      return (
        <>
          <div className="row mb-3" key={dayItem.day}>
            <div className="col-xl-3">
              <label className="pt-5 font-weight-bolder">
                {General.toTitleCase(dayItem.day)}
              </label>
            </div>
            <div className="col-xl-3">
              <FlatPickr
                key={dayItem.starts_at}
                value={dayItem?.starts_at}
                className="form-control form-control-solid form-control-lg"
                options={{
                  enableTime: true,
                  noCalendar: true,
                  altInput: true,
                  dateFormat: "H:i",
                  time_24hr: true,
                  static: true,
                }}
                onChange={(dates) => {
                  let date = dates[0];

                  starts_at = moment(date).format("HH:mm:ss");

                  days[index].starts_at = starts_at;

                  this.setState({ days }, () => this._updateHours(days));
                }}
              />
            </div>
            <div className="col-xl-3 text-center">
              <label className="pt-5 text-center">to</label>
            </div>
            <div className="col-xl-3">
              <FlatPickr
                key={dayItem.ends_at}
                value={dayItem?.ends_at}
                className="form-control form-control-solid form-control-lg"
                options={{
                  enableTime: true,
                  noCalendar: true,
                  altInput: true,
                  dateFormat: "H:i",
                  time_24hr: true,
                  static: true,
                }}
                onChange={(dates) => {
                  let date = dates[0];

                  ends_at = moment(date).format("HH:mm:ss");

                  days[index].ends_at = ends_at;

                  this.setState({ days }, () => this._updateHours(days));
                }}
              />
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-xl-3"></div>
            <div className="col-xl-3 pt-2">Closed?</div>
            <div className="col-xl-6">
              <label
                className="checkbox checkbox-lg"
                style={{ float: "right" }}
              >
                <input
                  type="checkbox"
                  name="is_closed"
                  checked={dayItem.starts_at == null && dayItem.ends_at == null}
                  className="salebox"
                  onClick={(e) => {
                    if (e.target.checked) {
                      data.opening_hours[index].starts_at = null;
                      data.opening_hours[index].ends_at = null;
                    }

                    this.setState({
                      data,
                    });
                  }}
                />
                <span />
              </label>
            </div>
          </div>
        </>
      );
    });
  }

  _updateVenue() {
    let { data } = this.state;

    if (!this._isFormValid()) {
      return;
    }

    data.opening_hours = data?.opening_hours?.filter(
      (dayItem) => dayItem?.starts_at !== null
    );

    this.setState({ isLoading: true });

    Backend.editVenue(data)
      .then((data) => {
        this.setState({ data, isLoading: false });
        Notify.success("Store details have been updated successfully.");
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  render() {
    let { data, isLoading } = this.state;

    if (!data) {
      return null;
    }

    let { title, phone_number, address, description } = data;

    return (
      <div className="row mt-20 mb-15">
        <div className="offset-xxl-2 col-xxl-8">
          <form className="form" id="kt_form">
            <div
              className="pb-5"
              data-wizard-type="step-content"
              data-wizard-state="current"
            >
              <h6 className="mb-10 font-weight-bold">
                Add your store information for your customers. If you have
                multiple stores, let's start with your primary location.
              </h6>
              <h4 className="mb-4 font-weight-bold text-dark">
                Store Information
              </h4>
              <div className="form-group">
                <label>Company Name *</label>
                <input
                  type="text"
                  className="form-control form-control-solid form-control-lg"
                  name="title"
                  placeholder="Company Name"
                  value={title}
                  onChange={(e) => this._handleChange(e)}
                />
              </div>

              <PhoneInput
                spanClassName="text"
                value={phone_number}
                onChange={(e) => this._handleChange(e)}
              />

              {address && (
                <div className="form-group">
                  <label>Company Address *</label>
                  <LocationInput
                    className="form-control"
                    location={address}
                    onUpdated={(address) => {
                      data.address = address;
                      this.setState({ data });
                    }}
                  />
                </div>
              )}

              <div className="form-group">
                <label>About Your Company</label>
                <textarea
                  className="form-control form-control-solid form-control-lg"
                  placeholder="Up to 300 words about your company"
                  name="description"
                  value={description}
                  onChange={(e) => this._handleChange(e)}
                />
              </div>
              <div className="form-group">
                <label>Opening Hours</label>
                {this._getOpeningHours()}
              </div>
            </div>
            <div className="d-flex justify-content-between border-top mt-5 pt-10">
              <div className="col text-right">
                <CustomButton
                  buttonClassName="btn btn-primary font-weight-bolder px-9 py-4 text-uppercase"
                  title="Save Changes"
                  disabled={isLoading}
                  onClick={(e) => {
                    e.preventDefault();
                    this._updateVenue();
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(EditStoreDetails);

import React, { Component } from "react";

import Backend from "../../../../utils/Backend";
import Notify from "../../../../utils/Notify";

import CustomButton from "../../CustomButton";
import PhoneInput from "../../PhoneInput";

export default class AddLoginDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        title: "",
        eircode: "",
        vat_no: "",
        liquor_license_no: "",
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        phone_country_code: "+353",
        password: "",
        confirm_password: "",
      },
      vendorInfo: props.vendorInfo,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _handleChange(e) {
    let { data } = this.state;

    data[e.target.name] = e.target.value;
    this.setState({ data });
  }

  _isFormValid() {
    const { data } = this.state;

    let error = null;

    if (!data.title) {
      error = "Please enter a valid company name.";
    } else if (!data.first_name) {
      error = "Please enter a valid first name.";
    } else if (!data.last_name) {
      error = "Please enter a valid last name.";
    } else if (!data.email) {
      error = "Please enter a valid email.";
    } else if (!data.eircode) {
      error = "Please enter a valid eircode.";
    } else if (!data.phone_number) {
      error = "Please enter a valid phone number.";
    } else if (!data.vat_no) {
      error = "Please enter a valid vat number.";
    } else if (!data.vat_no) {
      error = "Please enter a valid vat number.";
    } else if (!data.liquor_license_no) {
      error = "Please enter a valid liquour licence number.";
    } else if (!data?.password) {
      error = "Please enter a valid password.";
    } else if (data?.password !== data?.confirm_password) {
      error = "Passwords do not match.";
    }

    if (error) {
      Notify.error(error);
      return false;
    }

    return true;
  }

  _updateCompany() {
    let { data } = this.state;

    let {
      title,
      eircode,
      vat_no,
      liquor_license_no,
      first_name,
      last_name,
      email,
      phone_number,
      phone_country_code,
      password,
    } = data;

    data = {
      title,
      eircode,
      vat_no,
      liquor_license_no,
      members: [
        {
          user: {
            first_name,
            last_name,
            email,
            password,
            phone_number,
            phone_country_code,
          },
        },
      ],
    };

    if (!this._isFormValid()) {
      return;
    }

    this.setState({ isLoading: true });

    Backend.addCompany(data)
      .then((company) => {
        this.setState({ data, isLoading: false });
        this.props.onNextClick({ company });
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  render() {
    let { data, isLoading } = this.state;

    return (
      <div className="wizard-body py-8 px-8 py-lg-20 px-lg-10">
        <div className="row">
          <div className="offset-xxl-2 col-xxl-12">
            <form
              className="form fv-plugins-bootstrap fv-plugins-framework"
              id="kt_form"
            >
              <div
                className="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <h6 className="mb-10 font-weight-bold">
                  Add Vendors login information for access to Clinks.
                </h6>
                <h4 className="mb-4 font-weight-bold text-dark">
                  Vendor Login Details
                </h4>
                <div className="form-group">
                  <label>Company Name *</label>
                  <input
                    type="text"
                    className="form-control form-control-solid form-control-lg"
                    placeholder="Company Name"
                    name="title"
                    value={data.title}
                    onChange={(e) => this._handleChange(e)}
                  />
                </div>

                <div className="form-group fv-plugins-icon-container">
                  <label>Contact Name *</label>
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control form-control-solid form-control-lg"
                        placeholder="First Name"
                        name="first_name"
                        value={data.first_name}
                        onChange={(e) => this._handleChange(e)}
                      />
                      <div className="fv-plugins-message-container" />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control form-control-solid form-control-lg"
                        placeholder="Last Name"
                        name="last_name"
                        value={data.last_name}
                        onChange={(e) => this._handleChange(e)}
                      />
                      <div className="fv-plugins-message-container" />
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label>Login Email *</label>
                  <input
                    type="email"
                    className="form-control form-control-solid form-control-lg"
                    placeholder="Email Address"
                    name="email"
                    value={data.email}
                    onChange={(e) => this._handleChange(e)}
                  />
                </div>
                <PhoneInput
                  spanClassName="text"
                  value={data.phone_number}
                  onChange={(e) => this._handleChange(e)}
                />
                <div className="form-group">
                  <label>Company Eircode *</label>
                  <input
                    type="text"
                    className="form-control form-control-solid form-control-lg mb-2"
                    placeholder="Company Eircode"
                    name="eircode"
                    value={data.eircode}
                    onChange={(e) => this._handleChange(e)}
                  />
                </div>

                <div className="form-group">
                  <label>Company VAT No. *</label>
                  <input
                    type="text"
                    className="form-control form-control-solid form-control-lg mb-2"
                    placeholder="Company VAT No."
                    name="vat_no"
                    value={data.vat_no}
                    onChange={(e) => this._handleChange(e)}
                  />
                </div>

                <div className="form-group">
                  <label>Liquor Licence No. *</label>
                  <input
                    type="text"
                    className="form-control form-control-solid form-control-lg mb-2"
                    placeholder="Liquor Licence No."
                    name="liquor_license_no"
                    value={data.liquor_license_no}
                    onChange={(e) => this._handleChange(e)}
                  />
                </div>

                <div className="form-group">
                  <label>Password *</label>
                  <input
                    type="password"
                    className="form-control form-control-solid form-control-lg mb-2"
                    name="password"
                    value={data.password}
                    onChange={(e) => this._handleChange(e)}
                  />
                </div>

                <div className="form-group">
                  <label>Confirm Password *</label>
                  <input
                    type="password"
                    className="form-control form-control-solid form-control-lg mb-2"
                    name="confirm_password"
                    value={data.confirm_password}
                    onChange={(e) => this._handleChange(e)}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-between border-top mt-5 mb-8 pt-10">
                <div className="mr-2">
                  <CustomButton
                    buttonClassName="btn btn-primary font-weight-bolder px-9 py-4"
                    style={{ transform: "translateX(25rem)" }}
                    dataWizardType="action-next"
                    title="Continue"
                    disabled={isLoading}
                    onClick={(e) => {
                      e.preventDefault();

                      this._updateCompany();
                    }}
                  />
                </div>
              </div>
              <div />
              <div />
              <div />
              <div />
              <div />
            </form>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";

import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import Backend from "../../../../utils/Backend";
import Notify from "../../../../utils/Notify";

import CustomButton from "../../CustomButton";

export default class AgeSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        key: "age_limit",
        value: 18,
      },
    };
  }

  componentDidMount() {
    this._getAgeDetails();
  }

  _getAgeDetails() {
    this.setState({ isLoading: true });

    Backend.getAgeDetails()
      .then((data) => {
        this.setState({ data, isLoading: false });
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  _onSliderChange(value) {
    let { data } = this.state;

    data.value = value;

    this.setState({
      data,
    });
  }

  _isFormValid() {
    const { data } = this.state;

    const { value } = data;

    let error = null;

    if (!value) {
      error = "Please choose age limit.";
    }

    if (error) {
      Notify.error(error);
      return false;
    }

    return true;
  }

  _updateAge() {
    let { data } = this.state;

    if (!this._isFormValid()) {
      return;
    }

    this.setState({ isLoading: true });

    Backend.updateAge(data)
      .then(() => {
        this._getAgeDetails();
        Notify.success("Age settings have been updated successfully.");
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  render() {
    let { data, isLoading } = this.state;

    let { value } = data;

    const sliderProps = {
      min: 18,
      max: 100,
      marks: { 18: 18, 100: 100 },
    };

    const valueStyle = {
      color: "white",
      width: "15px",
      height: "15px",
      marginLeft: "140px",
      backgroundColor: "#C63030",
      fontWeight: "bold",
      padding: "3px",
      borderRadius: "20%",
    };

    return (
      <div className="wizard-body py-8 px-8 py-lg-20 px-lg-10 mt-5">
        <div className="row">
          <div className="offset-xxl-2 col-xxl-8">
            <form
              className="form fv-plugins-bootstrap fv-plugins-framework"
              id="kt_form"
            >
              <div
                className="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <h4 className="mb-10 font-weight-bold text-dark">
                  Set Limits on Age Verification Pop Up
                </h4>
                <form>
                  <div className="form-group row">
                    <label className="col">Max Verification Age</label>
                    <div className="row">
                      <div className="ml-40">
                        <span style={valueStyle}>{value}</span>
                        <div className="slider-arrow-down" />

                        <div style={{ width: "300px", margin: "0.5rem auto" }}>
                          <Slider
                            {...sliderProps}
                            onChange={(value) => this._onSliderChange(value)}
                            tipFormatter={(value) => value}
                            value={value}
                            handleStyle={{
                              height: 12,
                              width: 12,
                              borderRadius: "10%",
                              backgroundColor: "#C63030",
                              borderColor: "transparent",
                            }}
                            dotStyle={{
                              height: 8,
                              width: 8,
                              borderRadius: "10%",
                              backgroundColor: "#C63030",
                              borderColor: "transparent",
                            }}
                            trackStyle={{
                              backgroundColor: "#C63030",
                              border: "none",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div className="d-flex justify-content-between border-top col mt-5 pt-10 ml-25">
                <div style={{ margin: "0 auto" }}>
                  <CustomButton
                    buttonClassName="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                    title="Save Changes"
                    disabled={isLoading}
                    onClick={(e) => {
                      e.preventDefault();
                      this._updateAge();
                    }}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";

export default class PhoneInput extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="form-group">
        <label>
          Phone Number
          <span className={this.props.spanClassName}> *</span>
        </label>
        <input
          type="tel"
          className="form-control"
          placeholder="Phone Number"
          name="phone_number"
          value={this.props.value}
          onChange={this.props.onChange}
        />
      </div>
    );
  }
}

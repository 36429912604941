import React from "react";

import notFoundImg from "../../assets/media/not-found/not-found.svg";
import logo from "../../assets/media/logos/clinks.svg";

export default class NotFound extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className="notFound-container">
        <div className="logo-section">
          <img src={logo} alt="Logo" />
        </div>
        <h2>Oops...</h2>
        <h4>Looks like you are lost.</h4>
        <img className="notFound-img" src={notFoundImg} alt="Having Fun" />
        <button
          onClick={() => this.props.history.push("/account")}
          className="btn btn-primary home-btn"
        >
          Back to Home
        </button>
      </div>
    );
  }
}

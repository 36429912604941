import React, { Component } from "react";

import General from "../../../../utils/General";

import ProductLibrarySectionTable from "../../tables/product-library/ProductLibrarySectionTable";

export default class ProductLibrarySection extends Component {
  constructor(props) {
    super(props);

    this.state = this._getState();
  }

  onClickTabItem(tab) {
    this.setState({ selectedCategoryItem: tab });
  }

  _getState() {
    let selectedCategoryItem = null;

    if (this.props.data?.length > 0) {
      selectedCategoryItem = this.props.data[0].id;
    }

    return { selectedCategoryItem, data: this.props.data };
  }

  _showCategories() {
    const { selectedCategoryItem } = this.state;

    return this.props.data?.map((categoryItem, index) => {
      return (
        <li className="nav-item mt-3" key={categoryItem?.id}>
          <a
            onClick={() => {
              this.onClickTabItem(categoryItem?.id);
            }}
            className={`nav-link ${
              selectedCategoryItem === categoryItem?.id ? "active" : ""
            }`}
            id="wine-tab"
            data-toggle="tab"
            href="wine"
            aria-controls="profile"
          >
            <span className="nav-text">
              {General.toTitleCase(categoryItem?.title)}
            </span>
          </a>
        </li>
      );
    });
  }

  render() {
    let category = this.props?.data?.find(
      (category) => category?.id == this.state.selectedCategoryItem
    );

    let categoryId = category?.id;

    return (
      <>
        <div className="wizard-body py-8 px-10 py-lg-20 px-lg-10">
          <div className="row" style={{ width: "700px" }}>
            <div className="col">
              <div className="form-group category-row">
                <ul
                  className="nav nav-primary nav-pills"
                  id="category-tabs"
                  role="tablist"
                >
                  {this._showCategories()}
                </ul>
              </div>
            </div>
          </div>
          <div className="col">
            <ProductLibrarySectionTable categoryId={categoryId} />
          </div>
        </div>
      </>
    );
  }
}

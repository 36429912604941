import React, { Component } from "react";

import Modal from "react-bootstrap/Modal";

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";

import AsyncSelect from "../common/AsyncSelect";

export default class AddMenuItemModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this._getState(props),
      show: props.show,
      isLoading: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps));
  }

  _getState(props) {
    return {
      ...props,
      data: {
        menu: null,
        menu_category: null,
        item: null,
        price: null,
        price_sale: null,
        on_sale: false,
        currency: 1,
      },
    };
  }

  _isFormValid() {
    const { data } = this.state;

    const { item, price, price_sale, on_sale } = data;

    let error = null;

    if (!item) {
      error = "Please select an item.";
    } else if (price === null) {
      error = "Please enter an item price.";
    } else if (on_sale && price_sale === null) {
      error = "Please enter a sale price.";
    }

    if (error) {
      Notify.error(error);
      return false;
    }

    return true;
  }

  _addMenuItem() {
    let { data } = this.state;

    let { item, price, price_sale, on_sale, currency } = data;

    if (!this._isFormValid()) {
      return;
    }

    this.setState({ isLoading: true });

    data.menu = this.props.menuId;
    data.menu_category = this.props.menuCategoryId;
    data.item = item ? item?.id : null;

    Backend.addMenuItem(data)
      .then(() => {
        this.setState({ data, isLoading: false });
        Notify.success("Menu Item has been added successfully.");
        this.props.onUpdated(data);
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  _getItem(item) {
    return {
      label: item.title,
      value: item.id,
      data: item,
    };
  }

  render() {
    let { show, data, isLoading } = this.state;

    if (!data) {
      return null;
    }

    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide();
          }
        }}
      >
        <div className="modal-content">
          <Modal.Header closeButton>
            <Modal.Title> Add Item</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="form-group row">
              <div data-repeater-list className="col-lg-12">
                <div
                  data-repeater-item
                  className="form-group row align-items-center"
                >
                  <div className="col-xl-12 my-2 my-md-0">
                    <div className="form-group">
                      <label>Items</label>
                      <AsyncSelect
                        endpoint={`${window.Api.Items}`}
                        filter={`category_id=${this.props.categoryId}`}
                        orderBy={"title"}
                        search_term={"title"}
                        getOptions={(items) => {
                          return items.map((item) => this._getItem(item));
                        }}
                        className="form-control h-auto border-0 form-control-solid c-selectbox"
                        name="items"
                        value={data?.item ? this._getItem(data.item) : null}
                        onSelected={(item) => {
                          data.item = item;
                          this.setState({ data });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-xl-12">
                    <div className="form-group">
                      <label>Item Price *</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">€</span>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          placeholder={0.0}
                          name="price"
                          value={data?.price ? data?.price / 100 : null}
                          onChange={(e) => {
                            data.price = e.target.value
                              ? Math.round(
                                  parseFloat(e.target.value).toFixed(2) * 100
                                )
                              : null;

                            this.setState({ data });
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-9 mb-3 mt-15">
                    <label>Is this item on sale?</label>
                  </div>

                  <div className="col-md-3 mb-3 mt-10">
                    <label
                      className="checkbox checkbox-lg"
                      style={{ position: "initial" }}
                    >
                      <input
                        type="checkbox"
                        className="salebox"
                        name="on_sale"
                        checked={data?.on_sale}
                        onClick={(e) => {
                          data.on_sale = e.target.checked;

                          this.setState({ data });
                        }}
                      />
                      <span />
                    </label>
                  </div>
                  {data?.on_sale && (
                    <div className="col-md-12 sale-item mb-3">
                      <label>Sale Price</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">€</span>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          placeholder={0.0}
                          name="price_sale"
                          value={
                            data?.price_sale ? data?.price_sale / 100 : null
                          }
                          onChange={(e) => {
                            data.price_sale = e.target.value
                              ? Math.round(
                                  parseFloat(e.target.value).toFixed(2) * 100
                                )
                              : null;

                            this.setState({ data });
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              className="btn btn-outline-primary font-weight-bold"
              onClick={() => this.props.onHide()}
            >
              Close
            </button>

            <button
              type="button"
              className="btn btn-primary font-weight-bold"
              disabled={isLoading}
              onClick={() => this._addMenuItem()}
            >
              Save Changes
            </button>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }
}

import React from "react";

const Separator = ({ title, subtitle, mainTitle }) => {
  return (
    <div className="c-separator form-group row mt-4 align-items-center text-center">
      <div className="col-3">
        <hr />
      </div>
      <div className="col-6">
        <h4 style={{ transform: "translateY(0.8rem)" }}>
          <strong>{mainTitle}</strong>
        </h4>
        <span>
          <strong>{title}</strong>
        </span>
        <br />
        <span>
          <strong>{subtitle}</strong>
        </span>
      </div>
      <div className="col-3">
        <hr />
      </div>
    </div>
  );
};

export default Separator;

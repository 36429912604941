import React from "react";

import { withRouter } from "react-router-dom";

import Backend from "../../../../utils/Backend";
import Notify from "../../../../utils/Notify";
import General from "../../../../utils/General";
import LocationFormat from "../../../../utils/LocationFormat";

class VendorsSubTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      venues: props.venues,
    };
  }

  _testOrder(venueId) {
    this.setState({ isLoading: true });

    Backend.testOrder(venueId)
      .then((data) => {
        this.setState({ data, isLoading: false });
        window.location.reload();
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  _pauseVenue(venueId) {
    this.setState({ isLoading: true });

    Backend.pauseVenue(venueId)
      .then((data) => {
        data.paused = true;
        this.setState({ data, isLoading: false });
        window.location.reload();
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  _unpauseVenue(venueId) {
    this.setState({ isLoading: true });

    Backend.unpauseVenue(venueId)
      .then((data) => {
        data.paused = false;
        this.setState({ data, isLoading: false });
        window.location.reload();
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  _renderAddress(address) {
    return (
      <span className="text-dark-75 font-weight-bold line-height-sm">
        {address ? LocationFormat.fullAddress(address) : "-"}
      </span>
    );
  }

  _renderContactDetails(venueStaff) {
    let contactDetails = `${venueStaff[0]?.company_member?.user?.first_name} ${venueStaff[0]?.company_member?.user?.last_name}`;

    return General.toTitleCase(contactDetails);
  }

  _renderVendorDetails() {
    const { venues } = this.state;

    return venues?.map((venue) => {
      return (
        <>
          <div
            key={venue.id}
            id="child_data_local_2"
            className="datatable datatable-default datatable-primary datatable-scroll datatable-loaded"
            style={{ background: "white" }}
          >
            <div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
              <div
                className="ps__thumb-x"
                tabIndex={0}
                style={{ left: 0, width: 0 }}
              />
            </div>
            <div className="ps__rail-y" style={{ top: 0, right: 0 }}>
              <div
                className="ps__thumb-y"
                tabIndex={0}
                style={{ top: 0, height: 0 }}
              />
            </div>
            <table
              className="datatable-table ml-5"
              style={{ display: "block", maxHeight: 400 }}
            >
              <tbody className="datatable-body ps" style={{ maxHeight: 359 }}>
                <tr data-row={0} className="datatable-row">
                  <td
                    data-field="Store"
                    aria-label="Molloys Cork, 12 Main Street, Co.Cork, Ireland, W22 WRA1"
                    className="datatable-cell"
                  >
                    <span style={{ width: 341, marginLeft: "5px" }}>
                      {venue?.title}, {this._renderAddress(venue?.address)}{" "}
                    </span>
                  </td>
                  <td data-field="Contact" className="datatable-cell">
                    <span style={{ width: 280 }}>
                      {venue?.staff.length !== 0
                        ? this._renderContactDetails(venue?.staff)
                        : "Oops, no staff found..."}
                    </span>
                  </td>
                  <td
                    data-field="Actions"
                    aria-label="null"
                    className="datatable-cell row"
                  >
                    <span style={{ width: 115 }}>
                      {" "}
                      <a
                        href={`/manage-venues/${venue.id}`}
                        className="btn btn-icon btn-sm btn-pill btn-outline-primary"
                        data-toggle="popover"
                        data-content="Manage Store"
                        data-original-title=""
                        title=""
                      >
                        {" "}
                        <span className="svg-icon svg-icon-md">
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            {" "}
                            <g
                              stroke="none"
                              strokeWidth={1}
                              fill="none"
                              fillRule="evenodd"
                            >
                              {" "}
                              <rect x={0} y={0} width={24} height={24} />{" "}
                              <path
                                d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                                fill="#000000"
                                fillRule="nonzero"
                                transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "
                              />{" "}
                              <rect
                                fill="#000000"
                                opacity="0.3"
                                x={5}
                                y={20}
                                width={15}
                                height={2}
                                rx={1}
                              />{" "}
                            </g>{" "}
                          </svg>
                        </span>{" "}
                      </a>{" "}
                    </span>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                      <a
                        href="javascript:;"
                        className="btn btn-sm btn-outline-primary"
                        style={{ transform: "translateX(1rem)", width: 120 }}
                        onClick={() =>
                          venue?.paused === true
                            ? this._unpauseVenue(venue)
                            : this._pauseVenue(venue)
                        }
                      >
                        <span className="navi-text">
                          {venue?.paused === true
                            ? "Unpause Store"
                            : "Pause Store"}
                        </span>
                      </a>
                      <a
                        href="javascript:;"
                        className="btn btn-sm btn-outline-primary"
                        style={{ transform: "translateX(1rem)", width: 120, marginTop: '0.5rem' }}
                        onClick={() => this._testOrder(venue)}
                      >
                        <span className="navi-text">
                          Test Order
                        </span>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      );
    });
  }

  render() {
    const { venues } = this.state;

    return (
      <>
        {venues.length !== 0 ? (
          <thead className="datatable-head ml-5" style={{ padding: "5px" }}>
            <tr className="datatable-row">
              <th
                data-field="Store"
                className="datatable-cell datatable-cell-sort"
              >
                <div style={{ width: 470 }}>Store</div>
              </th>
              <th
                data-field="Contact"
                className="datatable-cell datatable-cell-sort"
              >
                <div style={{ width: 385 }}>Contact</div>
              </th>
              <th
                data-field="Actions"
                className="datatable-cell datatable-cell-sort"
              >
                <div style={{ width: 200 }}>Actions</div>
              </th>
            </tr>
          </thead>
        ) : (
          <div className="text-center text-dark-75 font-weight-bold">
            Oops, no stores found...{" "}
          </div>
        )}

        {this._renderVendorDetails()}
      </>
    );
  }
}

export default withRouter(VendorsSubTable);

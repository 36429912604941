import React from "react";

import moment from "moment";

import { withRouter } from "react-router-dom";

import LocationFormat from "../../../../utils/LocationFormat";
import BaseTable from "../BaseTable";
import General from "../../../../utils/General";

class ManageCustomersTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.table = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _renderAddress(address) {
    return (
      <span className="text-dark-75 font-weight-bold line-height-sm">
        {address ? LocationFormat.fullAddress(address) : "-"}
      </span>
    );
  }

  _getColumns() {
    return [
      {
        Header: () => <strong className="header__clr">USER</strong>,
        id: "user__first_name",
        Cell: (rowInfo) => {
          let customer = rowInfo.original;

          let nameDetails = `${General.toTitleCase(
            customer?.user?.first_name
          )} ${General.toTitleCase(customer?.user?.last_name)}`;

          if (!nameDetails) {
            return "-";
          }

          return (
            <>
              <span>{nameDetails}</span>
              <br />
              <a
                href="javascript:;"
                className="font-size-sm text-dark-50 text-hover-primary"
              >
                {customer?.user?.email ?? "-"}
              </a>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">PHONE NUMBER</strong>,
        id: "phone_number",
        sortable: false,
        Cell: (rowInfo) => {
          let customer = rowInfo.original;

          let phoneNumber = customer?.user?.phone_number;

          if (!phoneNumber) {
            return "-";
          }

          return <span>{phoneNumber}</span>;
        },
      },
      {
        Header: () => <strong className="header__clr">ORDERS</strong>,
        id: "order_count",
        Cell: (rowInfo) => {
          let customer = rowInfo.original;

          let orderCount = customer?.order_count;

          return (
            <>
              <span>{orderCount}</span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">LAST ORDER</strong>,
        id: "last_order_at",
        Cell: (rowInfo) => {
          let customer = rowInfo.original;

          let lastOrder = `${moment(customer?.last_order_at).format(
            "DD MMM YYYY - hh:mm A"
          )}`;

          return (
            <>
              <span>{customer?.last_order_at !== null ? lastOrder : "-"}</span>
            </>
          );
        },
      },

      {
        Header: () => <strong className="header__clr">ACTIONS</strong>,
        id: "id",
        sortable: false,
        Cell: (rowInfo) => {
          let customer = rowInfo.original;

          let customerId = customer.user.id;

          return (
            <>
              <span style={{ width: 211 }}>
                {" "}
                <a
                  href={`/manage-customers/${customerId}`}
                  className="btn btn-icon btn-sm btn-pill btn-outline-primary mr-2"
                  data-toggle="popover"
                  data-content="Manage User"
                >
                  {" "}
                  <span className="svg-icon svg-icon-md">
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      {" "}
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        {" "}
                        <rect x={0} y={0} width={24} height={24} />{" "}
                        <path
                          d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                          fill="#000000"
                          fillRule="nonzero"
                          transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "
                        />{" "}
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x={5}
                          y={20}
                          width={15}
                          height={2}
                          rx={1}
                        />{" "}
                      </g>{" "}
                    </svg>
                  </span>{" "}
                </a>{" "}
              </span>
            </>
          );
        },
      },
    ];
  }

  render() {
    const { isLoading } = this.state;

    const columns = this._getColumns();

    let tableProps = {};

    return (
      <>
        <BaseTable
          {...tableProps}
          ref={this.table}
          title={this.props.title}
          loading={isLoading}
          endpoint={`${window.Api.Customers}`}
          noDataMessage={"No customers found"}
          columns={columns}
          showPagination={true}
          defaultSorted={[
            {
              id: "created_at",
              desc: true,
            },
          ]}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "Actions" ? "visible" : "hidden",
              },
            };
          }}
        />
      </>
    );
  }
}

ManageCustomersTable.defaultProps = {
  title: "All Users",
  showPagination: true,
  showPaginationTop: true,
  showPaginationBottom: true,
};

export default withRouter(ManageCustomersTable);

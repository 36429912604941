import React, { Component } from "react";

import LazyLoadingList from "../common/LazyLoadingList";

import StoresCard from "./StoresCard";

import PlaybackLoadingCard from "../common/PlaybackLoadingCard";

import noContentImg from "../../../assets/media/svg/no-content/no-content.svg";
import { ThemeConsumer } from "react-bootstrap/esm/ThemeProvider";

export default class StoresCards extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };

    this.lazyLoadingList = React.createRef();
  }

  _renderPlaceholderCards() {
    return (
      <>
        <div>
          <PlaybackLoadingCard />
        </div>
      </>
    );
  }

  _renderNoContent() {
    return (
      <div className="no-content">
        <div className="no-content-section text-center mt-10">
          <h3>Oops, no stores found...</h3>
          <img src={noContentImg} alt="No Content" style={{width: "40%"}} />
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <LazyLoadingList
          ref={this.lazyLoadingList}
          endpoint={`${window.Api.Venues}?company_id=${this.props.vendorId}&search_term=${this.props.searchTerm}`}
          onItemUpdated={(stores) => {
            this.setState({
              stores,
              isLoading: true,
            });
          }}
          renderItem={(store) => {
            return (
          <StoresCard              
                store={store}
                vendorId={this.props.vendorId}
                onUpdated={() => this.lazyLoadingList.current.refresh()}
              />
            );
          }}
          renderInitialLoading={() => this._renderPlaceholderCards()}
          renderLoadingMore={() => this._renderPlaceholderCards()}
          renderNoContent={() => this._renderNoContent()}
        />
      </>
    );
  }
}

import React, { Component } from "react";

import AuthManager from "../../utils/AuthManager";
import General from "../../utils/General";
import Event from "../../utils/Event";

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthManager.currentUser,
    };
  }

  componentDidMount() {
    Event.on("USER_UPDATED", () =>
      this.setState({
        currentUser: AuthManager.currentUser,
      })
    );
  }

  componentWillUnmount() {
    Event.off("USER_UPDATED");
  }

  render() {
    const { currentUser } = this.state;

    return (
      <>
        <div id="kt_header" className="header header-fixed">
          <div className="container-fluid d-flex align-items-stretch justify-content-between">
            <div
              className="header-menu-wrapper header-menu-wrapper-left"
              id="kt_header_menu_wrapper"
            >
              <div
                id="kt_header_menu"
                className="header-menu header-menu-mobile header-menu-layout-default"
              >
                <ul className="header-tabs nav align-self-center font-size-lg"></ul>
              </div>
            </div>
            <div className="topbar">
              <div className="topbar-item">
                <div
                  className="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2"
                  id="kt_quick_user_toggle"
                >
                  <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
                    Hi,
                  </span>
                  <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
                    {currentUser?.user?.first_name}
                  </span>
                  <span className="symbol symbol-lg-35 symbol-25 symbol-light-danger">
                    <span className="symbol-label font-size-h5 font-weight-bold">
                      {General.getInitials(currentUser?.user?.first_name)}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

import React, { Component } from "react";

import AuthManager from "../../../../utils/AuthManager";
import Backend from "../../../../utils/Backend";
import Notify from "../../../../utils/Notify";
import General from "../../../../utils/General";

import EditUserModal from "../../modals/EditUserModal";

export default class UsersCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
    };
  }

  _removeAdmin() {
    const { data } = this.state;

    let adminId = data?.user?.id;

    this.setState({ isLoading: true });

    Backend.removeAdmin(adminId)
      .then(() => {
        this.setState({ isLoading: false });
        Notify.success("User has been removed successfully.");
        this.props.onEdited();
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    const { data, showEditUserModal } = this.state;

    let adminUser = AuthManager.currentUser;

    let currentAdminId = adminUser.user.id === data?.user.id;

    let adminDetails = `${General.toTitleCase(
      data?.user?.first_name
    )} ${General.toTitleCase(data?.user?.last_name)} `;

    return (
      <>
        <tr>
          <td className="pl-0">
            <div className="symbol symbol-50 symbol-light mt-2 mr-2">
              <span className="symbol-label">
                <img
                  src="assets/media/svg/avatars/001-boy.svg"
                  className="h-75 align-self-end"
                  alt="Admin"
                />
              </span>
            </div>
          </td>
          <td className="pl-0">
            <span className="text-dark font-weight-bolder mb-1 font-size-lg">
              {adminDetails} {currentAdminId ? "(You)" : ""}
            </span>
            <span className="text-muted font-weight-bold d-block"></span>
          </td>

          {currentAdminId
            ? ""
            : adminUser.role === "admin" && (
                <td className="text-right pr-0">
                  <a
                    href="javascript:;"
                    className="btn btn-icon btn-light btn-sm mr-2"
                    onClick={() =>
                      this.setState({
                        showEditUserModal: true,
                      })
                    }
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth={1}
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x={0} y={0} width={24} height={24} />
                          <path
                            d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                            fill="#000000"
                            fillRule="nonzero"
                            transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "
                          />
                          <rect
                            fill="#000000"
                            opacity="0.3"
                            x={5}
                            y={20}
                            width={15}
                            height={2}
                            rx={1}
                          />
                        </g>
                      </svg>
                    </span>
                  </a>
                  <a
                    href="javascript:;"
                    className="btn btn-icon btn-light btn-sm deleteUser"
                    onClick={() => this._removeAdmin()}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth={1}
                          fill="none"
                          fillRule="evenodd"
                        >
                          <g
                            transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)"
                            fill="#000000"
                          >
                            <rect x={0} y={7} width={16} height={2} rx={1} />
                            <rect
                              opacity="0.3"
                              transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) "
                              x={0}
                              y={7}
                              width={16}
                              height={2}
                              rx={1}
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                  </a>
                </td>
              )}
          <td className="text-right pr-0"></td>
        </tr>

        <EditUserModal
          show={showEditUserModal}
          data={data}
          onUpdated={() => {
            // this.props.onEdited();
            window.location.reload();
            this.setState({
              showEditUserModal: false,
            });
          }}
          onHide={() =>
            this.setState({
              showEditUserModal: false,
            })
          }
        />
      </>
    );
  }
}

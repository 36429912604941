import React, { Component } from "react";

export default class PlaybackLoadingCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  render() {
    return (
      <div className="container mt-8">
        <div className="col-xl-12">
          <div className="card card-custom gutter-b">
            <div className="card-header"></div>
          </div>
        </div>
      </div>
    );
  }
}

import React from "react";
import { ThemeConsumer } from "react-bootstrap/esm/ThemeProvider";

import Select from "react-select";

export default class CustomSelect extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Select
        {...this.props}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: "#F5F5F5",
            primary: "#F5F5F5",
            neutral0: "white",
          },
        })}
        styles={{
          control: (base) => ({
            ...base,
            borderRadius: "0.42rem !important",
            border: "1px solid #E5EAEE !important",

            "&:hover": {
              borderRadius: "0.42rem !important",
              background: "none",
              border: "1px solid rgb(53,149,246) !important",
              borderColor: "rgb(53,149,246)",
            },
          }),
        }}
      />
    );
  }
}

import React, { Component } from "react";

export default class List extends Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.state = {
      items: props.items,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  handleScroll(e) {
    if (
      document.documentElement.scrollHeight - 50 >
      document.body.offsetHeight + window.pageYOffset
    ) {
      return;
    }
    this.props.loadMore();
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    setTimeout(this.handleScroll, 2000);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    let { items } = this.state;

    return items.map((item, index) => {
      return this.props.renderItem(item, index);
    });
  }
}

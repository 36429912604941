import React from "react";

import lottie from "lottie-web";

import { animationData } from "../../../assets/js/logo-black";

export default class Div extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      canRenderChildren: props.canRenderChildren,
    };

    this.spinner = React.createRef();
    this.animObj = null;
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _startAnimation() {
    if (!this.spinner) {
      return;
    }
    //call the loadAnimation to start the animation
    this.animObj = lottie.loadAnimation({
      container: this.spinner, // the dom element that will contain the animation
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animationData, // the path to the animation json
    });
    this.animObj.setSpeed(2.5);

    this.animObj.play();
  }

  _stopAnimation() {
    if (!this.animObj) {
      return null;
    }
    this.animObj.stop();
  }

  _renderDisableView() {
    if (!this.props.disabled) {
      setTimeout(() => this._stopAnimation(), 50);
      return null;
    }

    setTimeout(() => this._startAnimation(), 50);

    return (
      <div className={`disable-view ${this.props.disableViewClassName}`}>
        {this.props.spinner && (
          <div class="spinner spinner-track spinner-primary">
            <div class="logo-svg">
              <span ref={(ref) => (this.spinner = ref)} />
            </div>
            {this.props.loadingText}
          </div>
        )}
      </div>
    );
  }

  _renderLoading() {
    if (this.props.renderLoading) {
      return this.props.renderLoading();
    }

    return null;
  }

  render() {
    let { canRenderChildren } = this.state;

    if (!canRenderChildren) {
      return null;
    }

    return (
      <div className={"relative " + this.props.className}>
        {this.props.children}
        {this._renderDisableView()}
      </div>
    );
  }
}

Div.defaultProps = {
  canRenderChildren: true,
  loadingText: "Loading...",
  disableViewClassName: "",
};

import React from "react";

import { withRouter } from "react-router-dom";

import Backend from "../../../../utils/Backend";
import General from "../../../../utils/General";
import Notify from "../../../../utils/Notify";

import EditProductCategoryModal from "../../modals/EditProductCategoryModal";

class ProductCategoriesSubTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      subcategories: props.subcategories,
    };
  }

  _isFormValid(category) {
    let error = null;

    if (category?.menu_item_count > 0) {
      error =
        "This SubCategory has associated menu items. It can't be deleted.";
    }

    if (error) {
      Notify.error(error);
      return false;
    }

    return true;
  }

  _deleteSubCategory(subcategory) {
    if (!this._isFormValid(subcategory)) {
      return;
    }

    this.setState({ isLoading: true });

    Backend.deleteSubCategory(subcategory.id)
      .then((data) => {
        this.setState({ isLoading: false });
        Notify.success("SubCategory has been deleted successfully.");
        this.props.onUpdated();
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  _renderSubcategories() {
    const { subcategories } = this.state;

    return subcategories?.map((subcategory) => {
      return (
        <>
          <div
            className="ps__rail-x"
            style={{ left: 0, bottom: 0 }}
            key={subcategory?.id}
          >
            <div
              className="ps__thumb-x"
              tabIndex={0}
              style={{ left: 0, width: 0 }}
            />
          </div>
          <div className="ps__rail-y" style={{ top: 0, right: 0 }}>
            <div
              className="ps__thumb-y"
              tabIndex={0}
              style={{ top: 0, height: 0 }}
            />
          </div>
          <table
            className="datatable-table ml-5 mt-3"
            style={{ display: "block", maxHeight: 400 }}
          >
            <tbody className="datatable-body ps" style={{ maxHeight: 359 }}>
              <tr data-row={0} className="datatable-row ">
                <td data-field="SubCategory" className="datatable-cell">
                  <div style={{ width: 287 }}>
                    {General.toTitleCase(subcategory?.title)}
                  </div>
                </td>
                <td data-field="Items" className="datatable-cell">
                  <div style={{ width: 287 }}>
                    {subcategory?.menu_item_count ?? "-"}
                  </div>
                </td>

                <th>
                  <div
                    className="row py-3"
                    style={{ transform: "translateX(-3.4rem)" }}
                  >
                    <a
                      href="javascript:;"
                      className="btn btn-sm btn-rounded btn-outline-primary mr-2 w-xs-100"
                      data-toggle="modal"
                      data-target="#editLibraryMenu"
                      onClick={() =>
                        this.setState({
                          subcategory,
                          showEditProductCategoryModal: true,
                        })
                      }
                    >
                      Edit
                    </a>{" "}
                    <a
                      href="javascript:;"
                      className="btn btn-sm btn-rounded btn-outline-primary mr-2 w-xs-100"
                      onClick={() => this._deleteSubCategory(subcategory)}
                    >
                      Remove
                    </a>{" "}
                  </div>
                </th>
              </tr>
            </tbody>
          </table>
        </>
      );
    });
  }

  render() {
    const {
      subcategories,
      subcategory,
      showEditProductCategoryModal,
    } = this.state;

    return (
      <>
        {subcategories.length !== 0 ? (
          <thead className="datatable-head ml-5">
            <tr className="datatable-row">
              <th
                data-field="SubCategory"
                className="datatable-cell datatable-cell-sort"
              >
                <div style={{ width: 287 }}>Sub-Category</div>
              </th>
              <th
                data-field="Items"
                className="datatable-cell datatable-cell-sort"
              >
                <div style={{ width: 287 }}>Products</div>
              </th>
            </tr>
          </thead>
        ) : (
          <div className="text-center text-dark-75 font-weight-bold">
            Oops, no sub-categories found...{" "}
          </div>
        )}

        {this._renderSubcategories()}

        <EditProductCategoryModal
          show={showEditProductCategoryModal}
          showSubCategoryData={this.props?.showSubCategoryData}
          data={subcategory}
          onUpdated={(selectedSubcategory) => {
            this.props.onUpdated();

            this.setState({
              showEditProductCategoryModal: false,
              subcategory: null,
            });
          }}
          onHide={() =>
            this.setState({
              showEditProductCategoryModal: false,
              subcategory: null,
            })
          }
        />
      </>
    );
  }
}

export default withRouter(ProductCategoriesSubTable);

import React, { Component } from "react";

import ProductCategoriesTable from "../../tables/product-library/ProductCategoriesTable";

export default class ProductCategories extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        <div className="wizard-body py-8 px-10 py-lg-20 px-lg-10">
          <div className="row">
            <div className="col-xxl-12" style={{maxWidth: 780}}>
              <div className="pb-5" data-wizard-type="step-content">
                <div className="card card-custom" style={{ boxShadow: "none" }}>
                  <ProductCategoriesTable
                    showCategoriesSubTable={true}
                    data={this.props.data}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

import React from "react";

import { Link, withRouter } from "react-router-dom";

import Backend from "../../../../utils/Backend";
import Notify from "../../../../utils/Notify";
import General from "../../../../utils/General";
import Currency from "../../../../utils/Currency";

import BaseTable from "../BaseTable";

import VendorsSubTable from "./VendorsSubTable";

class ManageVendorsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.table = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _pauseVendor(vendorId) {
    this.setState({ isLoading: true });

    Backend.pauseVendor(vendorId)
      .then((data) => {
        data.status = "paused";
        this.setState({ data, isLoading: false });
        window.location.reload();
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  _unpauseVendor(vendorId) {
    this.setState({ isLoading: true });

    Backend.unpauseVendor(vendorId)
      .then((data) => {
        data.status = "active";
        this.setState({ data, isLoading: false });
        window.location.reload();
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  _getColumns() {
    return [
      {
        Header: () => <strong className="header__clr">VENDOR</strong>,
        id: "title",
        Cell: (rowInfo) => {
          let vendor = rowInfo.original;

          let company = vendor.title;

          let email = vendor?.venues?.map(
            (venue) => venue?.company_member?.user?.email
          );

          let initials = General.getInitials(vendor.title);

          let logo = vendor?.logo?.original;

          if (!company || !email) {
            return "-";
          }

          return (
            <>
              <div className="d-flex align-items-center">
                <div className="symbol symbol-40 symbol-light flex-shrink-0">
                  {logo ? (
                    <img src={logo} alt="Logo" />
                  ) : (
                    <div className="symbol-label">{initials}</div>
                  )}
                </div>
                <div className="ml-2">
                  <div className="text-dark-75 font-weight-bold line-height-sm">
                    {vendor.title}
                  </div>
                  <a
                    href="javascript:;"
                    className="font-size-sm text-dark-50 text-hover-primary"
                  >
                    {email}
                  </a>
                </div>
              </div>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">PHONE NO.</strong>,
        id: "phone_number",
        sortable: false,
        Cell: (rowInfo) => {
          let vendor = rowInfo.original;

          let phoneNumbers = vendor?.venues?.map(
            (venue) => venue?.phone_number
          );

          let phoneNumber = phoneNumbers[0];

          if (!phoneNumber) {
            return "-";
          }

          return (
            <>
              <span>{phoneNumber}</span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">REVENUE</strong>,
        id: "total_earnings",
        Cell: (rowInfo) => {
          let vendor = rowInfo.original;

          let totalEarnings = vendor?.total_earnings;

          return (
            <>
              <span>{Currency.format(totalEarnings)}</span>
            </>
          );
        },
      },

      {
        Header: () => <strong className="header__clr">ACTIONS</strong>,
        id: "id",
        sortable: false,
        Cell: (rowInfo) => {
          let vendor = rowInfo.original;

          let vendorId = vendor.id;

          return (
            <>
              <span style={{ width: 130 }}>
                <a
                  href={`/manage-vendors/${vendorId}`}
                  className="btn btn-icon btn-sm btn-pill btn-outline-primary mr-2"
                  data-toggle="popover"
                  data-content="Manage Vendor"
                >
                  <span className="svg-icon svg-icon-md">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect x={0} y={0} width={24} height={24} />
                        <path
                          d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                          fill="#000000"
                          fillRule="nonzero"
                          transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "
                        />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x={5}
                          y={20}
                          width={15}
                          height={2}
                          rx={1}
                        />
                      </g>
                    </svg>
                  </span>
                </a>
                <div className="dropdown dropdown-inline show">
                  <a
                    href="javascript:;"
                    className="btn btn-icon btn-sm btn-pill btn-outline-primary"
                    data-toggle="dropdown"
                  >
                    <span className="svg-icon svg-icon-md">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth={1}
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x={0} y={0} width={24} height={24} />
                          <path
                            d="M5,8.6862915 L5,5 L8.6862915,5 L11.5857864,2.10050506 L14.4852814,5 L19,5 L19,9.51471863 L21.4852814,12 L19,14.4852814 L19,19 L14.4852814,19 L11.5857864,21.8994949 L8.6862915,19 L5,19 L5,15.3137085 L1.6862915,12 L5,8.6862915 Z M12,15 C13.6568542,15 15,13.6568542 15,12 C15,10.3431458 13.6568542,9 12,9 C10.3431458,9 9,10.3431458 9,12 C9,13.6568542 10.3431458,15 12,15 Z"
                            fill="#000000"
                          />
                        </g>
                      </svg>
                    </span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                    <ul className="navi flex-column navi-hover py-2">
                      <li className="navi-header font-weight-bolder text-uppercase font-size-xs text-primary pb-2">
                        Choose an action:
                      </li>
                      {vendor?.status !== "setup_not_completed" && (
                        <li className="navi-item">
                          <a
                            href="javascript:;"
                            className="navi-link"
                            onClick={() =>
                              vendor?.status === "paused"
                                ? this._unpauseVendor(vendor)
                                : this._pauseVendor(vendor)
                            }
                          >
                            <span className="navi-text">
                              {vendor?.status === "paused"
                                ? "Unpause Vendor"
                                : "Pause Vendor"}
                            </span>
                          </a>
                        </li>
                      )}

                      <li className="navi-item">
                        <a
                          className="navi-link"
                          href={`/manage-vendors/${vendorId}/edit-branding`}
                        >
                          <span className="navi-text">Edit Branding</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="kt-portlet__head-toolbar"></div>
              </span>
            </>
          );
        },
      },
    ];
  }

  _renderVendorsSubTable(vendor) {
    let venues = vendor?.venues?.map((venue) => venue);

    return (
      <VendorsSubTable showPaginationTop={false} title={``} venues={venues} />
    );
  }

  render() {
    const columns = this._getColumns();

    let tableProps = {};

    if (this.props.showVendorsSubTable) {
      tableProps = {
        SubComponent: (row) => this._renderVendorsSubTable(row.original),
      };
    }

    return (
      <>
        <BaseTable
          {...tableProps}
          ref={this.table}
          title={this.props.title}
          endpoint={`${window.Api.Companies}`}
          noDataMessage={"No companies found"}
          columns={columns}
          showPagination={true}
          SubComponent={(row) => this._renderVendorsSubTable(row.original)}
          defaultSorted={[
            {
              id: "created_at",
              desc: true,
            },
          ]}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "Actions" ? "visible" : "hidden",
              },
            };
          }}
          renderToolbar={(rowInfo) => {
            return (
              <div className="kt-portlet__head-toolbar">
                <div className="kt-portlet__head-wrapper">
                  <div className="row align-items-right">
                    <div className="col-md-12 my-2 my-md-0">
                      <div className="dropdown dropdown-inline mr-2">
                        <a
                          href={"/manage-vendors/add-vendor"}
                          className="btn btn-primary font-weight-bolder w-100"
                        >
                          Add a Vendor
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        />   
      </>
    );
  }
}

ManageVendorsTable.defaultProps = {
  title: "All Vendors",
  showPagination: true,
  showPaginationTop: true,
  showPaginationBottom: true,
};

export default withRouter(ManageVendorsTable);

import React, { Component } from "react";

import Modal from "react-bootstrap/Modal";

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";

import PhoneInput from "../PhoneInput";

export default class AddNewCompanyMemberModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this._getState(props),
      show: props.show,
      isLoading: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps));
  }

  _getState(props) {
    return {
      ...props,
      data: {
        company: null,
        venue: null,
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        phone_number: "",
        phone_country_code: "+353",
      },
    };
  }

  _handleChange(e) {
    let { data } = this.state;

    data[e.target.name] = e.target.value;
    this.setState({ data });
  }

  _isFormValid() {
    const { data } = this.state;

    const {
      first_name,
      last_name,
      email,
      phone_number,
      password,
      confirm_password,
      venue,
    } = data;

    let error = null;

    if (!first_name) {
      error = "Please enter a valid first name.";
    } else if (!last_name) {
      error = "Please enter a valid last name.";
    } else if (!email) {
      error = "Please enter a valid email.";
    } else if (!phone_number) {
      error = "Please enter a valid phone number.";
    } else if (!password) {
      error = "Please enter a valid password.";
    } else if (!confirm_password || confirm_password !== password) {
      error = "Passwords do not match.";
    }

    if (error) {
      Notify.error(error);
      return false;
    }

    return true;
  }

  _addCompanyMember() {
    let { data } = this.state;

    let companyId = this.props.vendorId;
    let venueId = this.props.venueId;

    let {
      first_name,
      last_name,
      email,
      phone_country_code,
      phone_number,
      password,
    } = data;

    data = {
      user: {
        first_name,
        last_name,
        email,
        password,
        phone_country_code,
        phone_number,
      },
      company: companyId,
      venue: venueId,
    };

    if (!this._isFormValid()) {
      return;
    }

    this.setState({ isLoading: true });

    Backend.addCompanyMember(data)
      .then(() => {
        this.setState({ data, isLoading: false });
        Notify.success("Company member has been created successfully.");
        this.props.onUpdated();
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  render() {
    let { show, data, isLoading } = this.state;

    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide();
          }
        }}
      >
        <div className="modal-content">
          <Modal.Header closeButton>
            <Modal.Title> Add New Company Member</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <>
              <div className="form-group fv-plugins-icon-container">
                <label>Contact Name *</label>
                <input
                  type="text"
                  className="form-control form-control-solid form-control-lg mb-3"
                  placeholder="First Name"
                  name="first_name"
                  value={data?.first_name}
                  onChange={(e) => this._handleChange(e)}
                />
                <input
                  type="text"
                  className="form-control form-control-solid form-control-lg mb-3"
                  placeholder="Last Name"
                  name="last_name"
                  value={data?.last_name}
                  onChange={(e) => this._handleChange(e)}
                />
              </div>
              <div className="form-group fv-plugins-icon-container">
                <label>Email Address *</label>
                <input
                  type="email"
                  className="form-control form-control-solid form-control-lg mb-3"
                  placeholder="Email Address"
                  name="email"
                  value={data.email}
                  onChange={(e) => this._handleChange(e)}
                />
                <PhoneInput
                  spanClassName="text"
                  value={data?.phone_number}
                  onChange={(e) => this._handleChange(e)}
                />
                <div className="fv-plugins-message-container" />
              </div>
              <div className="form-group">
                <label>Password *</label>
                <input
                  type="password"
                  className="form-control form-control-solid form-control-lg mb-3"
                  placeholder="Enter New Password"
                  name="password"
                  onChange={(e) => this._handleChange(e)}
                />
              </div>
              <div className="form-group">
                <label>Confirm Password *</label>
                <input
                  type="password"
                  className="form-control form-control-solid form-control-lg mb-3"
                  placeholder="Confirm New Password"
                  name="confirm_password"
                  onChange={(e) => this._handleChange(e)}
                />
              </div>
            </>
          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              className="btn btn-outline-primary font-weight-bold"
              onClick={() => this.props.onHide()}
            >
              Cancel
            </button>

            <button
              type="button"
              className="btn btn-primary font-weight-bold"
              disabled={isLoading}
              onClick={() => this._addCompanyMember()}
            >
              Add
            </button>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }
}

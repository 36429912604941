import React, { Component } from "react";

export default class CustomButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      buttonClassName: props.buttonClassName,
      style: props.style,
      dataWizardType: props.dataWizardType,
      title: props.title,
      disabled: props.disabled,
      onClick: props.onClick,
    };
  }

  render() {
    const { buttonClassName, style, dataWizardType, title, disabled, onClick } =
      this.state;

    return (
      <button
        className={buttonClassName}
        style={style}
        data-wizard-type={dataWizardType}
        disabled={disabled}
        onClick={onClick}
      >
        {title}
      </button>
    );
  }
}

import React, { Component } from "react";

import { withRouter } from "react-router-dom";

const LINK_OVERVIEW = {
  title: "Overview",
  icon: "icon-xl la la-dashboard menu-icon",
  url: "/",
};

const LINK_SALES = {
  title: "Sales",
  icon: "icon-xl la la-area-chart menu-icon",
  url: "/sales",
};

const LINK_MANAGE_VENDORS = {
  title: "Manage Vendors",
  icon: "icon-xl la la-store menu-icon",
  url: "/manage-vendors",
};

const LINK_MANAGE_DRIVERS = {
  title: "Manage Drivers",
  icon: "icon-xl la la-car-side menu-icon",
  url: "/manage-drivers",
};

const LINK_MANAGE_CUSTOMERS = {
  title: "Manage Customers",
  icon: "icon-xl la la-user menu-icon",
  url: "/manage-customers",
};

const LINK_ORDER_HISTORY = {
  title: "Order History",
  icon: "icon-xl la la-beer menu-icon",
  url: "/order-history",
};

const LINK_CANCELLED_ORDERS = {
  title: "Cancelled Orders",
  icon: "icon-xl la la-window-close menu-icon",
  url: "/cancelled-orders",
};

const LINK_PRODUCT_LIBRARY = {
  title: "Product Library",
  icon: "icon-xl la la-book menu-icon",
  url: "/product-library",
};

const LINK_DELIVERY_SETTINGS = {
  title: "Delivery Settings",
  icon: "icon-xl la la-car menu-icon",
  url: "/delivery-settings",
};

const LINK_PAYMENTS_HISTORY = {
  title: "Payments History",
  icon: "icon-xl la la-euro menu-icon",
  url: "/payments-history",
};

const LINK_DRIVERS_PAYMENTS = {
  title: "Drivers Payments",
  icon: "icon-xl la la-coins menu-icon",
  url: "/drivers-payments",
};

const FIRST_SECTION = [
  {
    title: "",
    links: [LINK_OVERVIEW, LINK_SALES],
  },
];

const ACCOUNTS_SECTION = [
  {
    title: "ACCOUNTS",
    links: [LINK_MANAGE_VENDORS, LINK_MANAGE_DRIVERS, LINK_MANAGE_CUSTOMERS],
  },
];

const MANAGEMENT_SECTION = [
  {
    title: "MANAGEMENT",
    links: [
      LINK_ORDER_HISTORY,
      LINK_CANCELLED_ORDERS,
      LINK_PRODUCT_LIBRARY,
      LINK_DELIVERY_SETTINGS,
      LINK_PAYMENTS_HISTORY,
      LINK_DRIVERS_PAYMENTS,
    ],
  },
];

class LeftMenu extends Component {
  constructor(props) {
    super(props);

    const first_section = FIRST_SECTION;
    const accounts_section = ACCOUNTS_SECTION;
    const management_section = MANAGEMENT_SECTION;

    this.state = {
      first_section,
      accounts_section,
      management_section,
    };
  }

  _goTo(url) {
    this.props.history.push(url);
  }

  _renderSections() {
    const { first_section, accounts_section, management_section } = this.state;

    const sectionTitle = (section, sectionIndex) => {
      return (
        section.title && (
          <li className="menu-section" key={sectionIndex}>
            <h4 className="menu-text">{section?.title}</h4>
            <i className="kt-menu__section-icon"></i>
          </li>
        )
      );
    };

    const sectionLink = (link, linkIndex) => {
      let className = "menu-item ";
      if (link.url === window.location.pathname) {
        className += "menu-item-active ";
      }

      return (
        <li className={className} aria-haspopup="true" key={linkIndex}>
          <a className="menu-link" onClick={() => this._goTo(link?.url)}>
            {link.icon && <i className={`${link?.icon}`}></i>}
            <span className="menu-text">{link?.title}</span>
          </a>
        </li>
      );
    };

    return (
      <div
        className="aside-menu-wrapper flex-column-fluid"
        id="kt_aside_menu_wrapper"
      >
        <div
          id="kt_aside_menu"
          className="aside-menu my-4"
          data-menu-vertical="1"
          data-menu-scroll="1"
          data-menu-dropdown-timeout="500"
        >
          <ul className="menu-nav">
            {first_section.map((section, sectionIndex) => {
              return (
                <>
                  {sectionTitle(section, sectionIndex)}

                  {section.links.map((link, linkIndex) => {
                    return sectionLink(link, linkIndex);
                  })}

                  {accounts_section.map((section, sectionIndex) => {
                    return (
                      <>
                        {sectionTitle(section, sectionIndex)}

                        {section.links.map((link, linkIndex) => {
                          return sectionLink(link, linkIndex);
                        })}
                      </>
                    );
                  })}

                  {management_section.map((section, sectionIndex) => {
                    return (
                      <>
                        {sectionTitle(section, sectionIndex)}

                        {section.links.map((link, linkIndex) => {
                          return sectionLink(link, linkIndex);
                        })}
                      </>
                    );
                  })}
                </>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <div
          className="aside aside-left aside-fixed d-flex flex-column flex-row-auto"
          id="kt_aside"
        >
          <div
            className="brand flex-column-auto"
            id="kt_brand"
            kt-hidden-height={65}
            style={{}}
          >
            <a href="/" className="brand-logo">
              <img
                src="/assets/media/logos/clinks.svg"
                height="42px"
                alt="Logo"
              />
            </a>

            <button
              className="brand-toggle btn btn-sm px-0"
              id="kt_aside_toggle"
            >
              <span className="svg-icon svg-icon svg-icon-xl">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                  >
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <path
                      d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z"
                      fill="#000000"
                      fillRule="nonzero"
                      transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999)"
                    />
                    <path
                      d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z"
                      fill="#000000"
                      fillRule="nonzero"
                      opacity="0.3"
                      transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999)"
                    />
                  </g>
                </svg>
              </span>
            </button>
          </div>

          {this._renderSections()}
        </div>
      </>
    );
  }
}

export default withRouter(LeftMenu);

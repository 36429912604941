import React from "react";

import moment from "moment";

import { withRouter } from "react-router-dom";

import LocationFormat from "../../../../utils/LocationFormat";
import Currency from "../../../../utils/Currency";
import General from "../../../../utils/General";

import BaseTable from "../BaseTable";

const STATUS_FILTERS = {
  name: {
    api: "statuses",
    display: "Status",
  },
  values: [
    {
      label: "All",
      value: null,
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Accepted",
      value: "accepted",
    },
    {
      label: "Looking for Driver",
      value: "looking_for_driver",
    },
    {
      label: "Rejected",
      value: "rejected",
    },
  ],
};

class VendorOrdersTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.table = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _renderAddress(address) {
    return <span>{address ? LocationFormat.fullAddress(address) : "-"}</span>;
  }

  _getColumns() {
    return [
      {
        Header: () => <strong className="header__clr">ORDER NO.</strong>,
        id: "id",
        Cell: (rowInfo) => {
          let order = rowInfo.original;
          let orderNumber = `${order.id}`;

          if (!orderNumber) {
            return "-";
          }

          return (
            <>
              <span class="text-dark-75 font-weight-bold line-height-sm">
                {orderNumber}
              </span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">VENDOR</strong>,
        id: "address",
        sortable: false,
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          let vendor = General.toTitleCase(order?.venue?.company?.title);

          let address = order.venue.address;

          if (!vendor || !address) {
            return "-";
          }

          return (
            <>
              <div className="ml-0 overflow-hidden">
                <div className="text-dark-75 font-weight-bold line-height-sm">
                  {vendor}
                </div>
                <a
                  href="#"
                  className="font-size-sm text-dark-50 text-hover-primary"
                >
                  {this._renderAddress(address)}
                </a>
              </div>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">STATUS</strong>,
        id: "status",
        sortable: false,
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          let labelClass = "";
          let status = "";

          if (order.status === "accepted") {
            labelClass = "label-light-success";
            status = "Accepted";
          } else if (order.status === "pending") {
            labelClass = "label-dark-75";
            status = "Pending";
          } else if (order.status === "looking_for_driver") {
            labelClass = "label-light-info";
            status = "Looking For Driver";
          } else if (order.status === "rejected") {
            labelClass = "label-danger";
            status = "Rejected";
          }

          if (!status) {
            return "-";
          }

          return (
            <>
              <span className={`label ${labelClass} label-inline label-pill`}>
                {status}
              </span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">DRIVER</strong>,
        id: "user__first_name",
        sortable: false,
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          let driver = `${order?.driver?.user?.first_name} ${order?.driver?.user?.last_name}`;

          if (!driver) {
            return "-";
          }

          return (
            <>
              <span>{order?.driver?.user ? driver : "-"}</span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">COST</strong>,
        id: "price",
        sortable: false,
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          let cost = order?.data?.items?.reduce((sum, item) => {
            const updatedPrice =
              sum +
              (item?.price_sale ? item?.price_sale : item?.price) *
                item?.quantity;
            return updatedPrice;
          }, 0);

          if (!cost) {
            return "-";
          }

          return (
            <>
              <span>{Currency.format(cost)}</span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">TOTAL</strong>,
        id: "price",
        sortable: false,
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          let total = order?.payment?.total;

          if (!total) {
            return "-";
          }

          return (
            <>
              <span className="text-dark-75 font-weight-bold line-height-sm">
                {Currency.format(total)}
              </span>
            </>
          );
        },
      },
      {
        Header: () => (
          <strong className="header__clr">ORDER DATE / TIME</strong>
        ),
        id: "created_at",
        Cell: (rowInfo) => {
          let order = rowInfo.original;
          let createdAt = `${moment(order?.created_at).format(
            "DD MMM YYYY - hh:mm A"
          )}`;

          if (!order?.created_at) {
            return "-";
          }

          return (
            <>
              <span className="text-dark-75 font-weight-bold line-height-sm">
                {createdAt}
              </span>
            </>
          );
        },
      },
    ];
  }

  render() {
    const { isLoading } = this.state;

    const columns = this._getColumns();

    let tableProps = {};

    return (
      <div className="col">
        <BaseTable
          {...tableProps}
          ref={this.table}
          title={this.props.title}
          loading={isLoading}
          endpoint={`${window.Api.Orders}?venue_id=${this.props.match.params?.venueId}`}
          noDataMessage={"No orders found"}
          columns={columns}
          filters={[STATUS_FILTERS]}
          showPagination={true}
          defaultSorted={[
            {
              id: "created_at",
              desc: true,
            },
          ]}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "Actions" ? "visible" : "hidden",
              },
            };
          }}
        />
      </div>
    );
  }
}

VendorOrdersTable.defaultProps = {
  title: "All Orders",
  showPagination: true,
  showPaginationTop: true,
  showPaginationBottom: true,
};

export default withRouter(VendorOrdersTable);

import React from "react";

import AsyncStorage from "./AsyncStorage";
import FetchHelper from "./FetchHelper";

import jwtDecode from "jwt-decode";
import moment from "moment";

var isLoggedIn = false;
var accessToken = null;
var refreshToken = null;
var currentUser = null;
var currentWebsite = null;

const KEY_ACCESS_TOKEN = "accessToken";
const KEY_REFRESH_TOKEN = "refreshToken";

export const KEY_WEBSITE = "website";

export default class AuthManager {
  static isAuthenticated() {
    return AuthManager.isLoggedIn;
  }

  static getAccessToken() {
    return AuthManager.accessToken;
  }

  static getCurrentUser() {
    return AuthManager.currentUser;
  }

  static isAdmin() {
    return AuthManager.currentUser.role === "admin";
  }

  static _hasError(responseJson) {
    let hasError = false;
    let tokens = responseJson.tokens;

    if (!tokens) {
      hasError = true;
    }

    if (tokens.length === 0) {
      hasError = true;
    }

    if (!tokens.access) {
      hasError = true;
    }

    if (!tokens.refresh) {
      hasError = true;
    }

    return hasError;
  }

  static register(data) {
    return FetchHelper.post(window.Api.Register, data, false, false)
      .then((responseJson) => {
        if (this._hasError(responseJson)) {
          throw AuthManager.getError(responseJson);
        }
        AuthManager._updateTokens(responseJson.tokens);
        AuthManager._setUser(responseJson, false);
        return responseJson;
      })
      .catch((error) => {
        throw AuthManager.getError(error);
      });
  }

  static login(email, password, forceWebsite = true) {
    let data = { email, password };

    return FetchHelper.post(window.Api.User.Login, data, false, false)
      .then((responseJson) => {
        if (this._hasError(responseJson)) {
          throw AuthManager.getError(responseJson);
        }

        
        if (!responseJson.admin) {
          throw {
            error: "invalid user",
            message: "Only admin accounts can access this",
          };
        }
        AuthManager._updateTokens(responseJson.tokens);
        AuthManager._setUser(responseJson, forceWebsite);

        return responseJson;
      })
      .catch((error) => {
        throw AuthManager.getError(error);
      });
  }

  static _getMinutesUntilTokenExpiration() {
    var decodedJWT = jwtDecode(AuthManager.accessToken);
    var exp = decodedJWT.exp * 1000;
    var expirationTime = moment(exp);
    var today = moment();
    let absoluteDifference = Math.abs(expirationTime.diff(today, "minutes"));
    return absoluteDifference;
  }

  static async validateTokens(onSuccess, onError) {
    let remainingMinutes = AuthManager._getMinutesUntilTokenExpiration();

    if (remainingMinutes > 1) {
      onSuccess();
      return;
    }

    return AuthManager.refreshTokens(() => {
      return onSuccess();
    }).catch((error) => {
      onError();
      // Token has expired.
      window.location.href = "/account";
    });
  }

  static refreshTokens() {
    return AsyncStorage.getItem(KEY_REFRESH_TOKEN)
      .then((refreshToken) => {
        if (!refreshToken) {
          throw { message: "No Refresh Token Found" };
          return;
        }
        // try and refresh the token if we find it, if this fails
        // our token has expired and we will need user to re login
        // manually
        const data = { refresh: refreshToken };

        return FetchHelper.post(
          window.Api.User.RefreshToken,
          data,
          false,
          false
        );
      })
      .then((tokenResponse) => {
        return AuthManager._updateTokens(tokenResponse);
      });
  }

  static silentLogin() {
    return AuthManager.refreshTokens()
      .then(() => {
        return FetchHelper.get(window.Api.User.Info);
      })
      .then((responseJson) => {
        AuthManager._setUser(responseJson);
        return AuthManager.currentUser;
      })
      .catch((error) => {
        AuthManager.accessToken = null;
        AuthManager.refreshToken = null;
        throw error;
      });
  }

  static async logout() {
    let data = { refresh: AuthManager.refreshToken };
    return FetchHelper.post(window.Api.User.Logout, data).then(() => {
      AuthManager.removeCredentials();
      return;
    });
  }

  static requestResetPassword(email) {
    return FetchHelper.post(
      window.Api.User.RequestResetPassword,
      {
        email,
      },
      false,
      false
    );
  }

  static resetPassword(email, password, code) {
    let data = {
      email,
      password,
      verification_code: code,
    };
    return FetchHelper.post(window.Api.User.ResetPassword, data, false, false);
  }

  static removeCredentials() {
    AuthManager.accessToken = null;
    AuthManager.refreshToken = null;
    AuthManager.isLoggedIn = false;
    AuthManager.currentUser = null;
    AuthManager.currentWebsite = null;
    AsyncStorage.removeItem(KEY_WEBSITE);
    AsyncStorage.removeItem(KEY_ACCESS_TOKEN);
    return AsyncStorage.removeItem(KEY_REFRESH_TOKEN);
  }

  static getError(error) {
    var errorMessage = "An unexpected error occured";
    if (error.email) {
      errorMessage = error.email[0];
    } else if (error.message) {
      errorMessage = error.message;
    } else if (error.non_field_errors) {
      errorMessage = error.non_field_errors[0];
    } else if (error.detail) {
      errorMessage = error.detail;
    }
    return { error: errorMessage, message: errorMessage };
  }

  static _updateTokens(tokens) {
    AuthManager.accessToken = tokens.access;
    AuthManager.refreshToken = tokens.refresh;
    AsyncStorage.setItem(KEY_ACCESS_TOKEN, AuthManager.accessToken);
    AsyncStorage.setItem(KEY_REFRESH_TOKEN, AuthManager.refreshToken);
  }

  static _setUser(responseJson) {
    if (responseJson.admin) {
      AuthManager.currentUser = responseJson.admin;
      AuthManager.userType = responseJson.user.role;
    } else {
      throw {
        detail: "Only admin can access this platform",
      };
    }
  }

  static _getCurrentUser(responseJson) {
    if (responseJson.admin) {
      return responseJson.admin;
    }
    return null;
  }

  static getHeaders(contentType = "application/json") {
    var headers = {};

    if (contentType === "application/json") {
      headers = { "Content-Type": contentType };
    }
    if (AuthManager.accessToken) {
      headers["Authorization"] = "Bearer " + AuthManager.accessToken;
    }
    return new Headers(headers);
  }
}

import React, { Component } from "react";

import { Line } from "react-chartjs-2";

import Backend from "../utils/Backend";
import Notify from "../utils/Notify";
import Currency from "../utils/Currency";

import StatsCard from "./components/stats-cards/StatsCard";

export default class Sales extends Component {
  constructor(props) {
    super(props);

    this.state = {
      graphStats: [],
      monthlyStats: [],
      totalEarnings: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this._getGraphStats();
    this._getMonthlyStats();
    this._getTotalEarnings();
  }

  _getGraphStats() {
    Backend.getDailyStats()
      .then((graphStats) => {
        this.setState({ graphStats, isLoading: false });
        let graphMap = graphStats?.total;

        let date = graphMap?.map((stat, index) => stat?.date);

        let salesCount = graphMap?.map((statInfo) => statInfo?.sales_count);

        let totalEarnings = graphMap?.map(
          (statInfo) => statInfo?.earnings / 100
        );

        let graphData = {
          labels: date,

          datasets: [
            {
              label: "Value of Sales",
              data: totalEarnings,
              fill: true,
              borderColor: "#1BC5BD",
              borderWidth: 4,
              pointDot: false,
              backgroundColor: "rgba(75,192,192,0.2)",
            },
            {
              label: "Amount of Sales",
              data: salesCount,
              fill: true,
              borderColor: "#C63030",
              borderWidth: 4,
              pointDot: false,
              backgroundColor: "rgb(255,0,0, 0.3)",
            },
          ],
        };

        this.setState({
          graphs: graphData,
          isLoading: false,
        });

        setTimeout(() => {
          this.setState({
            isLoading: false,
          });
        }, 300);
      })
      .catch((error) => {
        Notify.error(error.message);
      });
  }

  _getTotalEarnings() {
    this.setState({ isLoading: true });

    Backend.getTotalEarnings()
      .then((totalEarnings) => {
        this.setState({ totalEarnings, isLoading: false });
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  }

  _getMonthlyStats() {
    this.setState({ isLoading: true });

    Backend.getMonthlyStats()
      .then((monthlyStats) => {
        this.setState({ monthlyStats, isLoading: false });
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  }

  _renderGraphs() {
    let { graphs, isLoading } = this.state;

    if (isLoading) {
      return null;
    }

    return <Line data={graphs} />;
  }

  _getWeeklySales() {
    const { graphStats } = this.state;

    let weeklySales = graphStats?.total?.reduce((sum, sales) => {
      const updatedWeeklySales = sum + sales.sales_count;

      return updatedWeeklySales;
    }, 0);

    return weeklySales;
  }

  _getMonthlySales() {
    const { monthlyStats } = this.state;

    let monthlySales = monthlyStats?.total?.reduce((sum, sales) => {
      const updatedMonthlySales = sum + sales.sales_count;

      return updatedMonthlySales;
    }, 0);

    return monthlySales;
  }

  render() {
    let { totalEarnings } = this.state;

    return (
      <div className="container">
        <div className="row wd-100">
          <div className="col-lg-4">
            {" "}
            <StatsCard
              data={[
                {
                  statsInfo:
                    totalEarnings?.total_earnings &&
                    `${Currency.format(totalEarnings?.total_earnings)}`,
                  title: "Total Revenue",
                  icon: (
                    <g
                      stroke="none"
                      strokeWidth={1}
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x={0} y={0} width={24} height={24} />
                      <path
                        d="M4.3618034,10.2763932 L4.8618034,9.2763932 C4.94649941,9.10700119 5.11963097,9 5.30901699,9 L15.190983,9 C15.4671254,9 15.690983,9.22385763 15.690983,9.5 C15.690983,9.57762255 15.6729105,9.65417908 15.6381966,9.7236068 L15.1381966,10.7236068 C15.0535006,10.8929988 14.880369,11 14.690983,11 L4.80901699,11 C4.53287462,11 4.30901699,10.7761424 4.30901699,10.5 C4.30901699,10.4223775 4.32708954,10.3458209 4.3618034,10.2763932 Z M14.6381966,13.7236068 L14.1381966,14.7236068 C14.0535006,14.8929988 13.880369,15 13.690983,15 L4.80901699,15 C4.53287462,15 4.30901699,14.7761424 4.30901699,14.5 C4.30901699,14.4223775 4.32708954,14.3458209 4.3618034,14.2763932 L4.8618034,13.2763932 C4.94649941,13.1070012 5.11963097,13 5.30901699,13 L14.190983,13 C14.4671254,13 14.690983,13.2238576 14.690983,13.5 C14.690983,13.5776225 14.6729105,13.6541791 14.6381966,13.7236068 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                      <path
                        d="M17.369,7.618 C16.976998,7.08599734 16.4660031,6.69750122 15.836,6.4525 C15.2059968,6.20749878 14.590003,6.085 13.988,6.085 C13.2179962,6.085 12.5180032,6.2249986 11.888,6.505 C11.2579969,6.7850014 10.7155023,7.16999755 10.2605,7.66 C9.80549773,8.15000245 9.45550123,8.72399671 9.2105,9.382 C8.96549878,10.0400033 8.843,10.7539961 8.843,11.524 C8.843,12.3360041 8.96199881,13.0779966 9.2,13.75 C9.43800119,14.4220034 9.7774978,14.9994976 10.2185,15.4825 C10.6595022,15.9655024 11.1879969,16.3399987 11.804,16.606 C12.4200031,16.8720013 13.1129962,17.005 13.883,17.005 C14.681004,17.005 15.3879969,16.8475016 16.004,16.5325 C16.6200031,16.2174984 17.1169981,15.8010026 17.495,15.283 L19.616,16.774 C18.9579967,17.6000041 18.1530048,18.2404977 17.201,18.6955 C16.2489952,19.1505023 15.1360064,19.378 13.862,19.378 C12.6999942,19.378 11.6325049,19.1855019 10.6595,18.8005 C9.68649514,18.4154981 8.8500035,17.8765035 8.15,17.1835 C7.4499965,16.4904965 6.90400196,15.6645048 6.512,14.7055 C6.11999804,13.7464952 5.924,12.6860058 5.924,11.524 C5.924,10.333994 6.13049794,9.25950479 6.5435,8.3005 C6.95650207,7.34149521 7.5234964,6.52600336 8.2445,5.854 C8.96550361,5.18199664 9.8159951,4.66400182 10.796,4.3 C11.7760049,3.93599818 12.8399943,3.754 13.988,3.754 C14.4640024,3.754 14.9609974,3.79949954 15.479,3.8905 C15.9970026,3.98150045 16.4939976,4.12149906 16.97,4.3105 C17.4460024,4.49950095 17.8939979,4.7339986 18.314,5.014 C18.7340021,5.2940014 19.0909985,5.62999804 19.385,6.022 L17.369,7.618 Z"
                        fill="#000000"
                      />
                    </g>
                  ),
                },
              ]}
            />{" "}
          </div>
          <div className="col-lg-4">
            {" "}
            <StatsCard
              data={[
                {
                  statsInfo: this._getMonthlySales(),
                  title: "Sales this month",
                  icon: (
                    <g
                      stroke="none"
                      strokeWidth={1}
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x={0} y={0} width={24} height={24} />
                      <path
                        d="M5.94290508,4 L18.0570949,4 C18.5865712,4 19.0242774,4.41271535 19.0553693,4.94127798 L19.8754445,18.882556 C19.940307,19.9852194 19.0990032,20.9316862 17.9963398,20.9965487 C17.957234,20.9988491 17.9180691,21 17.8788957,21 L6.12110428,21 C5.01653478,21 4.12110428,20.1045695 4.12110428,19 C4.12110428,18.9608266 4.12225519,18.9216617 4.12455553,18.882556 L4.94463071,4.94127798 C4.97572263,4.41271535 5.41342877,4 5.94290508,4 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                      <path
                        d="M7,7 L9,7 C9,8.65685425 10.3431458,10 12,10 C13.6568542,10 15,8.65685425 15,7 L17,7 C17,9.76142375 14.7614237,12 12,12 C9.23857625,12 7,9.76142375 7,7 Z"
                        fill="#000000"
                      />
                    </g>
                  ),
                },
              ]}
            />
          </div>
          <div className="col-lg-4">
            {" "}
            <StatsCard
              data={[
                {
                  statsInfo: this._getWeeklySales(),
                  title: "Sales this week",
                  icon: (
                    <g
                      stroke="none"
                      strokeWidth={1}
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x={0} y={0} width={24} height={24} />
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x={7}
                        y={4}
                        width={10}
                        height={4}
                      />
                      <path
                        d="M7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,20 C19,21.1045695 18.1045695,22 17,22 L7,22 C5.8954305,22 5,21.1045695 5,20 L5,4 C5,2.8954305 5.8954305,2 7,2 Z M8,12 C8.55228475,12 9,11.5522847 9,11 C9,10.4477153 8.55228475,10 8,10 C7.44771525,10 7,10.4477153 7,11 C7,11.5522847 7.44771525,12 8,12 Z M8,16 C8.55228475,16 9,15.5522847 9,15 C9,14.4477153 8.55228475,14 8,14 C7.44771525,14 7,14.4477153 7,15 C7,15.5522847 7.44771525,16 8,16 Z M12,12 C12.5522847,12 13,11.5522847 13,11 C13,10.4477153 12.5522847,10 12,10 C11.4477153,10 11,10.4477153 11,11 C11,11.5522847 11.4477153,12 12,12 Z M12,16 C12.5522847,16 13,15.5522847 13,15 C13,14.4477153 12.5522847,14 12,14 C11.4477153,14 11,14.4477153 11,15 C11,15.5522847 11.4477153,16 12,16 Z M16,12 C16.5522847,12 17,11.5522847 17,11 C17,10.4477153 16.5522847,10 16,10 C15.4477153,10 15,10.4477153 15,11 C15,11.5522847 15.4477153,12 16,12 Z M16,16 C16.5522847,16 17,15.5522847 17,15 C17,14.4477153 16.5522847,14 16,14 C15.4477153,14 15,14.4477153 15,15 C15,15.5522847 15.4477153,16 16,16 Z M16,20 C16.5522847,20 17,19.5522847 17,19 C17,18.4477153 16.5522847,18 16,18 C15.4477153,18 15,18.4477153 15,19 C15,19.5522847 15.4477153,20 16,20 Z M8,18 C7.44771525,18 7,18.4477153 7,19 C7,19.5522847 7.44771525,20 8,20 L12,20 C12.5522847,20 13,19.5522847 13,19 C13,18.4477153 12.5522847,18 12,18 L8,18 Z M7,4 L7,8 L17,8 L17,4 L7,4 Z"
                        fill="#000000"
                      />
                    </g>
                  ),
                },
              ]}
            />{" "}
          </div>
        </div>
        <div className="row wd-100">
          <div className="col-md-12">
            <div className="card card-custom gutter-b">
              <div className="card-header">
                <div className="card-title">
                  <h3 className="card-label">Sales Numbers</h3>
                </div>
                <div className="card-toolbar"></div>
              </div>
              <div className="card-body" style={{ position: "relative" }}>
                {this._renderGraphs()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

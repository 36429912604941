import React from "react";

import Creatable from "react-select/creatable";
import AsyncPaginate from "react-select-async-paginate";

import FetchHelper from "../../../utils/FetchHelper";

export default class AsyncSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this._getState(props),
      search: "",
      prevSearch: "",
      items: [],
      nextPageUrl: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps));
  }

  _getState(props) {
    return {
      ...props,
      placeholder: props.placeholder,
      endpoint: props.endpoint,
      isMulti: props.isMulti ? props.isMulti : false,
      creatable: props.creatable ? props.creatable : false,
      styles: props.styles,
      filter: props.filter,
      value: props.value,
      disabled: props.disabled,
    };
  }

  _loadOptions(search, prevOptions) {
    let { endpoint, prevSearch, nextPageUrl, filter, items } = this.state;

    let url = `${endpoint}?${filter + "&"}search_term=${search}&order_by=${
      this.props.orderBy
    }`;

    let nextPage = false;
    if (search === prevSearch && nextPageUrl) {
      url = nextPageUrl;
      nextPage = true;
    }

    return FetchHelper.get(url).then((response) => {
      let results = this.props.paginated ? response.results : response;
      let newOptions = this.props.getOptions(results);
      if (nextPage) {
        items = [...items, ...results];
      } else {
        items = results;
      }

      this.setState({
        items,
        nextPageUrl: this.props.paginated ? response.next : false,
        prevSearch: search,
      });
      return {
        options: newOptions,
        hasMore: this.props.paginated ? response.next != null : false,
      };
    });
  }

  _handleChange(option) {
    if (this.state.isMulti) {
      this._handleChangeMulti(option);
    } else if (option.__isNew__) {
      this.setState({ value: option }, () => {
        this.props.onCreated(option);
      });
    } else {
      this.setState({ value: option }, () => {
        this.props.onSelected(option.data);
      });
    }
  }

  _handleChangeMulti(options) {
    options = options || [];
    let lastOption = options[options.length - 1];

    if (lastOption && lastOption.__isNew__) {
      this.props.onCreated(lastOption);
      return;
    }

    this.setState({ value: options }, () => {
      this.props.onSelected(this.state.value);
    });
  }

  render() {
    let props = {};

    if (this.state.creatable) {
      props.SelectComponent = Creatable;
    }
    return (
      <AsyncPaginate
        {...props}
        isMulti={this.props.isMulti}
        value={this.state.value}
        styles={this.state.styles}
        closeMenuOnSelect={!this.state.isMulti}
        loadOptions={this._loadOptions.bind(this)}
        debounceTimeout={300}
        createOptionPosition={this.props.createOptionPosition}
        formatCreateLabel={(inputValue) => {
          if (this.props.buttonLabel) {
            return <b>{this.props.buttonLabel}</b>;
          }
          if (!inputValue) {
            return <b>+ Create</b>;
          }
          return <b>{`+ Create "${inputValue}"`}</b>;
        }}
        isValidNewOption={({ inputValue, selectValue, selectOptions }) => {
          return true;
        }}
        onChange={(value) => {
          this._handleChange(value);
        }}
        isDisabled={this.state.disabled}
        classNamePrefix={this.props.classNamePrefix}
      />
    );
  }
}

AsyncSelect.defaultProps = {
  isMulti: false,
  orderBy: "name",
  paginated: true,
  classNamePrefix: "react_select",
  createOptionPosition: "last",
};

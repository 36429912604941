import React from "react";

import moment from "moment";

import { withRouter } from "react-router-dom";

import LocationFormat from "../../../../utils/LocationFormat";
import Currency from "../../../../utils/Currency";
import General from "../../../../utils/General";
import Backend from "../../../../utils/Backend";
import Notify from "../../../../utils/Notify";

import BaseTable from "../BaseTable";

const STATUS_FILTERS = {
  name: {
    api: "statuses",
    display: "Status",
  },
  values: [
    {
      label: "All",
      value: null,
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Accepted",
      value: "accepted",
    },
    {
      label: "Looking for Driver",
      value: "looking_for_driver",
    },
    {
      label: "Rejected",
      value: "rejected",
    },
  ],
};

const DELIVERY_STATUS_FILTERS = {
  name: {
    api: "delivery_statuses",
    display: "Delivery Status",
  },
  values: [
    {
      label: "All",
      value: null,
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Out For Delivery",
      value: "out_for_delivery",
    },
    {
      label: "Failed",
      value: "failed",
    },
    {
      label: "Returned",
      value: "returned",
    },
    {
      label: "Delivered",
      value: "delivered",
    },
  ],
};

class OrdersHistoryTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.table = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _renderAddress(address) {
    return <span>{address ? LocationFormat.fullAddress(address) : "-"}</span>;
  }

  _cancelOrder(orderId) {
    this.setState({ isLoading: true });
    Backend.cancelOrder(orderId)
      .then((data) => {
        this.setState({ data, isLoading: false });
        window.location.reload();
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  _getColumns() {
    return [
      {
        Header: () => <strong className="header__clr">ORDER NO.</strong>,
        id: "id",
        width: 95,
        Cell: (rowInfo) => {
          let order = rowInfo.original;
          let orderNumber = `${order.id}`;

          if (!orderNumber) {
            return "-";
          }

          return (
            <>
              <span className="text-dark-75 font-weight-bold line-height-sm">
                {orderNumber}
              </span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">VENDOR</strong>,
        id: "address",
        sortable: false,
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          let vendor = General.toTitleCase(order?.venue?.company?.title);

          let address = order?.venue?.address;

          if (!vendor || !address) {
            return "-";
          }

          return (
            <>
              <div className="ml-0 overflow-hidden">
                <div className="text-dark-75 font-weight-bold line-height-sm">
                  {vendor}
                </div>
                <a
                  href="javascript:;"
                  className="font-size-sm text-dark-50 text-hover-primary"
                >
                  {this._renderAddress(address)}
                </a>
              </div>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">STATUS</strong>,
        id: "status",
        width: 135,
        sortable: false,
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          let labelClass = "";
          let status = "";

          if (order.status === "accepted") {
            labelClass = "label-light-success";
            status = "Accepted";
          } else if (order.status === "pending") {
            labelClass = "label-dark-75";
            status = "Pending";
          } else if (order.status === "looking_for_driver") {
            labelClass = "label-light-info";
            status = "Looking For Driver";
          } else if (order.status === "rejected") {
            labelClass = "label-danger";
            status = "Rejected";
          }

          if (!status) {
            return "-";
          }

          return (
            <>
              <span className={`label ${labelClass} label-inline label-pill`}>
                {status}
              </span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">DELIVERY STATUS</strong>,
        id: "delivery_status",
        width: 135,
        sortable: false,
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          let labelClass = "";
          let deliveryStatus = "";

          if (order.delivery_status === "delivered") {
            labelClass = "label-light-success";
            deliveryStatus = "Delivered";
          } else if (order.delivery_status === "pending") {
            labelClass = "label-light-info";
            deliveryStatus = "Pending";
          } else if (order.delivery_status === "failed") {
            labelClass = "label-dark-75";
            deliveryStatus = "Failed";
          } else if (order.delivery_status === "returned") {
            labelClass = "label-danger";
            deliveryStatus = "Returned";
          } else if (order.delivery_status === "out_for_delivery") {
            labelClass = "label-warning";
            deliveryStatus = "Out For Delivery";
          }

          if (!deliveryStatus) {
            return "-";
          }

          return (
            <>
              <span className={`label ${labelClass} label-inline label-pill`}>
                {deliveryStatus}
              </span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">DRIVER</strong>,
        id: "user__first_name",
        sortable: false,
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          let driver = "";

          if (order?.driver) {
            driver = `${General.toTitleCase(
              order?.driver?.user?.first_name
            )} ${General.toTitleCase(order?.driver?.user?.last_name)}`;
          } else {
            driver = "-";
          }

          return (
            <>
              <span>{driver}</span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">TOTAL</strong>,
        id: "price",
        width: 135,
        sortable: false,
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          let cost = order?.payment?.total;

          if (!cost) {
            return "-";
          }

          return (
            <>
              <span>{Currency.format(cost)}</span>
            </>
          );
        },
      },
      {
        Header: () => (
          <strong className="header__clr">ORDER DATE / TIME</strong>
        ),
        id: "created_at",
        Cell: (rowInfo) => {
          let order = rowInfo.original;
          let createdAt = `${moment(order?.created_at).format(
            "DD MMM YYYY - hh:mm A"
          )}`;

          if (!order?.created_at) {
            return "-";
          }

          return (
            <>
              <span className="text-dark-75 font-weight-bold line-height-sm">
                {createdAt}
              </span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">ACTIONS</strong>,
        id: "id",
        width: 90,
        sortable: false,
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          let contactEmail = order?.venue?.contact_email;

          return (
            <>
              <span style={{ width: 130 }}>
                <a
                  href={`mailto: ${contactEmail}`}
                  target="_blank"
                  className="btn btn-icon btn-sm btn-pill btn-outline-primary ml-3"
                  data-toggle="popover"
                  data-content="Contact Vendor"
                  title="Email Vendor"
                >
                  <span className="svg-icon svg-icon-md">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect x={0} y={0} width={24} height={24} />
                        <path
                          d="M5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,17 C21,18.1045695 20.1045695,19 19,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z"
                          fill="#000000"
                        />
                      </g>
                    </svg>
                  </span>
                </a>
                <a
                  href="javascript:;"
                  className="btn btn-icon btn-sm btn-pill btn-outline-primary ml-1"
                  data-toggle="popover"
                  data-content="Cancel Order"
                  title="Cancel Order"
                  onClick={() => this._cancelOrder(order?.id)}
                >
                  <span className="svg-icon svg-icon-md">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 48 48"
                      version="1.1"
                    >
                        <linearGradient id="wRKXFJsqHCxLE9yyOYHkza_fYgQxDaH069W_gr1" x1="9.858" x2="38.142" y1="9.858" y2="38.142" gradientUnits="userSpaceOnUse">
                          <stop offset="0" stop-color="#f44f5a"></stop>
                          <stop offset=".443" stop-color="#ee3d4a"></stop>
                          <stop offset="1" stop-color="#e52030"></stop>
                        </linearGradient>
                        <path fill="#C63030" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path>
                        <path d="M33.192,28.95L28.243,24l4.95-4.95c0.781-0.781,0.781-2.047,0-2.828l-1.414-1.414	c-0.781-0.781-2.047-0.781-2.828,0L24,19.757l-4.95-4.95c-0.781-0.781-2.047-0.781-2.828,0l-1.414,1.414	c-0.781,0.781-0.781,2.047,0,2.828l4.95,4.95l-4.95,4.95c-0.781,0.781-0.781,2.047,0,2.828l1.414,1.414	c0.781,0.781,2.047,0.781,2.828,0l4.95-4.95l4.95,4.95c0.781,0.781,2.047,0.781,2.828,0l1.414-1.414	C33.973,30.997,33.973,29.731,33.192,28.95z" opacity=".05"></path>
                        <path d="M32.839,29.303L27.536,24l5.303-5.303c0.586-0.586,0.586-1.536,0-2.121l-1.414-1.414	c-0.586-0.586-1.536-0.586-2.121,0L24,20.464l-5.303-5.303c-0.586-0.586-1.536-0.586-2.121,0l-1.414,1.414	c-0.586,0.586-0.586,1.536,0,2.121L20.464,24l-5.303,5.303c-0.586,0.586-0.586,1.536,0,2.121l1.414,1.414	c0.586,0.586,1.536,0.586,2.121,0L24,27.536l5.303,5.303c0.586,0.586,1.536,0.586,2.121,0l1.414-1.414	C33.425,30.839,33.425,29.889,32.839,29.303z" opacity=".07"></path>
                        <path fill="#fff" d="M31.071,15.515l1.414,1.414c0.391,0.391,0.391,1.024,0,1.414L18.343,32.485	c-0.391,0.391-1.024,0.391-1.414,0l-1.414-1.414c-0.391-0.391-0.391-1.024,0-1.414l14.142-14.142	C30.047,15.124,30.681,15.124,31.071,15.515z"></path>
                        <path fill="#fff" d="M32.485,31.071l-1.414,1.414c-0.391,0.391-1.024,0.391-1.414,0L15.515,18.343	c-0.391-0.391-0.391-1.024,0-1.414l1.414-1.414c0.391-0.391,1.024-0.391,1.414,0l14.142,14.142	C32.876,30.047,32.876,30.681,32.485,31.071z"></path>
                    </svg>
                  </span>
                </a>
              </span>
            </>
          );
        },
      },
    ];
  }

  render() {
    const { isLoading } = this.state;

    const columns = this._getColumns();

    let tableProps = {};

    return (
      <>
        <BaseTable
          {...tableProps}
          ref={this.table}
          title={this.props.title}
          loading={isLoading}
          endpoint={`${window.Api.Orders}`}
          noDataMessage={"No orders found"}
          columns={columns}
          filters={[STATUS_FILTERS, DELIVERY_STATUS_FILTERS]}
          showPagination={true}
          defaultSorted={[
            {
              id: "created_at",
              desc: true,
            },
          ]}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "Actions" ? "visible" : "hidden",
              },
            };
          }}
        />
      </>
    );
  }
}

OrdersHistoryTable.defaultProps = {
  title: "All Orders",
  showPagination: true,
  showPaginationTop: true,
  showPaginationBottom: true,
};

export default withRouter(OrdersHistoryTable);

import React from "react";

import moment from "moment";

import { withRouter } from "react-router-dom";

import LocationFormat from "../../../../utils/LocationFormat";
import Currency from "../../../../utils/Currency";
import BaseTable from "../BaseTable";
import General from "../../../../utils/General";

const TYPE_FILTERS = {
  name: {
    api: "type",
    display: "Type",
  },
  values: [
    {
      label: "All",
      value: null,
    },
    {
      label: "Delivery",
      value: "delivery",
    },
    {
      label: "Return",
      value: "return",
    },
  ],
};

class DriversPaymentsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.table = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _renderAddress(address) {
    return <span>{address ? LocationFormat.fullAddress(address) : "-"}</span>;
  }

  _getColumns() {
    return [
      {
        Header: () => <strong className="header__clr">INVOICE NO.</strong>,
        id: "invoice_number",
        Cell: (rowInfo) => {
          let payment = rowInfo.original;

          let invoiceNumber = payment?.id;

          if (!invoiceNumber) {
            return "-";
          }

          return (
            <>
              <span className="text-dark-75 font-weight-bold line-height-sm">
                {invoiceNumber}
              </span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">DRIVER</strong>,
        id: "user__first_name",
        sortable: false,
        Cell: (rowInfo) => {
          let payment = rowInfo.original;

          let driver = "";

          if (payment?.driver?.user) {
            driver = `${General.toTitleCase(
              payment?.driver?.user?.first_name
            )} ${General.toTitleCase(payment?.driver?.user?.last_name)}`;
          } else {
            driver = "-";
          }

          return (
            <>
              <span>{driver}</span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">PAYMENT TYPE</strong>,
        id: "type",
        sortable: false,
        Cell: (rowInfo) => {
          let payment = rowInfo.original;

          let labelClass = "";
          let status = "";

          if (payment?.type === "delivery") {
            labelClass = "label-light-success";
            status = "Delivery";
          } else if (payment?.type === "return") {
            labelClass = "label-light-info";
            status = "Return";
          }

          if (!status) {
            return "-";
          }

          return (
            <span className={`label ${labelClass} label-inline label-pill`}>
              {status}
            </span>
          );
        },
      },

      {
        Header: () => <strong className="header__clr">AMOUNT</strong>,
        id: "amount",
        sortable: false,
        Cell: (rowInfo) => {
          let payment = rowInfo.original;

          let amount = Currency.format(payment?.amount);

          if (!amount) {
            return "-";
          }

          return (
            <>
              <span>{amount}</span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">DATE / TIME</strong>,
        id: "created_at",
        Cell: (rowInfo) => {
          let payment = rowInfo.original;
          let createdAt = `${moment(payment?.created_at).format(
            "DD MMM YYYY - hh:mm A"
          )}`;

          if (!payment?.created_at) {
            return "-";
          }

          return (
            <>
              <span className="text-dark-75 font-weight-bold line-height-sm">
                {createdAt}
              </span>
            </>
          );
        },
      },
    ];
  }
  render() {
    const { isLoading } = this.state;

    const columns = this._getColumns();

    let tableProps = {};

    return (
      <>
        <BaseTable
          {...tableProps}
          ref={this.table}
          title={this.props.title}
          loading={isLoading}
          endpoint={`${window.Api.DriverPayments}`}
          noDataMessage={"No drivers payments found"}
          columns={columns}
          filters={[TYPE_FILTERS]}
          showPagination={true}
          defaultSorted={[
            {
              id: "created_at",
              desc: true,
            },
          ]}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "Actions" ? "visible" : "hidden",
              },
            };
          }}
        />
      </>
    );
  }
}

DriversPaymentsTable.defaultProps = {
  title: "Drivers Payments",
  showPagination: true,
  showPaginationTop: true,
  showPaginationBottom: true,
};

export default withRouter(DriversPaymentsTable);

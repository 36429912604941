import React, { Component } from "react";

import Modal from "react-bootstrap/Modal";

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import General from "../../../utils/General";

import ImageUpload from "../common/ImageUpload";
import PhoneInput from "../PhoneInput";

import Select from "../common/Select";

const VEHICLE_TYPE_OPTIONS = [
  {
    label: "Car",
    value: "car",
  },
  {
    label: "Bicycle",
    value: "bicycle",
  },
  {
    label: "Scooter",
    value: "scooter",
  },
];

export default class EditDriverModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this._getState(props),
      show: props.show,
      isLoading: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps));
  }

  _getState(props) {
    return {
      ...props,
      data: General.clone(props.data),
    };
  }
  _handleUserChange(e) {
    let { data } = this.state;

    let value = e.target.value;

    data.user[e.target.name] = value;

    this.setState({ data });
  }

  _handleChange(e) {
    let { data } = this.state;

    let value = e.target.value;

    data[e.target.name] = value;

    this.setState({ data });
  }

  _isFormValid() {
    const { data } = this.state;

    let error = null;

    if (!data?.user.first_name) {
      error = "Please enter a valid first name.";
    } else if (!data?.user.last_name) {
      error = "Please enter a valid last name.";
    } else if (!data?.user.email) {
      error = "Please enter a valid email.";
    } else if (!data?.user.phone_number) {
      error = "Please enter a valid phone number.";
    } else if (!data?.ppsn) {
      error = "Please enter a valid ppsn.";
    } else if (!data?.vehicle_type) {
      error = "Please enter a valid vehicle type.";
    } else if (
      data?.user.password &&
      data?.user.password !== data?.user.confirm_password
    ) {
      error = "Passwords do not match.";
    }

    if (error) {
      Notify.error(error);
      return false;
    }

    return true;
  }

  _updateDriver() {
    let { data } = this.state;

    data = General.clone(data)

    if (!this._isFormValid()) {
      return;
    }

    if (data?.identification !== null) {
      data.identification.type = "driver_license";
      data.identification.front = data.identification.front.id;
      data.identification.back = data.identification.back.id;
    }

    data.vehicle_registration_no = data.vehicle_registration_no ?? null;

    if (data.user.password == null || !data.user.password) {
      delete data.user.password;
      delete data.user.confirm_password;
    }

    this.setState({ isLoading: true });

    Backend.updateDriver(data)
      .then((data) => {
        this.setState({ data, isLoading: false });
        Notify.success("Driver has been updated successfully.");
        this.props.onUpdated();
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  render() {
    let { show, data, isLoading } = this.state;

    if (!data) {
      return null;
    }

    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide();
          }
        }}
      >
        <div className="modal-content">
          <Modal.Header closeButton>
            <Modal.Title>Edit Driver</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <form>
              <div className="form-group">
                <label>
                  Full Name
                  <span className="text-danger">*</span>
                </label>
                <div className="row">
                  <div className="col-lg-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      name="first_name"
                      value={data?.user?.first_name}
                      onChange={(e) => this._handleUserChange(e)}
                    />
                  </div>
                  <div className="col-lg-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      name="last_name"
                      value={data?.user?.last_name}
                      onChange={(e) => this._handleUserChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>
                  Email Address
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email Address"
                  name="email"
                  value={data?.user?.email}
                  onChange={(e) => this._handleUserChange(e)}
                />
              </div>
              <PhoneInput
                spanClassName="text-danger"
                value={data?.user?.phone_number}
                onChange={(e) => this._handleUserChange(e)}
              />
              <div className="form-group">
                <label>
                  PPSN
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter PPSN"
                  name="ppsn"
                  value={data?.ppsn}
                  onChange={(e) => this._handleChange(e)}
                />
              </div>
              <div className="form-group">
                <label>Vehicle Type</label>
                <Select
                  value={VEHICLE_TYPE_OPTIONS.find(
                    (option) => option.value === data.vehicle_type
                  )}
                  className="async-select-paginate"
                  classNamePrefix="async-select-paginate"
                  onChange={(option) => {
                    data.vehicle_type = option.value;
                    this.setState({ data });
                  }}
                  options={VEHICLE_TYPE_OPTIONS}
                  isDisabled={data?.vehicle_type}
                />
              </div>
              {data?.vehicle_type !== "bicycle" && (
                <>
                  <div id="vehicle-reg" className="form-group">
                    <label>
                      Vehicle Registration
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter vehicle registration number"
                      name="vehicle_registration_no"
                      value={data?.vehicle_registration_no}
                      onChange={(e) => this._handleChange(e)}
                    />
                  </div>
                  <div id="Drivers Licence" className="form-group">
                    <label>
                      Drivers Licence - Front
                      <span className="text-danger">*</span>
                    </label>
                    <ImageUpload
                      image={data?.identification?.front}
                      hidePreview={false}
                      cropImage={true}
                      aspectRatio={540 / 139}
                      onUpdated={(front) => {
                        data.identification.front = front;

                        this.setState({ data });
                      }}
                    />
                  </div>
                  <div id="Drivers Licence" className="form-group">
                    <label>
                      Drivers Licence - Back
                      <span className="text-danger">*</span>
                    </label>
                    <ImageUpload
                      image={data?.identification?.back}
                      hidePreview={false}
                      cropImage={true}
                      aspectRatio={540 / 139}
                      onUpdated={(back) => {
                        data.identification.back = back;

                        this.setState({ data });
                      }}
                    />
                  </div>
                </>
              )}

              <div className="form-group">
                <label htmlFor="exampleInputPassword1">
                  Password
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Password"
                  name="password"
                  value={data?.user?.password}
                  onChange={(e) => this._handleUserChange(e)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputConfirmPassword1">
                  Confirm Password
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputConfirmPassword1"
                  placeholder="Confirm Password"
                  name="confirm_password"
                  value={data?.user?.confirm_password}
                  onChange={(e) => this._handleUserChange(e)}
                />
              </div>
            </form>
          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              className="btn btn-outline-primary font-weight-bold"
              onClick={() => this.props.onHide()}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary font-weight-bold"
              disabled={isLoading}
              onClick={() => this._updateDriver()}
            >
              Save Changes
            </button>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }
}

import React, { Component } from "react";

import Modal from "react-bootstrap/Modal";

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import General from "../../../utils/General";

export default class EditUserModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this._getState(props),
      show: props.show,
      isLoading: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps));
  }

  _getState(props) {
    return {
      ...props,
      data: General.clone(props.data),
    };
  }

  _handleChange(e) {
    let { data } = this.state;

    let value = e.target.value;

    data.user[e.target.name] = value;

    this.setState({ data });
  }

  _isFormValid() {
    const { data } = this.state;

    let error = null;

    if (!data?.user?.first_name) {
      error = "Please enter a valid first name.";
    } else if (!data?.user?.last_name) {
      error = "Please enter a valid last name.";
    } else if (!data?.user?.email) {
      error = "Please enter a valid email.";
    }

    if (error) {
      Notify.error(error);
      return false;
    }

    return true;
  }

  _updateAdmin() {
    let { data } = this.state;

    if (!this._isFormValid()) {
      return;
    }

    this.setState({ isLoading: true });

    Backend.updateAdmin(data)
      .then((data) => {
        this.setState({ data, isLoading: false });
        Notify.success("User has been updated successfully.");
        this.props.onUpdated();
       
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  render() {
    let { show, data, isLoading } = this.state;

    if (!data) {
      return null;
    }

    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide();
          }
        }}
      >
        <div className="modal-content">
          <Modal.Header closeButton>
            <Modal.Title>Edit User</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <form>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="User Name"
                  name="first_name"
                  value={data?.user?.first_name}
                  onChange={(e) => this._handleChange(e)}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="User Last Name"
                  name="last_name"
                  value={data?.user?.last_name}
                  onChange={(e) => this._handleChange(e)}
                />
              </div>

              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="User Email"
                  name="email"
                  value={data?.user?.email}
                  onChange={(e) => this._handleChange(e)}
                />
              </div>
            </form>
          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              className="btn btn-outline-primary font-weight-bold"
              onClick={() => this.props.onHide()}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary font-weight-bold"
              disabled={isLoading}
              onClick={(e) => {
                e.preventDefault();
                this._updateAdmin();
              }}
            >
              <span className="svg-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    strokeWidth={1}
                    fill="none"
                    fillRule="evenodd"
                  >
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <path
                      d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z"
                      fill="#000000"
                      fillRule="nonzero"
                      transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) "
                    />
                  </g>
                </svg>
              </span>{" "}
              Save User Changes
            </button>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }
}

import React from "react";

import moment from "moment";

import General from "../../utils/General";
import Backend from "../../utils/Backend";
import Notify from "../../utils/Notify";

export default class Aside extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tabs: props.tabs,
      activeTabIndex: props.activeTabIndex,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _renderTabs(tabs, activeTabIndex) {
    return tabs.map((tab, index) => {
      let tabState = index === activeTabIndex ? "current" : "pending";

      return (
        <>
          <div className="wizard-steps">
            <div
              className="wizard-step"
              data-wizard-type="step"
              data-wizard-state={tabState}
              onClick={() => this.props.onClick(tab, index)}
            >
              <div className="wizard-wrapper">
                <div className="wizard-icon">
                  <span
                    className={
                      tab.largeIcon
                        ? "svg-icon svg-icon-4x"
                        : "svg-icon svg-icon-2x"
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      {tab.icon}
                    </svg>
                  </span>
                </div>
                <div className="wizard-label">
                  <h3 className="wizard-title">{tab.title}</h3>
                  <div className="wizard-desc">{tab.description}</div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    });
  }

  render() {
    const { tabs, activeTabIndex } = this.state;

    return <> {this._renderTabs(tabs, activeTabIndex)}</>;
  }
}

import React, { Component } from "react";

import CSVFileModal from "../../modals/CSVFileModal";

export default class ImportCSVFile extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { showCSVFileModal } = this.state;

    return (
      <>
        <div className="wizard-body py-8 px-10 py-lg-20 px-lg-10">
          <div className="row">
            <div className="col-xxl-12">
              <div className="pb-5" data-wizard-type="step-content">
                <div className="card card-custom" style={{ boxShadow: "none" }}>
                  <p>
                    Download the following{" "}
                    <a href="/template.csv" target="_blank">
                      template CSV
                    </a>
                    .
                  </p>
                  <div
                    className="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <a
                      href="javascript:;"
                      onClick={() =>
                        this.setState({
                          showCSVFileModal: true,
                        })
                      }
                    >
                      Import a CSV file to quickly populate your library
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <CSVFileModal
          show={showCSVFileModal}
          onHide={() =>
            this.setState({
              showCSVFileModal: false,
            })
          }
        />
      </>
    );
  }
}

import React, { Component } from "react";

import AuthManager from "../../../../utils/AuthManager";
import Backend from "../../../../utils/Backend";
import Notify from "../../../../utils/Notify";
import Event from "../../../../utils/Event";
import General from "../../../../utils/General";

import CustomButton from "../../CustomButton";

export default class AccountSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      admin: General.clone(AuthManager.currentUser),
    };
  }

  _handleChange(e) {
    let { admin } = this.state;

    const key = e.target.name;
    const value = e.target.value;
    admin.user[key] = value;

    this.setState({ admin });
  }

  _isPasswordFormValid() {
    const { admin } = this.state;

    let { current_password, password, confirm_password } = admin.user;

    let isValid = true;
    let error = null;

    if (
      !current_password ||
      current_password === "" ||
      current_password.length < 6
    ) {
      isValid = false;
      error =
        "Please enter your current password. Password must be at least 6 characters.";
    } else if (!password || password === "" || password.length < 6) {
      isValid = false;
      error =
        "Please enter a valid new password. Password must be at least 6 characters.";
    } else if (password !== confirm_password) {
      isValid = false;
      error = "Passwords do not match";
    }

    if (error) {
      Notify.error(error);
    }

    return isValid;
  }

  _updateAccount() {
    let { admin } = this.state;

    let message = "Your password details have been updated successfully.";

    if (!this._isPasswordFormValid()) {
      return;
    }

    admin.user = {
      id: admin.user.id,
      password: admin.user.password,
      current_password: admin.user.current_password,
    };

    this.setState({ isLoading: true });

    Backend.updateAccount(admin)
      .then((data) => {
        this.setState({
          data,
          isLoading: false,
        });
        AuthManager.currentUser = data;
        Event.emit("USER_UPDATED");
        Notify.success(message);
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    const { admin, isLoading } = this.state;

    return (
      <div className="wizard-body py-8 px-8 py-lg-20 px-lg-10 ml-20 mt-2">
        <div className="row">
          <div className="offset-xxl-2 col-xxl-12">
            <form
              className="form fv-plugins-bootstrap fv-plugins-framework"
              id="kt_form"
            >
              <div className="pb-5" data-wizard-type="step-content">
                <h4 className="mb-10 font-weight-bold text-dark">
                  Control Your Login Details
                </h4>
                <div className="form-group row">
                  <label className="col-4 col-form-label">Email Address</label>
                  <div className="col-8 mb-4">
                    <input
                      className="form-control"
                      type="email"
                      name="email"
                      value={admin?.user?.email}
                      disabled={admin?.user?.email}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-4 col-form-label">
                    Current Password
                  </label>
                  <div className="col-8 mb-4">
                    <input
                      className="form-control"
                      type="password"
                      name="current_password"
                      onChange={(e) => {
                        this._handleChange(e);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-4 col-form-label">New Password</label>
                  <div className="col-8 mb-4">
                    <input
                      className="form-control"
                      type="password"
                      name="password"
                      onChange={(e) => {
                        this._handleChange(e);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-4 col-form-label">
                    Confirm New Password
                  </label>
                  <div className="col-8 mb-4">
                    <input
                      className="form-control"
                      type="password"
                      name="confirm_password"
                      onChange={(e) => {
                        this._handleChange(e);
                      }}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-between border-top mt-5 pt-10">
                  <div style={{ margin: "0 auto" }}>
                    <CustomButton
                      buttonClassName="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                      title="Save Changes"
                      disabled={isLoading}
                      onClick={(e) => {
                        e.preventDefault();
                        this._updateAccount();
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

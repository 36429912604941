import React from "react";

import { withRouter } from "react-router-dom";

import BaseTable from "../BaseTable";

import Backend from "../../../../utils/Backend";
import General from "../../../../utils/General";
import Notify from "../../../../utils/Notify";

import AddItemModal from "../../modals/AddItemModal";
import EditItemModal from "../../modals/EditItemModal";

class ProductLibrarySectionTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryId: props.categoryId,
    };
    this.table = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _removeItem(itemId) {
    this.setState({ isLoading: true });

    Backend.removeItem(itemId)
      .then((item) => {
        this.setState({ item: null, isLoading: false });
        Notify.success("Item has been removed successfully.");
        this.table.current.refresh();
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          item: null,
          isLoading: false,
        });
      });
  }

  _getColumns() {
    return [
      {
        Header: () => <strong className="header__clr"></strong>,
        id: "title",
        Cell: (rowInfo) => {
          let item = rowInfo.original;

          let itemTitle = item.title;
          let itemDescription = item.description;
          let itemImage = item.image.original;

          if (!itemTitle) {
            return "-";
          }

          return (
            <>
              <div className="d-flex align-items-center overflow-hidden">
                <div className="symbol symbol-40 flex-shrink-0">
                  {itemImage ? (
                    <div
                      className="symbol-label"
                      style={{
                        backgroundImage: `url(${itemImage})`,
                      }}
                    />
                  ) : (
                    <div
                      className="symbol-label"
                      style={{
                        backgroundColor: "whitesmoke",
                        width: 40,
                        height: 40,
                        borderRadius: "10%",
                      }}
                    />
                  )}
                </div>
                <div className="ml-2">
                  <div className="text-dark-75 font-weight-bold line-height-sm">
                    {General.toTitleCase(itemTitle)}
                  </div>
                  {itemDescription && (
                    <a
                      href="javascript:;"
                      className="font-size-sm text-dark-50 text-hover-primary"
                    >
                      {General.toTitleCase(itemDescription)}
                    </a>
                  )}
                </div>
              </div>
            </>
          );
        },
      },

      {
        Header: () => <strong className="header__clr"></strong>,
        id: "id",
        sortable: false,
        Cell: (rowInfo) => {
          let item = rowInfo.original;

          let selectedItem = item;

          let { isLoading } = this.state;

          return (
            <div className="row align-items-right">
              <div className="col-md-12 my-2 my-md-0">
                <button
                  type="button"
                  className="btn btn-sm btn-rounded btn-outline-primary mr-2 w-xs-100"
                  onClick={() =>
                    this.setState({
                      selectedItem,
                      showEditItemModal: true,
                    })
                  }
                >
                  Edit
                </button>

                <button
                  type="button"
                  className="btn btn-sm btn-rounded btn-outline-primary mr-2 w-xs-100"
                  disabled={isLoading}
                  onClick={() => {
                    this._removeItem(selectedItem?.id);
                  }}
                >
                  Remove
                </button>
              </div>
            </div>
          );
        },
      },
    ];
  }

  render() {
    let {
      showAddItemModal,
      showEditItemModal,
      selectedItem,
      isLoading,
      categoryId,
    } = this.state;

    const columns = this._getColumns();

    let tableProps = {};

    return (
      <>
        <BaseTable
          {...tableProps}
          ref={this.table}
          title={this.props.title}
          loading={isLoading}
          endpoint={`${window.Api.Items}?category_id=${categoryId}`}
          noDataMessage={"No items found"}
          columns={columns}
          showPagination={true}
          showSearch={false}
          defaultSorted={[
            {
              id: "id",
              desc: true,
            },
          ]}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "Actions" ? "visible" : "hidden",
              },
            };
          }}
          renderToolbar={(rowInfo) => {
            return (
              <>
                <div className="kt-portlet__head-toolbar">
                  <div className="kt-portlet__head-wrapper">
                    <div className="row align-items-right">
                      <div className="col-md-12 my-2 my-md-0">
                        <div className="dropdown dropdown-inline mr-2">
                          <button
                            type="button"
                            className="btn btn-outline-primary font-weight-bolder w-100"
                            onClick={() =>
                              this.setState({
                                showAddItemModal: true,
                              })
                            }
                          >
                            <i className="flaticon2-plus"></i>
                            Add Item
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          }}
        />
        <AddItemModal
          show={showAddItemModal}
          categoryId={this.props.categoryId}
          onUpdated={(selectedItem) => {
            this.table.current.refresh();

            this.setState({
              showAddItemModal: false,
              selectedItem: null,
            });
          }}
          onHide={() =>
            this.setState({
              showAddItemModal: false,
              selectedItem: null,
            })
          }
        />

        <EditItemModal
          show={showEditItemModal}
          categoryId={this.props.categoryId}
          data={selectedItem}
          onUpdated={(item) => {
            this.table.current.refresh();

            this.setState({
              showEditItemModal: false,
              selectedItem: null,
            });
          }}
          onHide={() =>
            this.setState({ showEditItemModal: false, selectedItem: null })
          }
        />
      </>
    );
  }
}

ProductLibrarySectionTable.defaultProps = {
  title: "Menu Items",
  showPagination: true,
  showPaginationTop: true,
  showPaginationBottom: true,
};

export default withRouter(ProductLibrarySectionTable);

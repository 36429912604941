import React, { Component } from "react";

import { withRouter } from "react-router-dom";

import Backend from "../../../../utils/Backend";
import Notify from "../../../../utils/Notify";

import CustomButton from "../../CustomButton";

import ImageUpload from "../../common/ImageUpload";

class AddCompanyBranding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        logo: null,
        featured_image: null,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  componentDidMount() {
    this._getVendorDetails();
  }

  _getVendorDetails() {
    let vendorId = this.props?.vendorInfo?.company?.id;

    this.setState({ isLoading: true });

    Backend.getVendorDetails(vendorId)
      .then((data) => {
        this.setState({ data, isLoading: false });
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  _isFormValid() {
    const { data } = this.state;

    const { logo, featured_image } = data;

    let error = null;

    if (!logo) {
      error = "Please upload a logo.";
    } else if (!featured_image) {
      error = "Please upload featured image.";
    }

    if (error) {
      Notify.error(error);
      return false;
    }

    return true;
  }

  _updateCompanyDetails() {
    let { data } = this.state;

    if (!this._isFormValid()) {
      return;
    }

    this.setState({ isLoading: true });

    if (data.id) {
      Backend.updateCompanyDetails(data)
        .then((company) => {
          this.setState({
            data,
            isLoading: false,
          });
          this.props.onNextClick(company);
        })
        .catch((error) => {
          Notify.error(error.message);
          this.setState({
            isLoading: false,
          });
        });
    }
  }

  render() {
    let { data, isLoading } = this.state;

    return (
      <>
        <div className="wizard-body py-8 px-8 py-lg-20 px-lg-10 mr-30">
          <div className="row">
            <div className="offset-xxl-2 col-xxl-8">
              <h6 className="mb-10 font-weight-bold">
                Show your customers what your company is about. Add your logo
                and some imagery that describes your brand.
              </h6>
              <h4 className="mb-4 font-weight-bold text-dark">
                Company Branding
              </h4>

              <div className="form-group">
                <label className="mb-3">Company Logo</label>
                <br />

                <ImageUpload
                  image={data?.logo}
                  hidePreview={false}
                  cropImage={true}
                  aspectRatio={1}
                  onUpdated={(logo) => {
                    data.logo = logo;

                    this.setState({ data });
                  }}
                />
              </div>

              <div className="form-group">
                <label>Featured Images</label>{" "}
                <ImageUpload
                  image={data?.featured_image}
                  hidePreview={false}
                  cropImage={true}
                  aspectRatio={1}
                  onUpdated={(featured_image) => {
                    data.featured_image = featured_image;

                    this.setState({ data });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between border-top mt-5 mb-8 pt-10">
            <div className="mr-2"></div>
            <div>
              <CustomButton
                buttonClassName="btn btn-primary font-weight-bolder px-9 py-4"
                dataWizardType="action-next"
                title="Continue"
                disabled={isLoading}
                onClick={(e) => {
                  e.preventDefault();
                  this._updateCompanyDetails();
                }}
              />
            </div>
          </div>
        </div>

      </>
    );
  }
}

export default withRouter(AddCompanyBranding);

import React, { Component } from "react";

import Backend from "../../../../utils/Backend";
import Notify from "../../../../utils/Notify";

import UsersCard from "./UsersCard";

import AddUserModal from "../../modals/AddUserModal";
import AuthManager from "../../../../utils/AuthManager";

export default class UsersSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    this._getAdmins();
  }

  _getAdmins() {
    this.setState({ isLoading: true });

    Backend.getAdmins()
      .then((data) => {
        this.setState({ data, isLoading: false });
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { data, showAddUserModal, isLoading } = this.state;

    let adminUser = AuthManager.currentUser;

    return (
      <>
        <div className="wizard-body py-8 px-8 py-lg-20 px-lg-10  mt-2">
          <div className="row">
            <div className="offset-xxl-2 col-xxl-12">
              <form
                className="form fv-plugins-bootstrap fv-plugins-framework"
                id="kt_form"
              >
                <div className="pb-5" data-wizard-type="step-content">
                  <h4 className="mb-10 font-weight-bold text-dark">
                    Users{" "}
                    {adminUser.role === "admin" && (
                      <a
                        href="javascript:;"
                        className="btn btn-icon btn-light text-right btn-sm ml-5"
                        onClick={() =>
                          this.setState({
                            showAddUserModal: true,
                          })
                        }
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth={1}
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect
                                fill="#000000"
                                x={4}
                                y={11}
                                width={16}
                                height={2}
                                rx={1}
                              />
                              <rect
                                fill="#000000"
                                opacity="0.3"
                                transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
                                x={4}
                                y={11}
                                width={16}
                                height={2}
                                rx={1}
                              />
                            </g>
                          </svg>
                        </span>
                      </a>
                    )}
                  </h4>
                  <div className="table-responsive">
                    <table className="table table-borderless table-vertical-center">
                      <thead>
                        <tr>
                          <th className="p-0" style={{ width: 50 }} />
                          <th className="p-0" style={{ minWidth: 150 }} />
                          <th className="p-0" style={{ minWidth: 70 }} />
                        </tr>
                      </thead>
                      <tbody>
                        {data?.map((admin) => {
                          return (
                            <UsersCard
                              key={admin?.user.id}
                              data={admin}
                              onEdited={() => this._getAdmins()}
                            />
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <AddUserModal
          show={showAddUserModal}
          onUpdated={() => {
            this._getAdmins();

            this.setState({
              showAddUserModal: false,
            });
          }}
          onHide={() =>
            this.setState({
              showAddUserModal: false,
            })
          }
        />
      </>
    );
  }
}

import React, { Component } from "react";

import Modal from "react-bootstrap/Modal";

import Backend from "../../../utils/Backend";
import General from "../../../utils/General";
import Notify from "../../../utils/Notify";

import LocationInput from "../../components/common/LocationInput";

import PhoneInput from "../PhoneInput";

export default class StoreDetailsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this._getState(props),
      show: props.show,
      isLoading: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps));
  }

  _getState(props) {
    return {
      ...props,
      data: General.clone(props.data),
    };
  }

  _handleChange(e) {
    const { data } = this.state;

    let value = e.target.value;

    data[e.target.name] = value;

    this.setState({ data });
  }

  _isFormValid() {
    const { data } = this.state;

    const { title, phone_number } = data;

    let error = null;

    if (!title) {
      error = "Please enter a valid venue name.";
    } else if (!phone_number) {
      error = "Please enter a valid phone number.";
    }

    if (error) {
      Notify.error(error);
      return false;
    }

    return true;
  }

  _updateVenue() {
    let { data } = this.state;

    if (!this._isFormValid()) {
      return;
    }

    data = {
      id: data.id,
      title: data?.title,
      address: data?.address,
      phone_number: data?.phone_number,
      phone_country_code: data?.phone_country_code,
    };

    this.setState({ isLoading: true });

    Backend.updateVenue(data)
      .then((data) => {
        this.setState({ data, isLoading: false });
        Notify.success("Venue has been updated successfully.");
        this.props.onUpdated();
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  render() {
    let { show, data, isLoading } = this.state;

    if (!data) {
      return null;
    }

    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide();
          }
        }}
      >
        <div className="modal-content">
          <Modal.Header closeButton>
            <Modal.Title>Edit</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <form>
              <div className="form-group">
                <label>
                  Venue Name
                  <span className="text-danger">*</span>
                </label>
                <div className="row">
                  <div className="col-lg-12">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      name="title"
                      value={data?.title}
                      onChange={(e) => this._handleChange(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Venue Address</label>
                <span className="text-danger">*</span>
                <LocationInput
                  className="form-control"
                  location={data?.address}
                  onUpdated={(address) => {
                    data.address = address;
                    this.setState({ data });
                  }}
                />
              </div>
              <PhoneInput
                spanClassName="text-danger"
                value={data.phone_number}
                onChange={(e) => this._handleChange(e)}
              />
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-outline-primary font-weight-bold"
              onClick={() => this.props.onHide()}
            >
              Close
            </button>

            <button
              type="button"
              className="btn btn-primary font-weight-bold"
              disabled={isLoading}
              onClick={(e) => {
                e.preventDefault();
                this._updateVenue();
              }}
            >
              Save Changes
            </button>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }
}

import React, { Component } from "react";

import moment from "moment";

export class Footer extends Component {
  render() {
    return (
      <div
        className="footer bg-white py-4 d-flex flex-lg-column"
        id="kt_footer"
      >
        <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
          <div className="text-dark order-2 order-md-1">
            <span className="text-muted font-weight-bold mr-2">
              © {moment().format("YYYY")}
            </span>
            <a
              href="#"
              target="_blank"
              className="text-dark-75 text-hover-primary"
            >
              Clinks
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;

import React, { Component } from "react";

import AddStoreSettings from "./AddStoreSettings";

export default class AddStore extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        <div className="container">
          <div className="card card-custom">
            <div className="card-header">
              <div className="card-title">
                <span className="card-icon">
                  <span className="svg-icon svg-icon-primary svg-icon-2x">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect
                          fill="#000000"
                          x={4}
                          y={11}
                          width={16}
                          height={2}
                          rx={1}
                        />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
                          x={4}
                          y={11}
                          width={16}
                          height={2}
                          rx={1}
                        />
                      </g>
                    </svg>
                  </span>
                </span>
                <h3 className="card-label">Add A Store</h3>
              </div>
            </div>
            <AddStoreSettings vendorId={this.props.match.params.vendorId} />
          </div>
        </div>
      </>
    );
  }
}

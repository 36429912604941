import React, { Component } from "react";

import { Route, Switch, withRouter } from "react-router-dom";

import Aside from "./components/Aside";

import EditCompanyBranding from "./components/settings/edit-branding/EditCompanyBranding";

const TAB_COMPANY_BRANDING_SETTINGS = {
  title: "Manage Company Branding",
  icon: (
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <polygon points="0 0 24 0 24 24 0 24" />
      <path
        d="M9,1 C4.92361111,7.82926829 4.92361111,12.8292683 9,16 C12.9097222,12.8292683 12.9097222,7.82926829 9,1 Z"
        fill="#000000"
        opacity="0.3"
        transform="translate(8.937500, 8.500000) scale(-1, 1) rotate(-330.000000) translate(-8.937500, -8.500000) "
      />
      <path
        d="M15,1 C10.9236111,7.82926829 10.9236111,12.8292683 15,16 C18.9097222,12.8292683 18.9097222,7.82926829 15,1 Z"
        fill="#000000"
        opacity="0.3"
        transform="translate(14.937500, 8.500000) rotate(-330.000000) translate(-14.937500, -8.500000) "
      />
      <path
        d="M12,1 C7.92361111,7.82926829 7.92361111,12.8292683 12,16 C15.9097222,12.8292683 15.9097222,7.82926829 12,1 Z"
        fill="#000000"
        opacity="0.3"
      />
      <path
        d="M6.34403065,13 L17.6559693,13 C18.2082541,13 18.6559693,13.4477153 18.6559693,14 C18.6559693,14.0973246 18.6417616,14.1941279 18.6137956,14.2873479 L16.4275913,21.5746958 C16.1738009,22.4206637 15.3951551,23 14.5119387,23 L9.4880613,23 C8.60484486,23 7.82619911,22.4206637 7.57240873,21.5746958 L5.38620437,14.2873479 C5.22750651,13.758355 5.52768992,13.2008716 6.05668277,13.0421737 C6.14990279,13.0142077 6.24670609,13 6.34403065,13 Z"
        fill="#000000"
      />
    </g>
  ),
  largeIcon: true,
  description: "Make the account professional looking",
  component: EditCompanyBranding,
};

const TABS = [TAB_COMPANY_BRANDING_SETTINGS];

export class EditBranding extends Component {
  constructor(props) {
    super(props);

    let tabs = TABS;
    this.state = {
      tabs,
      activeTabIndex: this._getActiveTabIndex(tabs),
      isLoading: false,
    };
  }

  _getActiveTabIndex(tabs) {
    const { pathname } = this.props.location;
    for (var i = 0; i < tabs.length; i++) {
      let tab = tabs[i];
      if (pathname === tab.url) {
        return i;
      }
    }
    return 0;
  }

  render() {
    const { tabs, activeTabIndex } = this.state;

    let activeTab = tabs[activeTabIndex];
    let TabComponent = activeTab.component;

    return (
      <>
        <div className="container">
          <div className="card card-custom">
            <div className="card-body p-0">
              <div className="row">
                <div
                  className="wizard wizard-2"
                  id="kt_wizard_v2"
                  data-wizard-state="first"
                  data-wizard-clickable="false"
                >
                  <div className="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
                    {" "}
                    <Aside
                      tabs={tabs}
                      activeTabIndex={activeTabIndex}
                      onClick={(tab, index) => {
                        return;
                      }}
                    />
                  </div>
                  <TabComponent vendorId={this.props.match.params.vendorId} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(EditBranding);

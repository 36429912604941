import React, { Component } from "react";

import Modal from "react-bootstrap/Modal";

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";

export default class PasscodeModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      data: props.data,
      passcode: null,
      vendorId: props.vendorId,
    };
  }

  componentDidMount() {
    this._getPasscode()
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _getPasscode() {
    const { vendorId } = this.state;

    this.setState({ isLoading: true });

    Backend.getPasscode(vendorId)
      .then((data) => {
        this.setState({ passcode: data.passcode, isLoading: false });
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { show, isLoading, passcode } = this.state;

    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide();
          }
        }}
        dialogClassName={"modal-dialog-centered modal-md"}
        className={"sub-modal"}
      >
        <Modal.Header closeButton>
          <Modal.Title>Passcode</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="py-1 stripe-modal-message">
            <div className="form-group">
              <h3 className="text-primary">{passcode}</h3>
            </div>
          </div>

          <div className="text-right">
            <button
              type="button"
              className="btn btn-outline-primary font-weight-bold"
              onClick={() => this.props.onHide()}
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

PasscodeModal.defaultProps = {
  title: "",
};

import React, { Component } from "react";

import Modal from "react-bootstrap/Modal";

import Backend from "../../../utils/Backend";
import General from "../../../utils/General";
import Notify from "../../../utils/Notify";
import PhoneInput from "../PhoneInput";

export default class DriverDetailsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this._getState(props),
      show: props.show,
      isLoading: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps));
  }

  _getState(props) {
    return {
      ...props,
      data: General.clone(props.data),
    };
  }

  _handleUserChange(e) {
    const { data } = this.state;

    let value = e.target.value;

    data.user[e.target.name] = value;

    this.setState({ data });
  }

  _isFormValid() {
    const { data } = this.state;

    let error = null;

    if (!data.user.first_name) {
      error = "Please enter a valid first name.";
    } else if (!data.user.last_name) {
      error = "Please enter a valid last name.";
    } else if (!data.user.email) {
      error = "Please enter a valid email.";
    } else if (!data.user.phone_number) {
      error = "Please enter a valid phone number.";
    }

    if (error) {
      Notify.error(error);
      return false;
    }

    return true;
  }

  _updateDriver() {
    let { data } = this.state;

    if (!this._isFormValid()) {
      return;
    }

    data.user = {
      id: data.user.id,
      first_name: data.user.first_name,
      last_name: data.user.last_name,
      email: data.user.email,
      phone_number: data.user.phone_number,
      phone_country_code: "+353",
    };

    this.setState({ isLoading: true });

    Backend.updateDriverDetails(data)
      .then((data) => {
        this.setState({ data, isLoading: false });
        Notify.success("Driver has been updated successfully.");
        this.props.onUpdated();
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { show, data, isLoading } = this.state;

    if (!data) {
      return null;
    }

    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide();
          }
        }}
      >
        <div className="modal-content">
          <Modal.Header closeButton>
            <Modal.Title>Edit</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="form-group">
              <label>
                Full Name
                <span className="text-danger">*</span>
              </label>
              <div className="row">
                <div className="col-lg-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    name="first_name"
                    value={data?.user?.first_name}
                    onChange={(e) => this._handleUserChange(e)}
                  />
                </div>
                <div className="col-lg-6">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    name="last_name"
                    value={data?.user?.last_name}
                    onChange={(e) => this._handleUserChange(e)}
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <label>
                Email Address
                <span className="text-danger">*</span>
              </label>
              <input
                type="email"
                className="form-control"
                placeholder="Email Address"
                name="email"
                value={data?.user?.email}
                onChange={(e) => this._handleUserChange(e)}
              />
            </div>
            <PhoneInput
              spanClassName="text-danger"
              value={data?.user?.phone_number}
              onChange={(e) => this._handleUserChange(e)}
            />
          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              className="btn btn-outline-primary font-weight-bold"
              onClick={() => this.props.onHide()}
            >
              Close
            </button>

            <button
              type="button"
              className="btn btn-primary font-weight-bold"
              disabled={isLoading}
              onClick={() => this._updateDriver()}
            >
              Save Changes
            </button>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }
}

import React, { Component } from "react";

import Backend from "../utils/Backend";
import Notify from "../utils/Notify";

import ImageUpload from "./components/common/ImageUpload";

import PhoneInput from "./components/PhoneInput";

import Select from "./components/common/Select";

const VEHICLE_TYPE_OPTIONS = [
  {
    label: "Car",
    value: "car",
  },
  {
    label: "Bicycle",
    value: "bicycle",
  },
  {
    label: "Scooter",
    value: "scooter",
  },
];

export default class AddDriver extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        confirm_password: "",
        phone_country_code: "+353",
        phone_number: "",
        ppsn: "",
        vehicle_type: "",
        vehicle_registration_no: null,
        identification: {},
      },
    };
  }

  _handleChange(e) {
    let { data } = this.state;

    data[e.target.name] = e.target.value;

    this.setState({
      data,
    });
  }

  _isFormValid() {
    const { data } = this.state;

    const {
      first_name,
      last_name,
      email,
      password,
      confirm_password,
      phone_number,
      ppsn,
      vehicle_type,
      identification,
    } = data;

    let error = null;

    if (!first_name) {
      error = "Please enter a valid first name.";
    } else if (!last_name) {
      error = "Please enter a valid last name.";
    } else if (!email) {
      error = "Please enter a valid email.";
    } else if (!phone_number) {
      error = "Please enter a valid phone number.";
    } else if (!ppsn) {
      error = "Please enter a valid ppsn.";
    } else if (!vehicle_type) {
      error = "Please select one of the options: car or bicycle or scooter.";
    } else if (
      vehicle_type != "bicycle" &&
      (!identification.back || !identification.front)
    ) {
      error = "Please upload front and back image of drivers licence.";
    } else if (!password) {
      error = "Please enter a valid password.";
    } else if (password !== confirm_password) {
      error = "Passwords do not match.";
    }

    if (error) {
      Notify.error(error);
      return false;
    }

    return true;
  }

  _addDriver() {
    let { data } = this.state;

    let {
      first_name,
      last_name,
      email,
      password,
      phone_number,
      phone_country_code,
      ppsn,
      vehicle_type,
      vehicle_registration_no,
      identification,
    } = data;

    if (!this._isFormValid()) {
      return;
    }

    if (Object.keys(data.identification).length != 0) {
      data.identification.type = "driver_license";
      data.identification.front = data.identification.front.id;
      data.identification.back = data.identification.back.id;
    }

    data = {
      user: {
        first_name,
        last_name,
        email,
        password,
        phone_country_code,
        phone_number,
      },
      ppsn,
      vehicle_type,
      vehicle_registration_no: vehicle_registration_no ?? null,
      identification:
        Object.keys(data.identification).length != 0 ? identification : null,
    };

    this.setState({ isLoading: true });

    Backend.addDriver(data)
      .then(() => {
        this.setState({ data, isLoading: false });
        Notify.success("Driver has been added successfully.");
        this.props.history.push("/manage-drivers");
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { data, isLoading } = this.state;

    return (
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="card card-custom">
              <div className="card-header flex-wrap border-0 pt-6 pb-0">
                <div className="card-title">
                  <h3 className="card-label">Driver Login Details</h3>
                </div>
              </div>
              <form>
                <div className="card-body">
                  <div className="form-group">
                    <label>
                      Full Name
                      <span className="text-danger">*</span>
                    </label>
                    <div className="row">
                      <div className="col-lg-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          name="first_name"
                          value={data.first_name}
                          onChange={(e) => this._handleChange(e)}
                        />
                      </div>
                      <div className="col-lg-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Last Name"
                          name="last_name"
                          value={data.last_name}
                          onChange={(e) => this._handleChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>
                      Email Address
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email Address"
                      name="email"
                      value={data.email}
                      onChange={(e) => this._handleChange(e)}
                    />
                  </div>
                  <PhoneInput
                    spanClassName="text-danger"
                    value={data.phone_number}
                    onChange={(e) => this._handleChange(e)}
                  />
                  <div className="form-group">
                    <label>
                      PPSN
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="ppsn"
                      value={data.ppsn}
                      onChange={(e) => this._handleChange(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Vehicle Type</label>
                    <Select
                      value={VEHICLE_TYPE_OPTIONS.find(
                        (option) => option.value === data.vehicle_type
                      )}
                      className="async-select-paginate"
                      classNamePrefix="async-select-paginate"
                      onChange={(option) => {
                        data.vehicle_type = option.value;
                        this.setState({ data });
                      }}
                      options={VEHICLE_TYPE_OPTIONS}
                    />
                  </div>
                  {data?.vehicle_type.trim() !== "bicycle" && (
                    <>
                      <div id="vehicle-reg" className="form-group">
                        <label>
                          Vehicle Registration
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="vehicle_registration_no"
                          value={data.vehicle_registration_no}
                          onChange={(e) => this._handleChange(e)}
                        />
                      </div>
                      <div id="Drivers Licence" className="form-group">
                        <label>
                          Drivers Licence - Front
                          <span className="text-danger">*</span>
                        </label>
                        <ImageUpload
                          image={data?.identification?.front}
                          hidePreview={false}
                          cropImage={true}
                          aspectRatio={620 / 139}
                          onUpdated={(front) => {
                            data.identification.front = front;

                            this.setState({ data });
                          }}
                        />
                      </div>
                      <div id="Drivers Licence" className="form-group">
                        <label>
                          Drivers Licence - Back
                          <span className="text-danger">*</span>
                        </label>
                        <ImageUpload
                          image={data?.identification?.back}
                          hidePreview={false}
                          cropImage={true}
                          aspectRatio={620 / 139}
                          onUpdated={(back) => {
                            data.identification.back = back;

                            this.setState({ data });
                          }}
                        />
                      </div>{" "}
                    </>
                  )}
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">
                      Password
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      name="password"
                      value={data.password}
                      onChange={(e) => this._handleChange(e)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputConfirmPassword1">
                      Confirm Password
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Confirm Password"
                      name="confirm_password"
                      value={data.confirm_password}
                      onChange={(e) => this._handleChange(e)}
                    />
                  </div>
                </div>
                <div className="card-footer">
                  <button
                    type="submit"
                    className="btn btn-primary mr-2"
                    disabled={isLoading}
                    onClick={(e) => {
                      e.preventDefault();
                      this._addDriver();
                    }}
                  >
                    Add Driver
                  </button>
                  <button
                    type="reset"
                    className="btn btn-secondary"
                    onClick={() => this.props.history.push("/manage-drivers")}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

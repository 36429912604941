import React, { Component } from "react";

import Modal from "react-bootstrap/Modal";

import Backend from "../../../utils/Backend";
import General from "../../../utils/General";
import Notify from "../../../utils/Notify";

import ImageUpload from "../../components/common/ImageUpload";
import AsyncSelect from "../common/AsyncSelect";

export default class EditItemModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this._getState(props),
      show: props.show,
      isLoading: false,
      isLoadingItem: true,
    };
    this._loadItem();
  }

  componentWillReceiveProps(nextProps) {
    let refresh = nextProps.data?.id != this.state.data?.id;

    this.setState(this._getState(nextProps), () => {
      if (refresh) {
        this._loadItem();
      }
    });
  }

  _getState(props) {
    return {
      ...props,
      data: General.clone(props.data),
    };
  }

  _loadItem() {
    let { data } = this.state;

    if (!data) {
      return;
    }

    this.setState({
      isLoadingItem: true,
    });

    Backend.getItem(data?.id)
      .then((item) => {
        this.setState({ data: item, isLoadingItem: false });
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoadingItem: false });
      });
  }

  _handleChange(e) {
    let { data } = this.state;

    let value = e.target.value;

    data[e.target.name] = value;

    this.setState({ data });
  }

  _isFormValid() {
    const { data } = this.state;

    const { title, image, description } = data;

    let error = null;

    if (!title) {
      error = "Please enter a valid title.";
    } else if (!image) {
      error = "Please upload an item image.";
    } else if (!description) {
      error = "Please enter a valid description.";
    }

    if (error) {
      Notify.error(error);
      return false;
    }

    return true;
  }

  _editItem() {
    let { data } = this.state;

    if (!this._isFormValid()) {
      return;
    }

    data.image = data.image.id;
    data.subcategory = data.subcategory ? data.subcategory.id : null;

    this.setState({ isLoading: true });

    Backend.editItem(data)
      .then((data) => {
        this.setState({ isLoading: false });
        Notify.success("Item has been updated successfully.");
        this.props.onUpdated(data);
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  _getSubcategory(subcategory) {
    return {
      label: subcategory?.title,
      value: subcategory?.id,
      data: subcategory,
    };
  }

  render() {
    let { show, data, isLoading, isLoadingItem } = this.state;

    if (!data || isLoadingItem) {
      return null;
    }

    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide();
          }
        }}
      >
        <div className="modal-content">
          <Modal.Header closeButton>
            <Modal.Title>Edit Item</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="form-group row">
              <div data-repeater-list className="col-lg-12">
                <div
                  data-repeater-item
                  className="form-group row align-items-center"
                >
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Item Name *</label>
                      <input
                        type="text"
                        className="form-control form-control-solid form-control-lg"
                        placeholder="Enter Title"
                        name="title"
                        value={data?.title}
                        onChange={(e) => this._handleChange(e)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Item Photo</label>
                      <ImageUpload
                        image={data?.image}
                        hidePreview={false}
                        cropImage={true}
                        aspectRatio={530 / 140}
                        onUpdated={(image) => {
                          data.image = image;
                          this.setState({ data });
                        }}
                        onLoading={(isLoading) => this.setState({ isLoading })}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>SubCategory *</label>
                      <AsyncSelect
                        endpoint={`${window.Api.Categories}`}
                        filter={`has_parent=true&parent_id=${this.props.categoryId}`}
                        orderBy={"title"}
                        search_term={"title"}
                        getOptions={(categories) => {
                          return categories?.map((category) =>
                            this._getSubcategory(category)
                          );
                        }}
                        className="form-control h-auto border-0 form-control-solid c-selectbox"
                        name="subcategory"
                        value={
                          data?.subcategory
                            ? this._getSubcategory(data.subcategory)
                            : null
                        }
                        placeholder="Please Select a SubCategory"
                        onSelected={(subcategory) => {
                          data.subcategory = subcategory;

                          this.setState({ data });
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Item Description</label>
                      <textarea
                        className="form-control form-control-solid form-control-lg"
                        placeholder="Enter Description"
                        name="description"
                        value={data?.description}
                        onChange={(e) => this._handleChange(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              className="btn btn-outline-primary font-weight-bold"
              onClick={() => this.props.onHide()}
            >
              Close
            </button>

            <button
              type="button"
              className="btn btn-primary font-weight-bold"
              disabled={isLoading}
              onClick={() => this._editItem()}
            >
              Save Changes
            </button>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }
}

import React, { Component } from "react";

import { Route, Switch, withRouter } from "react-router-dom";

import Aside from "./components/Aside";

import AddLoginDetails from "./components/settings/add-vendor-settings/AddLoginDetails";
import AddStoreDetails from "./components/settings/add-vendor-settings/AddStoreDetails";
import AddCompanyBranding from "./components/settings/add-vendor-settings/AddCompanyBranding";
import AddMenu from "./components/settings/add-vendor-settings/AddMenu";

const TAB_LOGIN_DETAILS_SETTINGS = {
  title: "Add Login Details",
  icon: (
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <rect x={0} y={0} width={24} height={24} />
      <polygon fill="#000000" opacity="0.3" points="5 15 3 21.5 9.5 19.5" />
      <path
        d="M16,10 L16,9.5 C16,8.11928813 14.8807119,7 13.5,7 C12.1192881,7 11,8.11928813 11,9.5 L11,10 C10.4477153,10 10,10.4477153 10,11 L10,14 C10,14.5522847 10.4477153,15 11,15 L16,15 C16.5522847,15 17,14.5522847 17,14 L17,11 C17,10.4477153 16.5522847,10 16,10 Z M13.5,21 C8.25329488,21 4,16.7467051 4,11.5 C4,6.25329488 8.25329488,2 13.5,2 C18.7467051,2 23,6.25329488 23,11.5 C23,16.7467051 18.7467051,21 13.5,21 Z M13.5,8 L13.5,8 C14.3284271,8 15,8.67157288 15,9.5 L15,10 L12,10 L12,9.5 C12,8.67157288 12.6715729,8 13.5,8 Z"
        fill="#000000"
      />
    </g>
  ),
  largeIcon: true,
  description: "Add the vendors login details",
  component: AddLoginDetails,
};

const TAB_STORE_DETAILS_SETTINGS = {
  title: "Add Store Details",
  icon: (
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <rect x={0} y={0} width={24} height={24} />
      <path
        d="M5.84026576,8 L18.1597342,8 C19.1999115,8 20.0664437,8.79732479 20.1528258,9.83390904 L20.8194924,17.833909 C20.9112219,18.9346631 20.0932459,19.901362 18.9924919,19.9930915 C18.9372479,19.9976952 18.8818364,20 18.8264009,20 L5.1735991,20 C4.0690296,20 3.1735991,19.1045695 3.1735991,18 C3.1735991,17.9445645 3.17590391,17.889153 3.18050758,17.833909 L3.84717425,9.83390904 C3.93355627,8.79732479 4.80008849,8 5.84026576,8 Z M10.5,10 C10.2238576,10 10,10.2238576 10,10.5 L10,11.5 C10,11.7761424 10.2238576,12 10.5,12 L13.5,12 C13.7761424,12 14,11.7761424 14,11.5 L14,10.5 C14,10.2238576 13.7761424,10 13.5,10 L10.5,10 Z"
        fill="#000000"
      />
      <path
        d="M10,8 L8,8 L8,7 C8,5.34314575 9.34314575,4 11,4 L13,4 C14.6568542,4 16,5.34314575 16,7 L16,8 L14,8 L14,7 C14,6.44771525 13.5522847,6 13,6 L11,6 C10.4477153,6 10,6.44771525 10,7 L10,8 Z"
        fill="#000000"
        fillRule="nonzero"
        opacity="0.3"
      />
    </g>
  ),
  largeIcon: true,
  description: "The first store to kick things off",
  component: AddStoreDetails,
};

const TAB_COMPANY_BRANDING_SETTINGS = {
  title: "Add Company Branding",
  icon: (
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <polygon points="0 0 24 0 24 24 0 24" />
      <path
        d="M9,1 C4.92361111,7.82926829 4.92361111,12.8292683 9,16 C12.9097222,12.8292683 12.9097222,7.82926829 9,1 Z"
        fill="#000000"
        opacity="0.3"
        transform="translate(8.937500, 8.500000) scale(-1, 1) rotate(-330.000000) translate(-8.937500, -8.500000) "
      />
      <path
        d="M15,1 C10.9236111,7.82926829 10.9236111,12.8292683 15,16 C18.9097222,12.8292683 18.9097222,7.82926829 15,1 Z"
        fill="#000000"
        opacity="0.3"
        transform="translate(14.937500, 8.500000) rotate(-330.000000) translate(-14.937500, -8.500000) "
      />
      <path
        d="M12,1 C7.92361111,7.82926829 7.92361111,12.8292683 12,16 C15.9097222,12.8292683 15.9097222,7.82926829 12,1 Z"
        fill="#000000"
        opacity="0.3"
      />
      <path
        d="M6.34403065,13 L17.6559693,13 C18.2082541,13 18.6559693,13.4477153 18.6559693,14 C18.6559693,14.0973246 18.6417616,14.1941279 18.6137956,14.2873479 L16.4275913,21.5746958 C16.1738009,22.4206637 15.3951551,23 14.5119387,23 L9.4880613,23 C8.60484486,23 7.82619911,22.4206637 7.57240873,21.5746958 L5.38620437,14.2873479 C5.22750651,13.758355 5.52768992,13.2008716 6.05668277,13.0421737 C6.14990279,13.0142077 6.24670609,13 6.34403065,13 Z"
        fill="#000000"
      />
    </g>
  ),
  largeIcon: true,
  description: "Make the account professional looking",
  component: AddCompanyBranding,
};

const TAB_ADD_MENU_SETTINGS = {
  title: "Add A Menu",
  icon: (
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <rect x={0} y={0} width={24} height={24} />
      <path
        d="M13,19.4 L16.6856953,20.8742781 C16.8755245,20.9502098 17,21.1340642 17,21.3385165 L17,21.5 C17,21.7761424 16.7761424,22 16.5,22 L7.5,22 C7.22385763,22 7,21.7761424 7,21.5 L7,21.3385165 C7,21.1340642 7.12447547,20.9502098 7.31430466,20.8742781 L11,19.4 L11,13.5 L13,13.5 L13,19.4 Z"
        fill="#000000"
        opacity="0.3"
      />
      <path
        d="M13.2493901,13.9609566 C12.3868662,14.6509757 11.1282815,14.5111329 10.4382624,13.648609 L3.86900921,5.43704257 C3.65623355,5.171073 3.54031242,4.84060724 3.54031242,4.5 C3.54031242,3.67157288 4.2118853,3 5.04031242,3 L18.9596876,3 C19.3002948,3 19.6307606,3.11592113 19.8967301,3.32869679 C20.5436231,3.84621111 20.6485051,4.79014967 20.1309908,5.43704257 L13.5617376,13.648609 C13.4694741,13.7639384 13.3647195,13.868693 13.2493901,13.9609566 Z M6.08062485,5 L8.5,8.01229084 L15.5,8.01229084 L17.9193752,5 L6.08062485,5 Z"
        fill="#000000"
        fillRule="nonzero"
      />
    </g>
  ),
  largeIcon: true,
  description: "Users need a menu to buy from",
  component: AddMenu,
};

const TABS = [
  TAB_LOGIN_DETAILS_SETTINGS,
  TAB_STORE_DETAILS_SETTINGS,
  TAB_COMPANY_BRANDING_SETTINGS,
  TAB_ADD_MENU_SETTINGS,
];

export class AddVendorSettings extends Component {
  constructor(props) {
    super(props);

    let tabs = TABS;
    this.state = {
      tabs,
      activeTabIndex: this._getActiveTabIndex(tabs),
      vendorInfo: {},
      isLoading: false,
    };
  }

  _getActiveTabIndex(tabs) {
    const { pathname } = this.props.location;
    for (var i = 0; i < tabs.length; i++) {
      let tab = tabs[i];
      if (pathname === tab.url) {
        return i;
      }
    }
    return 0;
  }

  render() {
    const { tabs, activeTabIndex, vendorInfo } = this.state;

    let activeTab = tabs[activeTabIndex];
    let TabComponent = activeTab.component;

    return (
      <>
        <div className="container">
          <div className="card card-custom">
            <div className="card-body p-0">
              <div className="row">
                <div
                  className="wizard wizard-2"
                  id="kt_wizard_v2"
                  data-wizard-state="first"
                  data-wizard-clickable="false"
                >
                  <div className="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
                    {" "}
                    <Aside
                      tabs={tabs}
                      activeTabIndex={activeTabIndex}
                      onClick={(tab, index) => {
                        return;
                      }}
                    />
                  </div>

                  <TabComponent
                    vendorInfo={vendorInfo}
                    activeTabIndex={activeTabIndex}
                    onNextClick={(vendorInfo) =>
                      this.setState({
                        activeTabIndex: activeTabIndex + 1,
                        vendorInfo,
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(AddVendorSettings);

import React, { Component } from "react";

import { Route, Switch, withRouter } from "react-router-dom";

import Aside from "./components/Aside";

import ProductLibrarySection from "./components/settings/product-library/ProductLibrarySection";
import ProductCategories from "./components/settings/product-library/ProductCategories";
import ImportCSVFile from "./components/settings/product-library/ImportCSVFile";
import Backend from "../utils/Backend";
import Notify from "../utils/Notify";

const TAB_PRODUCT_LIBRARY_SETTINGS = {
  title: "Product Library",
  icon: (
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <rect x={0} y={0} width={24} height={24} />
      <path
        d="M13,19.4 L16.6856953,20.8742781 C16.8755245,20.9502098 17,21.1340642 17,21.3385165 L17,21.5 C17,21.7761424 16.7761424,22 16.5,22 L7.5,22 C7.22385763,22 7,21.7761424 7,21.5 L7,21.3385165 C7,21.1340642 7.12447547,20.9502098 7.31430466,20.8742781 L11,19.4 L11,13.5 L13,13.5 L13,19.4 Z"
        fill="#000000"
        opacity="0.3"
      />
      <path
        d="M13.2493901,13.9609566 C12.3868662,14.6509757 11.1282815,14.5111329 10.4382624,13.648609 L3.86900921,5.43704257 C3.65623355,5.171073 3.54031242,4.84060724 3.54031242,4.5 C3.54031242,3.67157288 4.2118853,3 5.04031242,3 L18.9596876,3 C19.3002948,3 19.6307606,3.11592113 19.8967301,3.32869679 C20.5436231,3.84621111 20.6485051,4.79014967 20.1309908,5.43704257 L13.5617376,13.648609 C13.4694741,13.7639384 13.3647195,13.868693 13.2493901,13.9609566 Z M6.08062485,5 L8.5,8.01229084 L15.5,8.01229084 L17.9193752,5 L6.08062485,5 Z"
        fill="#000000"
        fillRule="nonzero"
      />
    </g>
  ),
  largeIcon: true,
  description: "Add preset menu items",
  component: ProductLibrarySection,
};

const TAB_PRODUCT_CATERGORIES_SETTINGS = {
  title: "Product Categories",
  icon: (
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <rect x={0} y={0} width={24} height={24} />
      <path
        d="M5.84026576,8 L18.1597342,8 C19.1999115,8 20.0664437,8.79732479 20.1528258,9.83390904 L20.8194924,17.833909 C20.9112219,18.9346631 20.0932459,19.901362 18.9924919,19.9930915 C18.9372479,19.9976952 18.8818364,20 18.8264009,20 L5.1735991,20 C4.0690296,20 3.1735991,19.1045695 3.1735991,18 C3.1735991,17.9445645 3.17590391,17.889153 3.18050758,17.833909 L3.84717425,9.83390904 C3.93355627,8.79732479 4.80008849,8 5.84026576,8 Z M10.5,10 C10.2238576,10 10,10.2238576 10,10.5 L10,11.5 C10,11.7761424 10.2238576,12 10.5,12 L13.5,12 C13.7761424,12 14,11.7761424 14,11.5 L14,10.5 C14,10.2238576 13.7761424,10 13.5,10 L10.5,10 Z"
        fill="#000000"
      />
      <path
        d="M10,8 L8,8 L8,7 C8,5.34314575 9.34314575,4 11,4 L13,4 C14.6568542,4 16,5.34314575 16,7 L16,8 L14,8 L14,7 C14,6.44771525 13.5522847,6 13,6 L11,6 C10.4477153,6 10,6.44771525 10,7 L10,8 Z"
        fill="#000000"
        fillRule="nonzero"
        opacity="0.3"
      />
    </g>
  ),
  largeIcon: true,
  description: "Manage product categories",
  component: ProductCategories,
};

const TAB_IMPORT = {
  title: "Import",
  icon: (
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <rect x={0} y={0} width={24} height={24} />
      <path
        d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z"
        fill="#000000"
        fillRule="nonzero"
        opacity="0.3"
      >
        <path
          d="M14.8875071,11.8306874 L12.9310336,11.8306874 L12.9310336,9.82301606 C12.9310336,9.54687369 12.707176,9.32301606 12.4310336,9.32301606 L11.4077349,9.32301606 C11.1315925,9.32301606 10.9077349,9.54687369 10.9077349,9.82301606 L10.9077349,11.8306874 L8.9512614,11.8306874 C8.67511903,11.8306874 8.4512614,12.054545 8.4512614,12.3306874 C8.4512614,12.448999 8.49321518,12.5634776 8.56966458,12.6537723 L11.5377874,16.1594334 C11.7162223,16.3701835 12.0317191,16.3963802 12.2424692,16.2179453 C12.2635563,16.2000915 12.2831273,16.1805206 12.3009811,16.1594334 L15.2691039,12.6537723 C15.4475388,12.4430222 15.4213421,12.1275254 15.210592,11.9490905 C15.1202973,11.8726411 15.0058187,11.8306874 14.8875071,11.8306874 Z"
          fill="#000000"
        ></path>
      </path>
    </g>
  ),
  largeIcon: true,
  description: "Import a CSV file",
  component: ImportCSVFile,
};

const TABS = [
  TAB_PRODUCT_LIBRARY_SETTINGS,
  TAB_PRODUCT_CATERGORIES_SETTINGS,
  TAB_IMPORT,
];

export class ProductLibrarySettings extends Component {
  constructor(props) {
    super(props);

    let tabs = TABS;
    this.state = {
      tabs,
      activeTabIndex: this._getActiveTabIndex(tabs),
      data: null,
      isLoading: false,
    };
  }

  componentDidMount() {
    this._getCategories();
  }

  _getCategories() {
    this.setState({ isLoading: true });

    Backend.getCategories()
      .then((data) => {
        this.setState({ data, isLoading: false });
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  }

  _getActiveTabIndex(tabs) {
    const { pathname } = this.props.location;
    for (var i = 0; i < tabs.length; i++) {
      let tab = tabs[i];
      if (pathname === tab.url) {
        return i;
      }
    }
    return 0;
  }

  render() {
    const { tabs, activeTabIndex, data, isLoading } = this.state;

    let activeTab = tabs[activeTabIndex];
    let TabComponent = activeTab.component;

    if (isLoading) {
      return null;
    }

    return (
      <>
        <div className="container">
          <div className="card card-custom">
            <div className="card-body p-0">
              <div className="row">
                <div
                  className="wizard wizard-2"
                  id="kt_wizard_v2"
                  data-wizard-state="first"
                  data-wizard-clickable="false"
                >
                  <div className="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
                    {" "}
                    <Aside
                      tabs={tabs}
                      activeTabIndex={activeTabIndex}
                      onClick={(tab, index) => {
                        this.setState(
                          {
                            activeTabIndex: index,
                          },
                          () => {
                            this.props.history.push(tab.url);
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <TabComponent data={data} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ProductLibrarySettings);

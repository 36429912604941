import React, { Component } from "react";

import General from "../../../utils/General";
import LocationFormat from "../../../utils/LocationFormat";

export default class StoresDefaultCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      store: props.store,
    };
  }

  _renderAddress(address) {
    return (
      <span class="text-dark-75 font-weight-bold line-height-sm">
        {address ? LocationFormat.fullAddress(address) : "Address not entered."}
      </span>
    );
  }

  render() {
    const { store } = this.state;

    return (
      <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
        <div className="card card-custom gutter-b card-stretch">
          <div className="card-body text-center pt-4 ribbon ribbon-clip ribbon-right">
            <div className="mt-7">
              <div className="symbol symbol-100">
                <div
                  className="symbol-label"
                  style={{
                    width: "60px",
                    height: "60px",
                    borderRadius: "50%",
                  }}
                >
                  <h1 className="m-0" style={{ fontSize: "30px" }}>
                    {General.getInitials(`${store?.title}`)}
                  </h1>
                </div>
              </div>
            </div>

            <div className="my-2">
              <a
                href="javascript:;"
                className="text-dark font-weight-bold text-hover-primary font-size-h4"
              >
                {store?.title}
              </a>
            </div>

            <span className="text-muted font-weight-bold">
              {this._renderAddress(store?.address)}
            </span>

            <div className="mt-9 mb-6">
              <a
                href={`/manage-vendors/${this.props?.vendorId}/stores/${store?.id}/orders`}
                className="btn btn-outline-primary btn-rounded w-100 mb-3"
              >
                View Orders
              </a>

              <a
                href={`/manage-vendors/${this.props?.vendorId}/stores/${store?.id}`}
                className="btn btn-primary btn-rounded w-100"
              >
                Settings
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React from "react";

import { withRouter } from "react-router-dom";
import Backend from "../../../../utils/Backend";

import General from "../../../../utils/General";
import Notify from "../../../../utils/Notify";

import AddProductCategoryModal from "../../modals/AddProductCategoryModal";
import EditProductCategoryModal from "../../modals/EditProductCategoryModal";

import BaseTable from "../BaseTable";

import CategoriesSubTable from "./CategoriesSubTable";

class ProductCategoriesTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.table = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _isFormValid(category) {
    let error = null;

    if (category?.menu_item_count > 0) {
      error = "This category has associated menu items. It can't be deleted.";
    }

    if (error) {
      Notify.error(error);
      return false;
    }

    return true;
  }

  _deleteCategory(category) {
    if (!this._isFormValid(category)) {
      return;
    }

    this.setState({ isLoading: true });

    Backend.deleteCategory(category.id)
      .then((data) => {
        this.setState({ isLoading: false });
        Notify.success("Category has been deleted successfully.");
        this.table.current.refresh();
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  _getColumns() {
    return [
      {
        Header: () => <strong className="header__clr"></strong>,
        id: "title",
        width: 200,
        Cell: (rowInfo) => {
          let category = rowInfo.original;

          let categoryTitle = General.toTitleCase(category.title);

          let categoryImage = category.image.original;

          if (!categoryTitle) {
            return "-";
          }

          return (
            <>
              <div className="d-flex align-items-center">
                <div className="symbol symbol-40 flex-shrink-0">
                  {categoryImage ? (
                    <div
                      className="symbol-label"
                      style={{
                        backgroundColor: "#C63030",
                        backgroundImage: `url(${categoryImage})`,
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="ml-2">
                  <div className="text-dark-75 font-weight-bold line-height-sm">
                    {categoryTitle}
                  </div>
                </div>
              </div>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">PRODUCTS</strong>,
        id: "menu_item_count",
        Cell: (rowInfo) => {
          let category = rowInfo.original;

          let menuItemCount = category.menu_item_count;

          return <span className="ml-10">{menuItemCount}</span>;
        },
      },
      {
        Header: () => <strong className="header__clr">SALES</strong>,
        id: "sales_count",
        Cell: (rowInfo) => {
          let category = rowInfo.original;

          let salesCount = category.sales_count;

          return <span className="ml-10">{salesCount}</span>;
        },
      },

      {
        Header: () => <strong className="header__clr"></strong>,
        id: "id",
        width: 160,
        sortable: false,
        Cell: (rowInfo) => {
          let category = rowInfo.original;

          return (
            <>
              <div className="row">
                <span className="ml-3">
                  {" "}
                  <a
                    href="javascript:;"
                    className="btn btn-sm btn-rounded btn-outline-primary mr-2 w-xs-100"
                    data-toggle="modal"
                    data-target="#editLibraryMenu"
                    onClick={() =>
                      this.setState({
                        category,
                        showEditProductCategoryModal: true,
                      })
                    }
                  >
                    Edit
                  </a>{" "}
                </span>
                <span>
                  {" "}
                  <a
                    href="javascript:;"
                    className="btn btn-sm btn-rounded btn-outline-primary mr-2 w-xs-100"
                    onClick={() => this._deleteCategory(category)}
                  >
                    Remove
                  </a>{" "}
                </span>
              </div>
            </>
          );
        },
      },
    ];
  }

  _renderCategoriesSubTable(category) {
    const subcategories = category?.subcategories?.map(
      (subcategory) => subcategory
    );

    return (
      <CategoriesSubTable
        showPaginationTop={false}
        title={``}
        subcategories={subcategories}
        showSubCategoryData={true}
        onUpdated={() => this.table.current.refresh()}
      />
    );
  }

  render() {
    const {
      showAddProductCategoryModal,
      showEditProductCategoryModal,
      category,
    } = this.state;

    const columns = this._getColumns();

    let tableProps = {};

    if (this.props.showCategoriesSubTable) {
      tableProps = {
        SubComponent: (row) => this._renderCategoriesSubTable(row.original),
      };
    }

    return (
      <>
        <BaseTable
          {...tableProps}
          ref={this.table}
          title={this.props.title}
          endpoint={`${window.Api.Categories}?has_parent=false`}
          noDataMessage={"No categories found"}
          columns={columns}
          showPagination={true}
          showSearch={false}
          defaultSorted={[
            {
              id: "created_at",
              desc: true,
            },
          ]}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "Actions" ? "visible" : "hidden",
              },
            };
          }}
          renderToolbar={(rowInfo) => {
            return (
              <div className="kt-portlet__head-toolbar">
                <div className="kt-portlet__head-wrapper">
                  <div className="row align-items-right">
                    <div className="col-md-12 my-2 my-md-0">
                      <div className="dropdown dropdown-inline mr-2">
                        <a
                          href="javascript:;"
                          data-toggle="modal"
                          data-target="#addLibraryCategory"
                          className="btn btn-outline-primary font-weight-bolder"
                          onClick={() =>
                            this.setState({
                              showAddProductCategoryModal: true,
                            })
                          }
                        >
                          <span className="svg-icon svg-icon-2x">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                strokeWidth={1}
                                fill="none"
                                fillRule="evenodd"
                              >
                                <rect
                                  fill="#000000"
                                  x={4}
                                  y={11}
                                  width={16}
                                  height={2}
                                  rx={1}
                                />
                                <rect
                                  fill="#000000"
                                  opacity="0.3"
                                  transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
                                  x={4}
                                  y={11}
                                  width={16}
                                  height={2}
                                  rx={1}
                                />
                              </g>
                            </svg>
                          </span>
                          Add Category
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        />

        <AddProductCategoryModal
          show={showAddProductCategoryModal}
          onUpdated={(selectedCategory) => {
            window.location.reload();

            this.setState({
              showAddProductCategoryModal: false,
            });
          }}
          onHide={() =>
            this.setState({
              showAddProductCategoryModal: false,
            })
          }
        />

        <EditProductCategoryModal
          show={showEditProductCategoryModal}
          data={category}
          onUpdated={(selectedCategory) => {
            this.table.current.refresh();

            this.setState({
              showEditProductCategoryModal: false,
              category: null,
            });
          }}
          onHide={() =>
            this.setState({
              showEditProductCategoryModal: false,
              category: null,
            })
          }
        />
      </>
    );
  }
}

ProductCategoriesTable.defaultProps = {
  title: "Categories",
  showPagination: true,
  showPaginationTop: true,
  showPaginationBottom: true,
};

export default withRouter(ProductCategoriesTable);

import React, { Component } from "react";

import Modal from "react-bootstrap/Modal";

import Notify from "../../../utils/Notify";

import FileUpload from "../common/FileUpload";

export default class CSVFileModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: props.show,
      file: null,
      isLoading: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _isFormValid() {
    const { file } = this.state;

    let error = null;

    if (!file) {
      error = "Please select a file.";
    }
    if (error) {
      Notify.error(error);
      return false;
    }

    return true;
  }

  render() {
    const { show, file, isLoading } = this.state;

    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide();
          }
        }}
      >
        <div className="modal-content">
          <Modal.Header closeButton>
            <Modal.Title> Import A CSV file</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div>
              <p>
                To import a .CSV file, set up your document with the headers in
                the following order:
              </p>
              <h6 className="font-weight-bolder text-dark">
                Title, Description, Image url, Category, Subcategory.
              </h6>
              <p className="text-warning font-weight-bolder">
                Note: Category must match an existing category from Clinks
                database.
              </p>
              <p />
              <FileUpload
                file={file}
                endpoint={`${window.Api.Items}/import`}
                accept={".csv"}
                onUploaded={(file) => {
                  this.setState({ file, isLoading: false });

                  this.props.onHide();
                }}
              />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              className="btn btn-outline-primary font-weight-bold"
              onClick={() => this.props.onHide()}
            >
              Close
            </button>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }
}

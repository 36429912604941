import React, { Component } from "react";

import Modal from "react-bootstrap/Modal";

import Backend from "../../../utils/Backend";
import General from "../../../utils/General";
import Notify from "../../../utils/Notify";

import ImageUpload from "../../components/common/ImageUpload";
import AsyncSelect from "../common/AsyncSelect";

export default class EditProductCategoryModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this._getState(props),
      show: props.show,
      isLoading: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps));
  }

  _getState(props) {
    return {
      ...props,
      data: General.clone(props.data),
    };
  }

  _handleChange(e) {
    let { data } = this.state;

    let value = e.target.value;

    data[e.target.name] = value;

    this.setState({ data });
  }

  _isFormValid() {
    const { data } = this.state;

    const { title, image } = data;

    let error = null;

    if (!title) {
      error = "Please enter a valid title.";
    } else if (!image) {
      error = "Please upload an item image.";
    }

    if (error) {
      Notify.error(error);
      return false;
    }

    return true;
  }

  _editProductCategory() {
    let { data } = this.state;

    if (!this._isFormValid()) {
      return;
    }

    data = {
      id: data.id,
      title: data.title,
      parent: data.parent ? data.parent?.id : null,
      image: data.image?.id,
    };

    this.setState({ isLoading: true });

    Backend.editProductCategory(data)
      .then((data) => {
        this.setState({ isLoading: false });
        Notify.success("Item has been updated successfully.");
        this.props.onUpdated(data);
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  _getParent(parent) {
    return {
      label: parent.title,
      value: parent.id,
      data: parent,
    };
  }

  render() {
    let { show, data, isLoading } = this.state;

    if (!data) {
      return null;
    }

    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide();
          }
        }}
      >
        <div className="modal-content">
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props?.showSubCategoryData
                ? "Edit Sub-Category"
                : "Edit Category"}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="form-group row">
              <div data-repeater-list className="col-lg-12">
                <div
                  data-repeater-item
                  className="form-group row align-items-center"
                >
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Item Name *</label>
                      <input
                        type="text"
                        className="form-control form-control-solid form-control-lg"
                        placeholder="Enter Title"
                        name="title"
                        value={data?.title}
                        onChange={(e) => this._handleChange(e)}
                      />
                    </div>
                  </div>
                  {!this.props?.showSubCategoryData && (
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>
                          Does this category have a Parent Category?
                        </label>
                        <AsyncSelect
                          endpoint={window.Api.Categories}
                          filter={"has_parent=false"}
                          orderBy={"title"}
                          search_term={"title"}
                          getOptions={(parents) => {
                            return parents.map((parent) =>
                              this._getParent(parent)
                            );
                          }}
                          className="form-control h-auto border-0 form-control-solid c-selectbox"
                          name="parent"
                          value={
                            data?.parent ? this._getParent(data.parent) : null
                          }
                          placeholder="Please Select a Type"
                          onSelected={(parent) => {
                            data.parent = parent;
                            this.setState({ data });
                          }}
                        />
                      </div>
                    </div>
                  )}

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Item Photo</label>
                      <ImageUpload
                        image={data?.image}
                        hidePreview={false}
                        cropImage={true}
                        aspectRatio={1}
                        onUpdated={(image) => {
                          data.image = image;
                          this.setState({ data });
                        }}
                        onLoading={(isLoading) => this.setState({ isLoading })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              className="btn btn-outline-primary font-weight-bold"
              onClick={() => this.props.onHide()}
            >
              Close
            </button>

            <button
              type="button"
              className="btn btn-primary font-weight-bold"
              disabled={isLoading}
              onClick={() => this._editProductCategory()}
            >
              Save Changes
            </button>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }
}

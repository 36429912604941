import React from "react";

import { withRouter } from "react-router-dom";

import LocationFormat from "../../../../utils/LocationFormat";
import BaseTable from "../BaseTable";
import General from "../../../../utils/General";

import EditDriverModal from "../../modals/EditDriverModal";
import Backend from "../../../../utils/Backend";
import Notify from "../../../../utils/Notify";

class ManageDriversTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.table = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _pauseDriver(driverId) {
    this.setState({ isLoading: true });

    Backend.pauseDriver(driverId)
      .then(() => {
        this.setState({ isLoading: false });
        Notify.success("Driver has been paused successfully.");
        this.table.current.refresh();
        window.location.reload();
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  _renderAddress(address) {
    return (
      <span className="text-dark-75 font-weight-bold line-height-sm">
        {address ? LocationFormat.fullAddress(address) : "-"}
      </span>
    );
  }

  _getColumns() {
    return [
      {
        Header: () => <strong className="header__clr">DRIVER</strong>,
        id: "user__first_name",
        Cell: (rowInfo) => {
          let driver = rowInfo.original;

          let name = `${General.toTitleCase(
            driver.user.first_name
          )} ${General.toTitleCase(driver.user.last_name)}`;

          if (!name) {
            return "-";
          }

          return (
            <>
              <span>{name}</span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">LICENCE</strong>,
        id: "identification",
        sortable: false,
        Cell: (rowInfo) => {
          let driver = rowInfo.original;

          let driverLicenceFront = driver?.identification?.front.original;

          let driverLicenceBack = driver?.identification?.back.original;

          if (!driverLicenceFront || !driverLicenceBack) {
            return "-";
          }

          return (
            <>
              {driver?.vehicle_type !== "bicycle" ? (
                <div className="row ml-2">
                  <div onClick={() => window.open(driverLicenceFront)}>
                    <span>Front</span>{" "}
                    <i
                      className="icon-xl la la-download text-primary mr-1"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <div
                    className="ml-4"
                    onClick={() => window.open(driverLicenceBack)}
                  >
                    <span>Back</span>{" "}
                    <i
                      className="icon-xl la la-download text-primary mr-1"
                      style={{ cursor: "pointer" }}
                    />
                  </div>{" "}
                </div>
              ) : (
                "-"
              )}
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">VEHICLE</strong>,
        id: "vehicle_type",
        sortable: false,
        Cell: (rowInfo) => {
          let driver = rowInfo.original;

          let vehicleType = driver.vehicle_type;

          if (!vehicleType) {
            return "-";
          }

          return (
            <>
              <span> {vehicleType}</span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">REGISTRATION</strong>,
        id: "vehicle_registration_no",
        sortable: false,
        Cell: (rowInfo) => {
          let driver = rowInfo.original;

          let registrationNumber = driver.vehicle_registration_no;

          if (!registrationNumber) {
            return "-";
          }

          return (
            <>
              <span>
                {driver?.vehicle_type !== "bicycle" ? registrationNumber : "-"}{" "}
              </span>
            </>
          );
        },
      },

      {
        Header: () => <strong className="header__clr">DELIVERIES</strong>,
        id: "order_count",
        Cell: (rowInfo) => {
          let driver = rowInfo.original;

          let orderCount = driver.order_count;

          return (
            <>
              <span>{orderCount} </span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">STATUS</strong>,
        id: "status",
        sortable: false,
        Cell: (rowInfo) => {
          let driverStatus = rowInfo.original;

          let labelClass = "";
          let status = "";

          if (
            driverStatus.last_known_location != null &&
            driverStatus.current_delivery_request == null
          ) {
            labelClass = "label-light-success";
            status = "Able To Accept Deliveries";
          } else if (driverStatus.last_known_location == null  && driverStatus.current_delivery_request == null) {
            labelClass = "label-danger";
            status = "Offline";
          } else if (
            driverStatus.last_known_location != null &&
            driverStatus.current_delivery_request != null
          ) {
            labelClass = "label-light-info";
            status = "Delivering";
          }

          if (!status) {
            return "-";
          }

          return (
            <>
              <span className={`label ${labelClass} label-inline label-pill`}>
                {status}
              </span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">ACTIONS</strong>,
        id: "id",
        sortable: false,
        Cell: (rowInfo) => {
          let driver = rowInfo.original;

          let selectedDriver = driver;

          return (
            <>
              <span style={{ width: 130 }}>
                <a
                  href={`/manage-drivers/${selectedDriver?.user?.id}`}
                  className="btn btn-icon btn-sm btn-pill btn-outline-primary mr-2"
                  data-toggle="popover"
                  data-content="Manage Driver"
                  data-original-title=""
                  title=""
                >
                  <span className="svg-icon svg-icon-md">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect x={0} y={0} width={24} height={24} />
                        <path
                          d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                          fill="#000000"
                          fillRule="nonzero"
                          transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "
                        />
                        <rect
                          fill="#000000"
                          opacity="0.3"
                          x={5}
                          y={20}
                          width={15}
                          height={2}
                          rx={1}
                        />
                      </g>
                    </svg>
                  </span>
                </a>
                <div className="dropdown dropdown-inline show">
                  <a
                    href="javascript:;"
                    className="btn btn-icon btn-sm btn-pill btn-outline-primary"
                    data-toggle="dropdown"
                  >
                    <span className="svg-icon svg-icon-md">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth={1}
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x={0} y={0} width={24} height={24} />
                          <path
                            d="M5,8.6862915 L5,5 L8.6862915,5 L11.5857864,2.10050506 L14.4852814,5 L19,5 L19,9.51471863 L21.4852814,12 L19,14.4852814 L19,19 L14.4852814,19 L11.5857864,21.8994949 L8.6862915,19 L5,19 L5,15.3137085 L1.6862915,12 L5,8.6862915 Z M12,15 C13.6568542,15 15,13.6568542 15,12 C15,10.3431458 13.6568542,9 12,9 C10.3431458,9 9,10.3431458 9,12 C9,13.6568542 10.3431458,15 12,15 Z"
                            fill="#000000"
                          />
                        </g>
                      </svg>
                    </span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                    <ul className="navi flex-column navi-hover py-2">
                      <li className="navi-header font-weight-bolder text-uppercase font-size-xs text-primary pb-2">
                        Choose an action:
                      </li>
                      <li className="navi-item">
                        <a
                          href="javascript:;"
                          className="navi-link"
                          onClick={() =>
                            this._pauseDriver(selectedDriver?.user)
                          }
                        >
                          <span className="navi-text">Delete Driver</span>
                        </a>
                      </li>
                      <li className="navi-item">
                        <a
                          href="javascript:;"
                          className="navi-link"
                          onClick={() => {
                            this.setState({
                              showEditDriverModal: true,
                              selectedDriver: driver,
                            });
                          }}
                        >
                          <span className="navi-text">Edit Driver</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </span>
            </>
          );
        },
      },
    ];
  }

  render() {
    const { showEditDriverModal, selectedDriver } = this.state;

    const columns = this._getColumns();

    let tableProps = {};

    return (
      <>
        <BaseTable
          {...tableProps}
          ref={this.table}
          title={this.props.title}
          endpoint={`${window.Api.Drivers}`}
          noDataMessage={"No drivers found"}
          columns={columns}
          showPagination={true}
          defaultSorted={[
            {
              id: "created_at",
              desc: true,
            },
          ]}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "Actions" ? "visible" : "hidden",
              },
            };
          }}
          renderToolbar={(rowInfo) => {
            return (
              <div className="kt-portlet__head-toolbar">
                <div className="kt-portlet__head-wrapper">
                  <div className="row align-items-right">
                    <div className="col-md-12 my-2 my-md-0">
                      <div className="dropdown dropdown-inline mr-2">
                        <a
                          href="/add-driver"
                          className="btn btn-primary font-weight-bolder w-100"
                        >
                          Add a Driver
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        />

        <EditDriverModal
          show={showEditDriverModal}
          data={selectedDriver}
          onUpdated={() => {
            this.table.current.refresh();

            this.setState({
              showEditDriverModal: false,
            });
          }}
          onHide={() =>
            this.setState({
              showEditDriverModal: false,
            })
          }
        />
      </>
    );
  }
}

ManageDriversTable.defaultProps = {
  title: "All Drivers",
  showPagination: true,
  showPaginationTop: true,
  showPaginationBottom: true,
};

export default withRouter(ManageDriversTable);

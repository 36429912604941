import { data } from "jquery";
import React, { Component } from "react";

import Modal from "react-bootstrap/Modal";

import Backend from "../../../utils/Backend";
import General from "../../../utils/General";
import Notify from "../../../utils/Notify";

import AsyncSelect from "../common/AsyncSelect";

export default class AddCategoryModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this._getState(props),
      show: props.show,
      isLoading: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps));
  }

  _getState(props) {
    return {
      ...props,
      data: {
        menu: null,
        category: null,
      },
    };
  }

  _addMenuCategory() {
    let { data } = this.state;

    this.setState({ isLoading: true });

    data.category = data.category ? data.category.id : null;
    data.menu = this.props.menuId;

    Backend.addMenuCategory(data)
      .then((data) => {
        this.setState({ data, isLoading: false });
        Notify.success("Category has been added successfully");
        this.props.onUpdated();
      })

      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  _getCategory(category) {
    return {
      label: category?.title,
      value: category?.id,
      data: category,
    };
  }

  render() {
    const { show, data, isLoading } = this.state;

    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide();
          }
        }}
      >
        <div className="modal-content">
          <Modal.Header closeButton>
            <Modal.Title> Add A Category</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <>
              <AsyncSelect
                endpoint={`${window.Api.Categories}`}
                filter={"has_parent=false"}
                orderBy={"title"}
                search_term={"title"}
                getOptions={(categories) => {
                  return categories?.map((category) =>
                    this._getCategory(category)
                  );
                }}
                className="form-control h-auto border-0 form-control-solid c-selectbox"
                name="category"
                value={data.category ? this._getCategory(data.category) : null}
                placeholder="Please Select a Type"
                onSelected={(category) => {
                  data.category = category;

                  this.setState({ data });
                }}
              />
            </>
          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              className="btn btn-outline-primary font-weight-bold"
              onClick={() => this.props.onHide()}
            >
              Close
            </button>

            <button
              type="button"
              className="btn btn-primary font-weight-bold"
              disabled={isLoading || !data.category}
              onClick={() => this._addMenuCategory()}
            >
              Add Category
            </button>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }
}

import React, { Component } from "react";

import Backend from "../utils/Backend";
import LocationFormat from "../utils/LocationFormat";
import Notify from "../utils/Notify";
import Currency from "../utils/Currency";
import General from "../utils/General";

import StatsCard from "./components/stats-cards/StatsCard";

import AddCategoryModal from "./components/modals/AddCategoryModal";
import StoreDetailsModal from "./components/modals/StoreDetailsModal";

import StoreMenuTable from "./components/tables/manage-stores/StoreMenuTable";
import StoreOrdersTable from "./components/tables/manage-stores/StoreOrdersTable";

export default class StoreDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      venueData: null,
      selectedCategoryItem: null,
    };
  }

  componentDidMount() {
    this._getMenu();
    this._getVenueDetails();
  }

  onClickTabItem(tab) {
    this.setState({ selectedCategoryItem: tab });
  }

  _getMenu() {
    let menuId = this.props.match.params?.venueId;

    if (menuId) {
      this.setState({
        isMenuDataLoading: true,
      });

      Backend.getMenu(menuId)
        .then((data) => {
          this.setState({ data, isMenuDataLoading: false });
        })
        .catch((error) => {
          Notify.error(error.message);
          this.setState({
            isMenuDataLoading: false,
          });
        });
    } else {
      return;
    }
  }

  _getVenueDetails() {
    let venueId = this.props.match.params?.venueId;

    this.setState({ isVenueDataLoading: true });

    Backend.getVenueDetails(venueId)
      .then((venueData) => {
        this.setState({ venueData, isVenueDataLoading: false });
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isVenueDataLoading: false });
      });
  }

  _showCategories() {
    let { data, selectedCategoryItem } = this.state;

    if (data?.menu_categories.length > 0) {
      selectedCategoryItem = data.menu_categories[0]?.category.id;
    }

    return data?.menu_categories?.map((categoryItem) => {
      return (
        <li className="nav-item mt-3" key={categoryItem?.category?.id}>
          <a
            onClick={() => {
              this.onClickTabItem(categoryItem?.category?.id);
            }}
            className={`nav-link ${
              selectedCategoryItem === categoryItem?.category?.id
                ? "active"
                : ""
            }`}
            id="wine-tab"
            data-toggle="tab"
            href="wine"
            aria-controls="profile"
          >
            <span className="nav-text">
              {General.toTitleCase(categoryItem?.category?.title)}
            </span>
          </a>
        </li>
      );
    });
  }

  _renderAddress(address) {
    return (
      <span className="text-dark-75 font-weight-bold line-height-sm">
        {address ? LocationFormat.fullAddress(address) : "Address not entered."}
      </span>
    );
  }

  render() {
    const { data, venueData, showAddCategoryModal, showStoreDetailsModal } =
      this.state;

    if (!venueData) {
      return null;
    }

    let categoryMenuItem = data?.menu_categories?.find(
      (categoryItem) =>
        categoryItem?.category?.id == this.state.selectedCategoryItem
    );

    let categoryId = categoryMenuItem?.category.id;

    let menuCategoryId = categoryMenuItem?.id;

    let menuId = this.props.match.params?.venueId;

    let averageDeliveryTimeSeconds = venueData?.average_delivery_time * 60;

    return (
      <>
        <div className="container">
          <div className="card card-custom gutter-b">
            <div className="card-body">
              <div className="d-flex">
                <div className="flex-shrink-0 mr-7"></div>
                <div className="flex-grow-1">
                  <div className="d-flex align-items-center justify-content-between flex-wrap">
                    <div className="mr-3">
                      <div className="d-flex align-items-center mr-3">
                        <a
                          href="javascript:;"
                          className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3"
                        >
                          {venueData?.title}
                        </a>
                      </div>
                      <div className="d-flex flex-wrap my-2">
                        <a
                          href="javascript:;"
                          className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                        >
                          <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                strokeWidth={1}
                                fill="none"
                                fillRule="evenodd"
                              >
                                <rect x={0} y={0} width={24} height={24} />
                                <path
                                  d="M21,12.0829584 C20.6747915,12.0283988 20.3407122,12 20,12 C16.6862915,12 14,14.6862915 14,18 C14,18.3407122 14.0283988,18.6747915 14.0829584,19 L5,19 C3.8954305,19 3,18.1045695 3,17 L3,8 C3,6.8954305 3.8954305,6 5,6 L19,6 C20.1045695,6 21,6.8954305 21,8 L21,12.0829584 Z M18.1444251,7.83964668 L12,11.1481833 L5.85557487,7.83964668 C5.4908718,7.6432681 5.03602525,7.77972206 4.83964668,8.14442513 C4.6432681,8.5091282 4.77972206,8.96397475 5.14442513,9.16035332 L11.6444251,12.6603533 C11.8664074,12.7798822 12.1335926,12.7798822 12.3555749,12.6603533 L18.8555749,9.16035332 C19.2202779,8.96397475 19.3567319,8.5091282 19.1603533,8.14442513 C18.9639747,7.77972206 18.5091282,7.6432681 18.1444251,7.83964668 Z"
                                  fill="#000000"
                                />
                                <circle
                                  fill="#000000"
                                  opacity="0.3"
                                  cx="19.5"
                                  cy="17.5"
                                  r="2.5"
                                />
                              </g>
                            </svg>
                          </span>
                          {this._renderAddress(venueData?.address)}
                        </a>
                        <a
                          href="javascript:;"
                          className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                        >
                          <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                strokeWidth={1}
                                fill="none"
                                fillRule="evenodd"
                              >
                                <rect x={0} y={0} width={24} height={24} />
                                <path
                                  d="M8,2.5 C7.30964406,2.5 6.75,3.05964406 6.75,3.75 L6.75,20.25 C6.75,20.9403559 7.30964406,21.5 8,21.5 L16,21.5 C16.6903559,21.5 17.25,20.9403559 17.25,20.25 L17.25,3.75 C17.25,3.05964406 16.6903559,2.5 16,2.5 L8,2.5 Z"
                                  fill="#000000"
                                  opacity="0.3"
                                />
                                <path
                                  d="M8,2.5 C7.30964406,2.5 6.75,3.05964406 6.75,3.75 L6.75,20.25 C6.75,20.9403559 7.30964406,21.5 8,21.5 L16,21.5 C16.6903559,21.5 17.25,20.9403559 17.25,20.25 L17.25,3.75 C17.25,3.05964406 16.6903559,2.5 16,2.5 L8,2.5 Z M8,1 L16,1 C17.5187831,1 18.75,2.23121694 18.75,3.75 L18.75,20.25 C18.75,21.7687831 17.5187831,23 16,23 L8,23 C6.48121694,23 5.25,21.7687831 5.25,20.25 L5.25,3.75 C5.25,2.23121694 6.48121694,1 8,1 Z M9.5,1.75 L14.5,1.75 C14.7761424,1.75 15,1.97385763 15,2.25 L15,3.25 C15,3.52614237 14.7761424,3.75 14.5,3.75 L9.5,3.75 C9.22385763,3.75 9,3.52614237 9,3.25 L9,2.25 C9,1.97385763 9.22385763,1.75 9.5,1.75 Z"
                                  fill="#000000"
                                  fillRule="nonzero"
                                />
                              </g>
                            </svg>
                          </span>
                          {venueData?.phone_number}
                        </a>
                      </div>
                    </div>

                    <div className="mb-10">
                      <a
                        href="javascript:;"
                        className="btn btn-primary btn-sm font-weight-bolder text-uppercase mb-5"
                        onClick={() =>
                          this.setState({
                            showStoreDetailsModal: true,
                          })
                        }
                      >
                        Edit
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row wd-100">
            <div className="col-lg-4">
              {" "}
              <StatsCard
                data={[
                  {
                    statsInfo: `${Currency.format(venueData?.total_earnings)}`,
                    title: "Total Revenue",
                    icon: (
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect x={0} y={0} width={24} height={24} />
                        <path
                          d="M4.3618034,10.2763932 L4.8618034,9.2763932 C4.94649941,9.10700119 5.11963097,9 5.30901699,9 L15.190983,9 C15.4671254,9 15.690983,9.22385763 15.690983,9.5 C15.690983,9.57762255 15.6729105,9.65417908 15.6381966,9.7236068 L15.1381966,10.7236068 C15.0535006,10.8929988 14.880369,11 14.690983,11 L4.80901699,11 C4.53287462,11 4.30901699,10.7761424 4.30901699,10.5 C4.30901699,10.4223775 4.32708954,10.3458209 4.3618034,10.2763932 Z M14.6381966,13.7236068 L14.1381966,14.7236068 C14.0535006,14.8929988 13.880369,15 13.690983,15 L4.80901699,15 C4.53287462,15 4.30901699,14.7761424 4.30901699,14.5 C4.30901699,14.4223775 4.32708954,14.3458209 4.3618034,14.2763932 L4.8618034,13.2763932 C4.94649941,13.1070012 5.11963097,13 5.30901699,13 L14.190983,13 C14.4671254,13 14.690983,13.2238576 14.690983,13.5 C14.690983,13.5776225 14.6729105,13.6541791 14.6381966,13.7236068 Z"
                          fill="#000000"
                          opacity="0.3"
                        />
                        <path
                          d="M17.369,7.618 C16.976998,7.08599734 16.4660031,6.69750122 15.836,6.4525 C15.2059968,6.20749878 14.590003,6.085 13.988,6.085 C13.2179962,6.085 12.5180032,6.2249986 11.888,6.505 C11.2579969,6.7850014 10.7155023,7.16999755 10.2605,7.66 C9.80549773,8.15000245 9.45550123,8.72399671 9.2105,9.382 C8.96549878,10.0400033 8.843,10.7539961 8.843,11.524 C8.843,12.3360041 8.96199881,13.0779966 9.2,13.75 C9.43800119,14.4220034 9.7774978,14.9994976 10.2185,15.4825 C10.6595022,15.9655024 11.1879969,16.3399987 11.804,16.606 C12.4200031,16.8720013 13.1129962,17.005 13.883,17.005 C14.681004,17.005 15.3879969,16.8475016 16.004,16.5325 C16.6200031,16.2174984 17.1169981,15.8010026 17.495,15.283 L19.616,16.774 C18.9579967,17.6000041 18.1530048,18.2404977 17.201,18.6955 C16.2489952,19.1505023 15.1360064,19.378 13.862,19.378 C12.6999942,19.378 11.6325049,19.1855019 10.6595,18.8005 C9.68649514,18.4154981 8.8500035,17.8765035 8.15,17.1835 C7.4499965,16.4904965 6.90400196,15.6645048 6.512,14.7055 C6.11999804,13.7464952 5.924,12.6860058 5.924,11.524 C5.924,10.333994 6.13049794,9.25950479 6.5435,8.3005 C6.95650207,7.34149521 7.5234964,6.52600336 8.2445,5.854 C8.96550361,5.18199664 9.8159951,4.66400182 10.796,4.3 C11.7760049,3.93599818 12.8399943,3.754 13.988,3.754 C14.4640024,3.754 14.9609974,3.79949954 15.479,3.8905 C15.9970026,3.98150045 16.4939976,4.12149906 16.97,4.3105 C17.4460024,4.49950095 17.8939979,4.7339986 18.314,5.014 C18.7340021,5.2940014 19.0909985,5.62999804 19.385,6.022 L17.369,7.618 Z"
                          fill="#000000"
                        />
                      </g>
                    ),
                  },
                ]}
              />{" "}
            </div>
            <div className="col-lg-4">
              {" "}
              <StatsCard
                data={[
                  {
                    statsInfo: `${venueData?.sales_count}`,
                    title: "All Orders",
                    icon: (
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect x={0} y={0} width={24} height={24} />
                        <path
                          d="M22,17 L22,21 C22,22.1045695 21.1045695,23 20,23 L4,23 C2.8954305,23 2,22.1045695 2,21 L2,17 L6.27924078,17 L6.82339262,18.6324555 C7.09562072,19.4491398 7.8598984,20 8.72075922,20 L15.381966,20 C16.1395101,20 16.8320364,19.5719952 17.1708204,18.8944272 L18.118034,17 L22,17 Z"
                          fill="#000000"
                        />
                        <path
                          d="M2.5625,15 L5.92654389,9.01947752 C6.2807805,8.38972356 6.94714834,8 7.66969497,8 L16.330305,8 C17.0528517,8 17.7192195,8.38972356 18.0734561,9.01947752 L21.4375,15 L18.118034,15 C17.3604899,15 16.6679636,15.4280048 16.3291796,16.1055728 L15.381966,18 L8.72075922,18 L8.17660738,16.3675445 C7.90437928,15.5508602 7.1401016,15 6.27924078,15 L2.5625,15 Z"
                          fill="#000000"
                          opacity="0.3"
                        />
                        <path
                          d="M11.1288761,0.733697713 L11.1288761,2.69017121 L9.12120481,2.69017121 C8.84506244,2.69017121 8.62120481,2.91402884 8.62120481,3.19017121 L8.62120481,4.21346991 C8.62120481,4.48961229 8.84506244,4.71346991 9.12120481,4.71346991 L11.1288761,4.71346991 L11.1288761,6.66994341 C11.1288761,6.94608579 11.3527337,7.16994341 11.6288761,7.16994341 C11.7471877,7.16994341 11.8616664,7.12798964 11.951961,7.05154023 L15.4576222,4.08341738 C15.6683723,3.90498251 15.6945689,3.58948575 15.5161341,3.37873564 C15.4982803,3.35764848 15.4787093,3.33807751 15.4576222,3.32022374 L11.951961,0.352100892 C11.7412109,0.173666017 11.4257142,0.199862688 11.2472793,0.410612793 C11.1708299,0.500907473 11.1288761,0.615386087 11.1288761,0.733697713 Z"
                          fill="#000000"
                          fillRule="nonzero"
                          transform="translate(11.959697, 3.661508) rotate(-270.000000) translate(-11.959697, -3.661508) "
                        />
                      </g>
                    ),
                  },
                ]}
              />{" "}
            </div>
            <div className="col-lg-4">
              {" "}
              <StatsCard
                data={[
                  {
                    statsInfo: `${General.averageDeliveryTime(
                      averageDeliveryTimeSeconds
                    )}`,
                    title: "Avg. Delivery Time",
                    icon: (
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect x={0} y={0} width={24} height={24} />
                        <path
                          d="M5.94290508,4 L18.0570949,4 C18.5865712,4 19.0242774,4.41271535 19.0553693,4.94127798 L19.8754445,18.882556 C19.940307,19.9852194 19.0990032,20.9316862 17.9963398,20.9965487 C17.957234,20.9988491 17.9180691,21 17.8788957,21 L6.12110428,21 C5.01653478,21 4.12110428,20.1045695 4.12110428,19 C4.12110428,18.9608266 4.12225519,18.9216617 4.12455553,18.882556 L4.94463071,4.94127798 C4.97572263,4.41271535 5.41342877,4 5.94290508,4 Z"
                          fill="#000000"
                          opacity="0.3"
                        />
                        <path
                          d="M7,7 L9,7 C9,8.65685425 10.3431458,10 12,10 C13.6568542,10 15,8.65685425 15,7 L17,7 C17,9.76142375 14.7614237,12 12,12 C9.23857625,12 7,9.76142375 7,7 Z"
                          fill="#000000"
                        />
                      </g>
                    ),
                  },
                ]}
              />{" "}
            </div>
          </div>
          <div className="card card-custom gutter-b">
            <div className="card-body">
              <ul className="nav nav-tabs nav-tabs-line">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#kt_tab_pane_1"
                  >
                    Orders
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#kt_tab_pane_3"
                  >
                    Menu
                  </a>
                </li>
              </ul>
              <div className="tab-content mt-5" id="myTabContent">
                <div
                  className="tab-pane fade active show"
                  id="kt_tab_pane_1"
                  role="tabpanel"
                  aria-labelledby="kt_tab_pane_2"
                >
                  <StoreOrdersTable venueId={venueData?.id} />
                </div>
                <div
                  className="tab-pane fade"
                  id="kt_tab_pane_3"
                  role="tabpanel"
                  aria-labelledby="kt_tab_pane_3"
                >
                  <div className="form-group category-row">
                    <ul
                      className="nav nav-primary nav-pills"
                      id="category-tabs"
                      role="tablist"
                    >
                      {this._showCategories()}

                      <li className="nav-item mt-3 ml-3">
                        <button
                          type="button"
                          className="btn btn-outline-primary w-xs-100"
                          data-toggle="modal"
                          data-target="#categoryAdd"
                          onClick={() =>
                            this.setState({
                              showAddCategoryModal: true,
                            })
                          }
                        >
                          Add a Category
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="card card-custom"
                    style={{ boxShadow: "none" }}
                  >
                    {categoryMenuItem && (
                      <StoreMenuTable
                        menuId={menuId}
                        menuCategoryId={menuCategoryId}
                        categoryId={categoryId}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <AddCategoryModal
          show={showAddCategoryModal}
          menuId={menuId}
          onUpdated={(category) => {
            this._getMenu();
            this.setState({
              showAddCategoryModal: false,
            });
          }}
          onHide={() =>
            this.setState({
              showAddCategoryModal: false,
            })
          }
        />

        <StoreDetailsModal
          show={showStoreDetailsModal}
          data={venueData}
          onUpdated={() => {
            this._getVenueDetails();
            this.setState({
              showStoreDetailsModal: false,
            });
          }}
          onHide={() =>
            this.setState({
              showStoreDetailsModal: false,
            })
          }
        />
      </>
    );
  }
}

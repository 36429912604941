import React, { Component } from "react";

import Backend from "../../../../utils/Backend";
import Notify from "../../../../utils/Notify";
import CustomButton from "../../CustomButton";

export default class MinimumOrderSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [
        {
          key: "minimum_order_amount",
          value: null,
        },
      ],
    };
  }

  componentDidMount() {
    this._getMinimumOrderDetails();
  }

  _getMinimumOrderDetails() {
    this.setState({ isLoading: true });

    Backend.getMinimumOrderDetails()
      .then((data) => {
        this.setState({ data, isLoading: false });
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  _handleChange(e) {
    let { data } = this.state;

    data[e.target.name] = e.target.value;
    this.setState({ data });
  }

  _isFormValid() {
    const { data } = this.state;

    const { value } = data;

    let error = null;

    if (!value || value < 0 || value < 50) {
      error = "Minimum order amount cannot be less than 50 cents.";
    }

    if (error) {
      Notify.error(error);
      return false;
    }

    return true;
  }

  _updateMinimumOrder() {
    let { data } = this.state;

    if (!this._isFormValid()) {
      return;
    }

    this.setState({ isLoading: true });

    Backend.updateMinimumOrder(data)
      .then(() => {
        this._getMinimumOrderDetails();
        Notify.success("Minimum order amount has been updated successfully.");
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { data, isLoading } = this.state;

    return (
      <div className="wizard-body py-8 px-8 py-lg-20 px-lg-10 ml-20 mt-2">
        <div className="row">
          <div className="offset-xxl-2 col-xxl-12">
            <form
              className="form fv-plugins-bootstrap fv-plugins-framework"
              id="kt_form"
            >
              <div className="pb-5" data-wizard-type="step-content">
                <h4 className="mb-10 font-weight-bold text-dark">
                  Control Your Minimum Order Details
                </h4>
                <div className="form-group row">
                  <label className="col-4 col-form-label">Enter Value</label>
                  <div className="col-8 mb-4">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="0.00"
                      name="value"
                      value={data?.value ? data?.value / 100 : null}
                      onChange={(e) => {
                        data.value = e.target.value
                          ? Math.round(
                              parseFloat(e.target.value).toFixed(2) * 100
                            )
                          : null;

                        this.setState({ data });
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between border-top mt-5 pt-10">
                  <div style={{ margin: "0 auto" }}>
                    <CustomButton
                      buttonClassName="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                      title="Save Changes"
                      disabled={isLoading}
                      onClick={(e) => {
                        e.preventDefault();
                        this._updateMinimumOrder();
                      }}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

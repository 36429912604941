import React, { Component } from "react";

import StoresDefaultCard from "./StoresDefaultCard";

export default class StoresCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      store: props.store,
      vendorId: props.vendorId,
      onUpdated: props.onUpdated
    };
  }

  render() {
    const { store, vendorId, onUpdated} = this.state;


    return (
      <>
        <StoresDefaultCard store={store} vendorId={vendorId} onUpdated={onUpdated} />
      </>
    );
  }
}

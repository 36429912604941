import React, { Component } from "react";

import Backend from "../utils/Backend";
import General from "../utils/General";
import Notify from "../utils/Notify";
import Currency from "../utils/Currency";

import VendorDetailsModal from "./components/modals/VendorDetailsModal";
import StatsCard from "./components/stats-cards/StatsCard";
import StoresCards from "./components/stores/StoresCards";

import PasscodeModal from "./components/modals/PasscodeModal";

export default class VendorDetails extends Component {
  constructor(props) {
    super(props);

    this.state = { data: null, searchTerm: "" };
  }

  componentDidMount() {
    this._getVendorDetails();
  }

  _getVendorDetails() {
    let vendorId = this.props.match.params.vendorId;

    this.setState({ isLoading: true });

    Backend.getVendorDetails(vendorId)
      .then((data) => {
        this.setState({ data, isLoading: false });
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  _updateSearchTerm = General.debounce(
    (searchTerm) => {
      this.setState({
        searchTerm,
      });
    },
    1000,
    false
  );

  render() {
    const { data, searchTerm, showVendorDetailsModal, showPasscodeModal } =
      this.state;

    if (!data) {
      return null;
    }

    let vendorId = data?.id;

    let averageDeliveryTimeSeconds = data?.average_delivery_time * 60;

    return (
      <>
        <div className="container">
          <div className="card card-custom gutter-b">
            <div className="card-body">
              <div className="d-flex">
                <div className="flex-shrink-0 mr-7">
                  <div className="symbol symbol-80 bg-secondary symbol-lg-100">
                    {data?.logo?.original ? (
                      <img
                        alt="Pic"
                        src={data?.logo?.original}
                        style={{ objectFit: "contain" }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="flex-grow-1">
                  <div className="d-flex align-items-center justify-content-between flex-wrap">
                    <div className="mr-3">
                      <div className="d-flex align-items-center mr-3">
                        <a
                          href="javascript:;"
                          className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3"
                        >
                          {data?.title}
                        </a>
                        <a
                          href="javascript:;"
                          className="btn btn-icon btn-sm btn-pill btn-outline-primary ml-2"
                          data-toggle="popover"
                          data-content="Show Passcode"
                          onClick={() =>
                            this.setState({
                              showPasscodeModal: true,
                            })
                          }
                        >
                          <span className="svg-icon svg-icon-md">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                strokeWidth={1}
                                fill="none"
                                fillRule="evenodd"
                              >
                                <rect x={0} y={0} width={24} height={24} />
                                <path
                                  d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z"
                                  fill="#000000"
                                  opacity="0.3"
                                />
                                <path
                                  d="M14.5,11 C15.0522847,11 15.5,11.4477153 15.5,12 L15.5,15 C15.5,15.5522847 15.0522847,16 14.5,16 L9.5,16 C8.94771525,16 8.5,15.5522847 8.5,15 L8.5,12 C8.5,11.4477153 8.94771525,11 9.5,11 L9.5,10.5 C9.5,9.11928813 10.6192881,8 12,8 C13.3807119,8 14.5,9.11928813 14.5,10.5 L14.5,11 Z M12,9 C11.1715729,9 10.5,9.67157288 10.5,10.5 L10.5,11 L13.5,11 L13.5,10.5 C13.5,9.67157288 12.8284271,9 12,9 Z"
                                  fill="#000000"
                                />
                              </g>
                            </svg>
                          </span>
                        </a>
                      </div>
                      <div className="d-flex flex-wrap my-2">
                        <a
                          href="javascript:;"
                          className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                        >
                          <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                strokeWidth={1}
                                fill="none"
                                fillRule="evenodd"
                              >
                                <rect x={0} y={0} width={24} height={24} />
                                <path
                                  d="M5,10.5 C5,6 8,3 12.5,3 C17,3 20,6.75 20,10.5 C20,12.8325623 17.8236613,16.03566 13.470984,20.1092932 C12.9154018,20.6292577 12.0585054,20.6508331 11.4774555,20.1594925 C7.15915182,16.5078313 5,13.2880005 5,10.5 Z M12.5,12 C13.8807119,12 15,10.8807119 15,9.5 C15,8.11928813 13.8807119,7 12.5,7 C11.1192881,7 10,8.11928813 10,9.5 C10,10.8807119 11.1192881,12 12.5,12 Z"
                                  fill="#000000"
                                  fillRule="nonzero"
                                />
                              </g>
                            </svg>
                          </span>
                          {data?.eircode}
                        </a>
                      </div>
                    </div>

                    <div className="mb-10">
                      <a
                        href="javascript:;"
                        className="btn btn-primary btn-sm font-weight-bolder text-uppercase mb-5"
                        onClick={() =>
                          this.setState({
                            showVendorDetailsModal: true,
                          })
                        }
                      >
                        Edit
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row wd-100">
            <div className="col-lg-4">
              {" "}
              <StatsCard
                data={[
                  {
                    statsInfo: `${Currency.format(data?.total_earnings)}`,
                    title: "Total Revenue",
                    icon: (
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect x={0} y={0} width={24} height={24} />
                        <path
                          d="M4.3618034,10.2763932 L4.8618034,9.2763932 C4.94649941,9.10700119 5.11963097,9 5.30901699,9 L15.190983,9 C15.4671254,9 15.690983,9.22385763 15.690983,9.5 C15.690983,9.57762255 15.6729105,9.65417908 15.6381966,9.7236068 L15.1381966,10.7236068 C15.0535006,10.8929988 14.880369,11 14.690983,11 L4.80901699,11 C4.53287462,11 4.30901699,10.7761424 4.30901699,10.5 C4.30901699,10.4223775 4.32708954,10.3458209 4.3618034,10.2763932 Z M14.6381966,13.7236068 L14.1381966,14.7236068 C14.0535006,14.8929988 13.880369,15 13.690983,15 L4.80901699,15 C4.53287462,15 4.30901699,14.7761424 4.30901699,14.5 C4.30901699,14.4223775 4.32708954,14.3458209 4.3618034,14.2763932 L4.8618034,13.2763932 C4.94649941,13.1070012 5.11963097,13 5.30901699,13 L14.190983,13 C14.4671254,13 14.690983,13.2238576 14.690983,13.5 C14.690983,13.5776225 14.6729105,13.6541791 14.6381966,13.7236068 Z"
                          fill="#000000"
                          opacity="0.3"
                        />
                        <path
                          d="M17.369,7.618 C16.976998,7.08599734 16.4660031,6.69750122 15.836,6.4525 C15.2059968,6.20749878 14.590003,6.085 13.988,6.085 C13.2179962,6.085 12.5180032,6.2249986 11.888,6.505 C11.2579969,6.7850014 10.7155023,7.16999755 10.2605,7.66 C9.80549773,8.15000245 9.45550123,8.72399671 9.2105,9.382 C8.96549878,10.0400033 8.843,10.7539961 8.843,11.524 C8.843,12.3360041 8.96199881,13.0779966 9.2,13.75 C9.43800119,14.4220034 9.7774978,14.9994976 10.2185,15.4825 C10.6595022,15.9655024 11.1879969,16.3399987 11.804,16.606 C12.4200031,16.8720013 13.1129962,17.005 13.883,17.005 C14.681004,17.005 15.3879969,16.8475016 16.004,16.5325 C16.6200031,16.2174984 17.1169981,15.8010026 17.495,15.283 L19.616,16.774 C18.9579967,17.6000041 18.1530048,18.2404977 17.201,18.6955 C16.2489952,19.1505023 15.1360064,19.378 13.862,19.378 C12.6999942,19.378 11.6325049,19.1855019 10.6595,18.8005 C9.68649514,18.4154981 8.8500035,17.8765035 8.15,17.1835 C7.4499965,16.4904965 6.90400196,15.6645048 6.512,14.7055 C6.11999804,13.7464952 5.924,12.6860058 5.924,11.524 C5.924,10.333994 6.13049794,9.25950479 6.5435,8.3005 C6.95650207,7.34149521 7.5234964,6.52600336 8.2445,5.854 C8.96550361,5.18199664 9.8159951,4.66400182 10.796,4.3 C11.7760049,3.93599818 12.8399943,3.754 13.988,3.754 C14.4640024,3.754 14.9609974,3.79949954 15.479,3.8905 C15.9970026,3.98150045 16.4939976,4.12149906 16.97,4.3105 C17.4460024,4.49950095 17.8939979,4.7339986 18.314,5.014 C18.7340021,5.2940014 19.0909985,5.62999804 19.385,6.022 L17.369,7.618 Z"
                          fill="#000000"
                        />
                      </g>
                    ),
                  },
                ]}
              />{" "}
            </div>
            <div className="col-lg-4">
              {" "}
              <StatsCard
                data={[
                  {
                    statsInfo: `${data?.sales_count}`,
                    title: "All Orders",
                    icon: (
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect x={0} y={0} width={24} height={24} />
                        <path
                          d="M22,17 L22,21 C22,22.1045695 21.1045695,23 20,23 L4,23 C2.8954305,23 2,22.1045695 2,21 L2,17 L6.27924078,17 L6.82339262,18.6324555 C7.09562072,19.4491398 7.8598984,20 8.72075922,20 L15.381966,20 C16.1395101,20 16.8320364,19.5719952 17.1708204,18.8944272 L18.118034,17 L22,17 Z"
                          fill="#000000"
                        />
                        <path
                          d="M2.5625,15 L5.92654389,9.01947752 C6.2807805,8.38972356 6.94714834,8 7.66969497,8 L16.330305,8 C17.0528517,8 17.7192195,8.38972356 18.0734561,9.01947752 L21.4375,15 L18.118034,15 C17.3604899,15 16.6679636,15.4280048 16.3291796,16.1055728 L15.381966,18 L8.72075922,18 L8.17660738,16.3675445 C7.90437928,15.5508602 7.1401016,15 6.27924078,15 L2.5625,15 Z"
                          fill="#000000"
                          opacity="0.3"
                        />
                        <path
                          d="M11.1288761,0.733697713 L11.1288761,2.69017121 L9.12120481,2.69017121 C8.84506244,2.69017121 8.62120481,2.91402884 8.62120481,3.19017121 L8.62120481,4.21346991 C8.62120481,4.48961229 8.84506244,4.71346991 9.12120481,4.71346991 L11.1288761,4.71346991 L11.1288761,6.66994341 C11.1288761,6.94608579 11.3527337,7.16994341 11.6288761,7.16994341 C11.7471877,7.16994341 11.8616664,7.12798964 11.951961,7.05154023 L15.4576222,4.08341738 C15.6683723,3.90498251 15.6945689,3.58948575 15.5161341,3.37873564 C15.4982803,3.35764848 15.4787093,3.33807751 15.4576222,3.32022374 L11.951961,0.352100892 C11.7412109,0.173666017 11.4257142,0.199862688 11.2472793,0.410612793 C11.1708299,0.500907473 11.1288761,0.615386087 11.1288761,0.733697713 Z"
                          fill="#000000"
                          fillRule="nonzero"
                          transform="translate(11.959697, 3.661508) rotate(-270.000000) translate(-11.959697, -3.661508) "
                        />
                      </g>
                    ),
                  },
                ]}
              />{" "}
            </div>
            <div className="col-lg-4">
              {" "}
              <StatsCard
                data={[
                  {
                    statsInfo: `${General.averageDeliveryTime(
                      averageDeliveryTimeSeconds
                    )}`,
                    title: "Avg. Delivery Time",
                    icon: (
                      <g
                        stroke="none"
                        strokeWidth={1}
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect x={0} y={0} width={24} height={24} />
                        <path
                          d="M5.94290508,4 L18.0570949,4 C18.5865712,4 19.0242774,4.41271535 19.0553693,4.94127798 L19.8754445,18.882556 C19.940307,19.9852194 19.0990032,20.9316862 17.9963398,20.9965487 C17.957234,20.9988491 17.9180691,21 17.8788957,21 L6.12110428,21 C5.01653478,21 4.12110428,20.1045695 4.12110428,19 C4.12110428,18.9608266 4.12225519,18.9216617 4.12455553,18.882556 L4.94463071,4.94127798 C4.97572263,4.41271535 5.41342877,4 5.94290508,4 Z"
                          fill="#000000"
                          opacity="0.3"
                        />
                        <path
                          d="M7,7 L9,7 C9,8.65685425 10.3431458,10 12,10 C13.6568542,10 15,8.65685425 15,7 L17,7 C17,9.76142375 14.7614237,12 12,12 C9.23857625,12 7,9.76142375 7,7 Z"
                          fill="#000000"
                        />
                      </g>
                    ),
                  },
                ]}
              />{" "}
            </div>
          </div>
          <div className="card card-custom gutter-b">
            <div className="card-body">
              <ul className="nav nav-tabs nav-tabs-line">
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#kt_tab_pane_2"
                  >
                    Stores
                  </a>
                </li>
              </ul>

              <div className="tab-content mt-5" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="kt_tab_pane_2"
                  role="tabpanel"
                  aria-labelledby="kt_tab_pane_2"
                >
                  <div className="card card-custom gutter-b">
                    <div className="card-header">
                      <div className="card-title">
                        <span className="card-icon">
                          <span className="svg-icon svg-icon-primary svg-icon-2x">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                strokeWidth={1}
                                fill="none"
                                fillRule="evenodd"
                              >
                                <polygon points="0 0 24 0 24 24 0 24" />
                                <path
                                  d="M9.26193932,16.6476484 C8.90425297,17.0684559 8.27315905,17.1196257 7.85235158,16.7619393 C7.43154411,16.404253 7.38037434,15.773159 7.73806068,15.3523516 L16.2380607,5.35235158 C16.6013618,4.92493855 17.2451015,4.87991302 17.6643638,5.25259068 L22.1643638,9.25259068 C22.5771466,9.6195087 22.6143273,10.2515811 22.2474093,10.6643638 C21.8804913,11.0771466 21.2484189,11.1143273 20.8356362,10.7474093 L17.0997854,7.42665306 L9.26193932,16.6476484 Z"
                                  fill="#000000"
                                  fillRule="nonzero"
                                  opacity="0.3"
                                  transform="translate(14.999995, 11.000002) rotate(-180.000000) translate(-14.999995, -11.000002) "
                                />
                                <path
                                  d="M4.26193932,17.6476484 C3.90425297,18.0684559 3.27315905,18.1196257 2.85235158,17.7619393 C2.43154411,17.404253 2.38037434,16.773159 2.73806068,16.3523516 L11.2380607,6.35235158 C11.6013618,5.92493855 12.2451015,5.87991302 12.6643638,6.25259068 L17.1643638,10.2525907 C17.5771466,10.6195087 17.6143273,11.2515811 17.2474093,11.6643638 C16.8804913,12.0771466 16.2484189,12.1143273 15.8356362,11.7474093 L12.0997854,8.42665306 L4.26193932,17.6476484 Z"
                                  fill="#000000"
                                  fillRule="nonzero"
                                  transform="translate(9.999995, 12.000002) rotate(-180.000000) translate(-9.999995, -12.000002) "
                                />
                              </g>
                            </svg>
                          </span>
                        </span>
                        <h3 className="card-label">All Stores</h3>
                      </div>
                      <div className="card-toolbar w-xs-100">
                        <div className="input-icon mr-3 w-xs-100">
                          <input
                            type="text"
                            id="kt_datatable_search_query"
                            className="form-control"
                            placeholder="Search Stores..."
                            onChange={(e) =>
                              this._updateSearchTerm(e.target.value)
                            }
                          />
                          <span>
                            <i className="flaticon2-search-1 text-muted" />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <StoresCards
                          vendorId={vendorId}
                          searchTerm={searchTerm}
                        />
                        <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                          <a href={`/manage-vendors/${vendorId}/add-store`}></a>
                          <div className="card card-custom border-dashed gutter-b card-stretch">
                            <div className="card-body text-center pt-4">
                              <a href={`/manage-vendors/${vendorId}/add-store`}>
                                <div className="mt-7">
                                  <div className="symbol symbol-circle mt-10">
                                    <i
                                      className="flaticon2-plus-1"
                                      style={{ fontSize: 60 }}
                                    />
                                  </div>
                                </div>
                              </a>
                              <div className="mt-9 mb-6">
                                <a
                                  href={`/manage-vendors/${vendorId}/add-store`}
                                  className="btn btn-clean btn-rounded w-100 mb-3 font-weight-bolder"
                                  style={{ fontSize: 25 }}
                                >
                                  Add A Store
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <VendorDetailsModal
          show={showVendorDetailsModal}
          data={data}
          onUpdated={() => {
            this._getVendorDetails();
            this.setState({
              showVendorDetailsModal: false,
            });
          }}
          onHide={() =>
            this.setState({
              showVendorDetailsModal: false,
            })
          }
        />

          <PasscodeModal
            show={showPasscodeModal}
            vendorId={vendorId}
            onHide={() =>
              this.setState({
                showPasscodeModal: false,
              })
            }
          />
      </>
    );
  }
}

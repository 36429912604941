import FetchHelper from "./FetchHelper";

import AuthManager from "./AuthManager";

import moment from "moment";

export default class Backend {
  static addDriver(driver) {
    let data = { ...driver };

    return FetchHelper.post(`${window.Api.Drivers}`, {
      user: driver.user,
      ...data,
    });
  }

  static updateDriver(driver) {
    let data = { ...driver };

    return FetchHelper.patch(`${window.Api.Drivers}/${driver.user.id}`, data);
  }

  static updateDriverDetails(driver) {
    let data = { ...driver };

    return FetchHelper.patch(`${window.Api.Drivers}/${driver.user.id}`, {
      user: data.user,
    });
  }

  static getDriverDetails(driverId) {
    return FetchHelper.get(`${window.Api.Drivers}/${driverId}`);
  }

  static getDrivers() {
    return FetchHelper.get(`${window.Api.Drivers}`);
  }

  static pauseDriver(driver) {
    return FetchHelper.delete(`${window.Api.Drivers}/${driver.id}`);
  }

  static addVenue(venue) {
    let data = { ...venue };
    return FetchHelper.post(`${window.Api.Venues}`, data);
  }

  static getVenue(venueId) {
    return FetchHelper.get(`${window.Api.Venues}/${venueId}`);
  }

  static getVenueDetails(venueId) {
    return FetchHelper.get(`${window.Api.Venues}/${venueId}`);
  }

  static editVenue(venue) {
    let data = { ...venue };

    return FetchHelper.patch(`${window.Api.Venues}/${venue.id}`, data);
  }

  static updateVenue(venue) {
    let data = { ...venue };

    return FetchHelper.patch(`${window.Api.Venues}/${venue.id}`, data);
  }

  static testOrder(venue) {
    // This mocks data, but it is actually overwritten on the server side anyway
    // Prepare the items array based on what is expected on the server
    const items = [
      {
          id: 1,
          menu_item_id: 1,
          quantity: 1,
          price: 1200
      },
      {
          id: 2,
          menu_item_id: 2,
          quantity: 1,
          price: 2000
      }
    ];

    const body = {
        venue: venue.id,
        menu: venue.id,
        payment: {
            card: "1", // Mock card ID, can be dynamic if needed
            expected_price: items.reduce((total, item) => total + item.price, 0),
            tip: 0  // Adjust if you need a different value for the tip
        },
        items: items,
        instructions: "Test order instructions",
        address: 3,  // Optional, replace if needed
        //customer: 7,  // set on backend
        payment_intent_id: "mock_stripe_charge_id" // This implies the second call after payment completed
    };

    return FetchHelper.post(`${window.Api.TestOrder}`, body);
}

  static pauseVenue(venue) {
    return FetchHelper.patch(`${window.Api.Venues}/${venue.id}`, {
      paused: true,
    });
  }

  static unpauseVenue(venue) {
    return FetchHelper.patch(`${window.Api.Venues}/${venue.id}`, {
      paused: false,
    });
  }

  static getVendorDetails(vendorId) {
    return FetchHelper.get(`${window.Api.Companies}/${vendorId}`);
  }

  static getPasscode(vendorId) {
    return FetchHelper.get(`${window.Api.Companies}/${vendorId}?passcode=true`);
  }

  static updateVendor(vendor) {
    let data = { ...vendor };

    return FetchHelper.patch(`${window.Api.Companies}/${vendor.id}`, data);
  }

  static pauseVendor(vendor) {
    return FetchHelper.patch(`${window.Api.Companies}/${vendor.id}`, {
      status: "paused",
    });
  }

  static unpauseVendor(vendor) {
    return FetchHelper.patch(`${window.Api.Companies}/${vendor.id}`, {
      status: "active",
    });
  }

  static addCompanyDetails(company) {
    let data = { ...company };

    return FetchHelper.post(`${window.Api.Companies}`, {
      logo: data.logo.id,
      featured_image: data.featured_image.id,
    });
  }

  static updateCompanyDetails(company) {
    let data = { ...company };

    return FetchHelper.patch(`${window.Api.Companies}/${company.id}`, {
      logo: data.logo.id,
      featured_image: data.featured_image.id,
    });
  }

  static addCompany(company) {
    let data = { ...company };

    return FetchHelper.post(`${window.Api.Companies}`, data);
  }

  static getCompanyMembers(companyId) {
    return FetchHelper.get(
      `${window.Api.CompanyMembers}?company_id=${companyId}&paginated=false`
    ).then((response) => response.results);
  }

  static getCompanyMember(companyMember) {
    return FetchHelper.get(
      `${window.Api.CompanyMembers}`,
      companyMember.user.id
    );
  }

  static addCompanyMember(company) {
    let data = { ...company };

    return FetchHelper.post(
      `${window.Api.CompanyMembers}?company_id=${data.company}`,
      data
    );
  }

  static editCompany(company) {
    let data = { ...company };

    return FetchHelper.patch(`${window.Api.Companies}/${company.id}`, data);
  }

  static getStaff(companyId, venueId) {
    return FetchHelper.get(
      `${window.Api.Staff}?company_id=${companyId}&venue_id=${venueId}`
    ).then((response) => response.results);
  }

  static addStaff(companyMemberId, venueId) {
    let data = { company_member: companyMemberId, venue: venueId };

    return FetchHelper.post(`${window.Api.Staff}`, data);
  }

  static removeStaff(companyMemberId, venueId) {
    let data = { company_member_id: companyMemberId, venue_id: venueId };

    return FetchHelper.delete(`${window.Api.Staff}`, data);
  }

  static getCategories() {
    return FetchHelper.get(`${window.Api.Categories}?has_parent=false`).then(
      (response) => response.results
    );
  }

  static getMenuCategories(menu) {
    return FetchHelper.get(`${window.Api.MenuCategories}/${menu}`).then(
      (response) => response.results
    );
  }

  static addProductCategory(category) {
    let data = { ...category };
    return FetchHelper.post(`${window.Api.Categories}`, data);
  }

  static deleteCategory(id) {
    return FetchHelper.delete(`${window.Api.Categories}/${id}`);
  }

  static editProductCategory(category) {
    let data = { ...category };
    return FetchHelper.patch(`${window.Api.Categories}/${category.id}`, data);
  }

  static deleteSubCategory(id) {
    return FetchHelper.delete(`${window.Api.Categories}/${id}`);
  }

  static addMenuCategory(category) {
    let data = { ...category };
    return FetchHelper.post(`${window.Api.MenuCategories}`, data);
  }

  static getMenu(menu) {
    return FetchHelper.get(`${window.Api.Menus}/${menu}`);
  }

  static addMenu(menu) {
    let data = { ...menu };
    return FetchHelper.post(`${window.Api.Menus}`, data);
  }

  static editMenu(menu) {
    let data = { menu };
    return FetchHelper.patch(`${window.Api.Menus}/${menu}`, data);
  }

  static addMenuItem(item) {
    let data = { ...item };

    return FetchHelper.post(`${window.Api.MenuItems}`, {
      item: data.item,
      menu: data.menu,
      ...data,
    });
  }

  static editMenuItem(menuItem) {
    let data = { ...menuItem };
    return FetchHelper.patch(`${window.Api.MenuItems}/${menuItem.id}`, data);
  }

  static removeMenuItem(menuItemId) {
    return FetchHelper.delete(`${window.Api.MenuItems}/${menuItemId}`);
  }

  static addItem(item) {
    let data = { ...item };

    return FetchHelper.post(`${window.Api.Items}`, data);
  }

  static getItem(id) {
    return FetchHelper.get(`${window.Api.Items}/${id}`);
  }

  static editItem(item) {
    let data = { ...item };
    return FetchHelper.patch(`${window.Api.Items}/${item.id}`, data);
  }

  static removeItem(itemId) {
    return FetchHelper.delete(`${window.Api.Items}/${itemId}`);
  }

  static getCustomerDetails(customerId) {
    return FetchHelper.get(`${window.Api.Customers}/${customerId}`);
  }

  static getOverviewStats() {
    return FetchHelper.get(
      `${window.Api.AllTimeStats}?types=total_earnings,sales_count`
    );
  }

  static getTotalEarnings() {
    return FetchHelper.get(`${window.Api.AllTimeStats}?types=total_earnings`);
  }

  static getDailyStats() {
    let maxDate = moment().endOf("isoWeek").format("YYYY-MM-DD");
    let minDate = moment().startOf("isoWeek").format("YYYY-MM-DD");

    return FetchHelper.get(
      `${window.Api.DailyStats}?max_date=${maxDate}&min_date=${minDate}&total=true`
    );
  }

  static getMonthlyStats() {
    let maxDate = moment().endOf("month").format("YYYY-MM-DD");
    let minDate = moment().startOf("month").format("YYYY-MM-DD");

    return FetchHelper.get(
      `${window.Api.DailyStats}?max_date=${maxDate}&min_date=${minDate}&total=true`
    );
  }

  static getVendorsStats() {
    return FetchHelper.get(
      `${window.Api.AllTimeStats}?types=company_count,venue_count`
    );
  }

  static getDriversStats() {
    return FetchHelper.get(
      `${window.Api.AllTimeStats}?types=driver_count,delivered_order_count`
    );
  }

  static getCustomersStats() {
    return FetchHelper.get(
      `${window.Api.AllTimeStats}?types=customer_count,total_earnings,sales_count`
    );
  }

  static getOrderHistoryStats() {
    return FetchHelper.get(
      `${window.Api.AllTimeStats}?types=delivered_order_count,rejected_order_count,average_wait_time`
    );
  }

  static getCancelledOrdersStats() {
    return FetchHelper.get(
      `${window.Api.AllTimeStats}?types=delivered_order_count,rejected_order_count,average_wait_time`
    );
  }

  static getPaymentsHistoryStats() {
    return FetchHelper.get(
      `${window.Api.AllTimeStats}?types=platform_earnings,total_earnings,sales_count`
    );
  }

  static getGraphStats() {
    return FetchHelper.get(
      `${
        window.Api.DailyStats
      }?types=total_earnings,sales_count&starts_at=${moment()
        .startOf("week")
        .toISOString()}&ends_at=${moment().endOf("week").toISOString()}`
    );
  }

  static getAdmins() {
    return FetchHelper.get(`${window.Api.Admins}`).then(
      (response) => response.results
    );
  }

  static addAdmin(admin) {
    let data = { ...admin };
    return FetchHelper.post(`${window.Api.Admins}`, data);
  }

  static updateAdmin(admin) {
    let data = { ...admin };
    return FetchHelper.patch(`${window.Api.Admins}/${admin.user.id}`, data);
  }

  static removeAdmin(adminId) {
    return FetchHelper.delete(`${window.Api.Admins}/${adminId}`);
  }

  static getAgeDetails() {
    return FetchHelper.get(`${window.Api.Settings}?key=age_limit`).then(
      (response) => response.results[1]
    );
  }

  static updateAge(age) {
    let data = { ...age };
    return FetchHelper.post(`${window.Api.Settings}`, data);
  }

  static getMinimumOrderDetails() {
    return FetchHelper.get(
      `${window.Api.Settings}?key=minimum_order_amount`
    ).then((response) => response.results[0]);
  }

  static updateMinimumOrder(minimumOrder) {
    let data = { ...minimumOrder };
    return FetchHelper.post(`${window.Api.Settings}`, data);
  }

  static updateAccount(admin) {
    let data = { ...admin };
    return FetchHelper.patch(`${window.Api.Admins}/${admin.user.id}`, data);
  }

  static updateOrderDriver(id) {
    return FetchHelper.patch(`${window.Api.Orders}/${id}`, {
      driver: id,
    });
  }

  // For driver accepting/rejecting delivery requests
  static updateDeliveryRequest(id, data) {
    return FetchHelper.patch(`${window.Api.Orders}/${id}`, data);
  }

  static cancelOrder(orderId) {
    return FetchHelper.patch(`${window.Api.Orders}/${orderId}`, {
      status: "rejected",
    });
  }

  static completeOrder(orderId) {
    return FetchHelper.patch(`${window.Api.Orders}/${orderId}`, {
      delivery_status: "delivered",
      identification_status: "provided",
      identification: "123456789",
    });
  }

  static getDeliveryDistances() {
    return FetchHelper.get(`${window.Api.DeliveryDistances}`).then(
      (response) => response.results
    );
  }

  static createDeliveryDistances(deliveryDistances) {
    return FetchHelper.post(`${window.Api.DeliveryDistances}`, {
      delivery_distances: [...deliveryDistances],
    });
  }

  static uploadImage(image, type = "favicon") {
    let data = new FormData();

    data.append("file", image, image.name);
    data.append("type", type);

    return FetchHelper.post(window.Api.Images, data, true);
  }

  static uploadFile(file, endpoint, params) {
    let data = new FormData();

    data.append("csv", file, file.name);
    for (var key in params) {
      data.append(key, params[key]);
    }
    return FetchHelper.post(endpoint, data, true);
  }
}

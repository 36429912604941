import React from "react";

import Div from "../layouts/Div";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";

export default class FileUpload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: props.file,
      params: props.params,
      endpoint: props.endpoint,
      method: props.method,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _onFileChange(e) {
    let { endpoint, params, method } = this.state;

    var files = e.target.files;
    var filesArr = Array.prototype.slice.call(files);
    let file = filesArr[0];
    if (file == null) {
      return;
    }

    this.setState({ loading: true });

    Backend.uploadFile(file, endpoint, params, method)
      .then((file) => {
        this.setState({
          file,
          loading: false,
        });
        this.props.onUploaded(file);
        Notify.success("File has been uploaded successfully.");
      })
      .catch((error) => {
        this.setState({ loading: false });
        Notify.error(error.message, 10000);
      });
  }

  render() {
    let { file, loading } = this.state;

    let label = file ? file?.name : "Choose a file";
    let isLoading = loading && "Loading...";

    return (
      <Div disabled={this.state.loading}>
        <div className="input-group">
          <div className="custom-file">
            <input
              type="file"
              className="custom-file-input"
              accept={this.props.accept}
              onChange={(e) => this._onFileChange(e)}
            />
            <label className="custom-file-label">{label}</label>
          </div>
        </div>
        <div className="mt-3">
          {" "}
          <span>{isLoading}</span>
        </div>
      </Div>
    );
  }
}

FileUpload.defaultProps = {
  method: "POST",
};

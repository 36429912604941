import React from "react";

import moment from "moment";

import { withRouter } from "react-router-dom";

import LocationFormat from "../../../../utils/LocationFormat";
import BaseTable from "../BaseTable";

class DriverTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.table = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _renderAddress(address) {
    return (
      <span className="text-dark-75 font-weight-bold line-height-sm">
        {address ? LocationFormat.fullAddress(address) : "-"}
      </span>
    );
  }

  _getColumns() {
    return [
      {
        Header: () => <strong className="header__clr">ORDER NO.</strong>,
        id: "id",
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          let orderId = order.id;

          if (!orderId) {
            return "-";
          }

          return (
            <>
              <span>{orderId}</span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">DELIVERY ADDRESS</strong>,
        id: "address",
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          let customerAddress = order.data.customer_address;

          return <span>{this._renderAddress(customerAddress)}</span>;
        },
      },
      {
        Header: () => <strong className="header__clr">ID CHECKED</strong>,
        id: "identification_status",
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          let labelClass = "";
          let identificationStatus = "";

          if (order.identification_status === "provided") {
            labelClass = "label-light-success";
            identificationStatus = "YES";
          } else {
            labelClass = "label-light-danger";
            identificationStatus = "NO";
          }

          if (!identificationStatus) {
            return "-";
          }

          return (
            <>
              <span
                className={`label w-100 font-weight-bold label-lg  ${labelClass} label-inline`}
              >
                {identificationStatus}
              </span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">ITEMS NUMBER</strong>,
        id: "items",
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          let items = order.data.items.length;

          if (!items || items === []) {
            return "-";
          }

          return (
            <>
              <span>{items}</span>
            </>
          );
        },
      },
      {
        Header: () => (
          <strong className="header__clr">ORDER DATE / TIME</strong>
        ),
        id: "created_at",
        Cell: (rowInfo) => {
          let order = rowInfo.original;
          let createdAt = `${moment(order?.created_at).format(
            "DD MMM YYYY - hh:mm A"
          )}`;

          if (!order?.created_at) {
            return "-";
          }

          return (
            <>
              <span className="text-dark-75 font-weight-bold line-height-sm">
                {createdAt}
              </span>
            </>
          );
        },
      },
    ];
  }

  render() {
    const { isLoading } = this.state;

    const columns = this._getColumns();

    let tableProps = {};

    return (
      <>
        <BaseTable
          {...tableProps}
          ref={this.table}
          title={this.props.title}
          loading={isLoading}
          endpoint={`${window.Api.Orders}?driver_id=${this.props.driverId}`}
          noDataMessage={"No orders found"}
          columns={columns}
          showPagination={true}
          defaultSorted={[
            {
              id: "created_at",
              desc: true,
            },
          ]}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "Actions" ? "visible" : "hidden",
              },
            };
          }}
        />
      </>
    );
  }
}

DriverTable.defaultProps = {
  title: "",
  showPagination: true,
  showPaginationTop: true,
  showPaginationBottom: true,
};

export default withRouter(DriverTable);

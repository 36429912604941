import React, { Component } from "react";

import Backend from "../utils/Backend";
import Notify from "../utils/Notify";

export default class DeliverySettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      delivery_distances: [],
    };
  }

  componentDidMount() {
    this._getDeliveryDistances();
  }

  _getDeliveryDistances() {
    this.setState({
      isLoading: true,
    });

    Backend.getDeliveryDistances()
      .then((delivery_distances) => {
        this.setState({ delivery_distances, isLoading: false });
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  }

  _isFormValid() {
    const { delivery_distances } = this.state;

    let isFromEqualToEndDistance = delivery_distances.filter(
      (deliveryDistance, index) =>
        delivery_distances[index].starts == delivery_distances[index].ends
    );

    let isFromBiggerThanEndDistance = delivery_distances.filter(
      (deliveryDistance, index) =>
        parseInt(deliveryDistance.starts) > parseInt(deliveryDistance.ends)
    );

    let toDistance = delivery_distances?.every(
      (deliveryDistance, index) => deliveryDistance.ends
    );

    let feeDistance = delivery_distances?.every(
      (deliveryDistance, index) => deliveryDistance.fee
    );

    let driverFee = delivery_distances?.every(
      (deliveryDistance, index) => deliveryDistance.driver_fee
    );

    let isDriverFeeBiggerThanFee = delivery_distances.filter(
      (deliveryDistance, index) =>
        parseInt(deliveryDistance.driver_fee) > parseInt(deliveryDistance.fee)
    );

    let error = null;

    if (!toDistance) {
      error = "Please enter a valid ending point.";
    } else if (!feeDistance) {
      error = "Please enter a valid fee.";
    } else if (!driverFee) {
      error = "Please enter a valid driver fee.";
    } else if (
      isFromEqualToEndDistance &&
      isFromEqualToEndDistance.length !== 0
    ) {
      error = "Starting point cannot be equal to ending point.";
    } else if (
      isFromBiggerThanEndDistance &&
      isFromBiggerThanEndDistance.length !== 0
    ) {
      error = "Ending point cannot be smaller than starting point.";
    } else if (
      isDriverFeeBiggerThanFee &&
      isDriverFeeBiggerThanFee.length !== 0
    ) {
      error = "Driver fee cannot be bigger than fee.";
    }

    if (error) {
      Notify.error(error);
      return false;
    }

    return true;
  }

  _createDeliveryDistances() {
    let { delivery_distances } = this.state;

    if (!this._isFormValid()) {
      return;
    }

    if (delivery_distances.length == 1) {
      delivery_distances[0]["starts"] = 0;
    }

    this.setState({ isLoading: true });

    Backend.createDeliveryDistances(delivery_distances)
      .then(() => {
        this._getDeliveryDistances();
        Notify.success("Delivery distances have been updated successfully.");
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    let { delivery_distances, isLoading } = this.state;

    return (
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            <div className="card card-custom">
              <div className="card-header flex-wrap border-0 pt-6 pb-0">
                <div className="card-title">
                  <h3 className="card-label">Delivery Settings</h3>
                </div>
              </div>
              <form action="javascript:;">
                <div className="card-body">
                  <div>
                    <div className="form-group row">
                      <div className="col-lg-12">
                        {delivery_distances?.map((deliveryDistance, index) => {
                          let fromDistance =
                            index === 0 ? 0 : deliveryDistance?.starts;

                          return (
                            <>
                              <div
                                key={index}
                                className="form-group row align-items-center"
                              >
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>From</label>
                                    <div className="input-group">
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="starts"
                                        value={fromDistance}
                                        disabled={true}
                                      />
                                      <div className="input-group-append">
                                        <span className="input-group-text">
                                          KM
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>To</label>
                                    <div className="input-group">
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="ends"
                                        value={deliveryDistance?.ends}
                                        onChange={(e) => {
                                          let ends = parseInt(e.target.value);

                                          delivery_distances[index].ends = ends;

                                          if (
                                            delivery_distances[index + 1] &&
                                            delivery_distances.length >
                                              index - 1
                                          ) {
                                            delivery_distances[
                                              index + 1
                                            ].starts = ends;
                                          }

                                          this.setState({ delivery_distances });
                                        }}
                                      />
                                      <div className="input-group-append">
                                        <span className="input-group-text">
                                          KM
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>Delivery Fee *</label>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          €
                                        </span>
                                      </div>
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="fee"
                                        value={
                                          deliveryDistance?.fee
                                            ? deliveryDistance?.fee / 100
                                            : ""
                                        }
                                        onChange={(e) => {
                                          deliveryDistance.fee = parseInt(
                                            e.target.value
                                          )
                                            ? Math.round(
                                                parseFloat(
                                                  e.target.value
                                                ).toFixed(2) * 100
                                              )
                                            : null;

                                          this.setState({
                                            delivery_distances,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="form-group">
                                    <label>Driver Fee *</label>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <span className="input-group-text">
                                          €
                                        </span>
                                      </div>
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="driver_fee"
                                        value={
                                          deliveryDistance?.driver_fee
                                            ? deliveryDistance?.driver_fee / 100
                                            : ""
                                        }
                                        onChange={(e) => {
                                          deliveryDistance.driver_fee =
                                            parseInt(e.target.value)
                                              ? Math.round(
                                                  parseFloat(
                                                    e.target.value
                                                  ).toFixed(2) * 100
                                                )
                                              : null;

                                          this.setState({
                                            delivery_distances,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>

                                {index === delivery_distances.length - 1 && (
                                  <div className="col-md-6">
                                    <a
                                      href="javascript:;"
                                      className="btn btn-sm font-weight-bolder btn-light-danger w-100"
                                      onClick={() => {
                                        delivery_distances.splice(index, 1);
                                        this.setState({
                                          delivery_distances,
                                        });
                                      }}
                                    >
                                      <i className="la la-trash-o" />
                                      Delete
                                    </a>
                                  </div>
                                )}
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-6 col-form-label text-right" />
                      <div className="col-lg-6">
                        <a
                          href="javascript:;"
                          className="btn btn-sm font-weight-bolder btn-primary w-100 mt-minus-140"
                          onClick={(e) => {
                            let lastDeliveryDistance =
                              delivery_distances[delivery_distances.length - 1];

                            let starts = lastDeliveryDistance?.ends;

                            let ends = starts;

                            delivery_distances.push({
                              starts,
                              ends,
                            });

                            this.setState({
                              delivery_distances,
                            });
                          }}
                        >
                          <i className="la la-plus" />
                          Add Another Delivery Option
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button
                    type="submit"
                    className="btn btn-primary mr-2"
                    disabled={isLoading}
                    onClick={(e) => {
                      e.preventDefault();
                      this._createDeliveryDistances();
                    }}
                  >
                    Save Settings
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

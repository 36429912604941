import React from "react";

import moment from "moment";

import { withRouter } from "react-router-dom";

import LocationFormat from "../../../../utils/LocationFormat";
import BaseTable from "../BaseTable";
import General from "../../../../utils/General";

class CancelledOrdersTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.table = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _renderAddress(address) {
    return (
      <span className="text-dark-75 font-weight-bold line-height-sm">
        {address ? LocationFormat.fullAddress(address) : "-"}
      </span>
    );
  }

  _getColumns() {
    return [
      {
        Header: () => <strong className="header__clr"></strong>,
        id: "id",
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          let orderId = order.id;

          let itemImage = "";

          if (order?.data?.items.length === 1) {
            itemImage = order?.data?.items?.map(
              (orderItem) => orderItem?.item?.image?.original
            );
          } else {
            itemImage = order?.data?.items[0]?.item?.image?.original;
          }

          return (
            <>
              <span style={{ width: 278 }}>
                <div className="d-flex align-items-center">
                  <div className="symbol symbol-40 flex-shrink-0">
                    {itemImage ? (
                      <div
                        className="symbol-label"
                        style={{
                          backgroundImage: `url(${itemImage})`,
                        }}
                      ></div>
                    ) : (
                      <div
                        className="symbol-label"
                        style={{
                          backgroundColor: "whitesmoke",
                          width: 40,
                          height: 40,
                          borderRadius: "10%",
                        }}
                      />
                    )}
                  </div>
                  <div className="ml-2">
                    <div className="text-dark-75 font-weight-bold line-height-sm">
                      Order #{orderId}
                    </div>{" "}
                    <a
                      href="javascript:;"
                      className="font-size-sm text-dark-50 text-hover-primary"
                    >
                      Cancelled by: {order?.venue?.company?.title}{" "}
                    </a>
                    <span> - {order?.venue?.title} </span>
                  </div>
                </div>
              </span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr"></strong>,
        id: "customer.user.first_name",
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          let customer = "";

          if (order?.customer?.user) {
            customer = `${General.toTitleCase(
              order?.customer?.user?.first_name
            )} ${General.toTitleCase(order?.customer?.user?.last_name)}`;
          } else {
            customer = "-";
          }

          return (
            <div className="d-flex align-items-center">
              <div className="ml-2">
                {" "}
                <a
                  href="javascript:;"
                  className="font-size-sm text-dark-50 text-hover-primary"
                >
                  Customer{" "}
                </a>
                <div className="text-dark-75 font-weight-bold line-height-sm">
                  {customer}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        Header: () => <strong className="header__clr"></strong>,
        id: "created_at",
        Cell: (rowInfo) => {
          let order = rowInfo.original;

          let createdAt = `${moment(order?.created_at).format(
            "DD MMM YYYY - hh:mm A"
          )}`;

          if (!order?.created_at) {
            return "-";
          }

          return (
            <>
              <div className="d-flex align-items-center">
                <div className="ml-2">
                  {" "}
                  <a
                    href="javascript:;"
                    className="font-size-sm text-dark-50 text-hover-primary"
                  >
                    Order created at{" "}
                  </a>
                  <div className="text-dark-75 font-weight-bold line-height-sm">
                    {createdAt}
                  </div>
                </div>
              </div>
            </>
          );
        },
      },
    ];
  }

  render() {
    const { isLoading } = this.state;

    const columns = this._getColumns();

    let tableProps = {};

    return (
      <>
        <BaseTable
          {...tableProps}
          ref={this.table}
          title={this.props.title}
          loading={isLoading}
          endpoint={`${window.Api.Orders}?statuses=rejected&rejection_reason=rejected_by_venue`}
          noDataMessage={"No cancelled orders found"}
          columns={columns}
          showPagination={true}
          showSearch={true}
          defaultSorted={[
            {
              id: "created_at",
              desc: true,
            },
          ]}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "Actions" ? "visible" : "hidden",
              },
            };
          }}
        />
      </>
    );
  }
}

CancelledOrdersTable.defaultProps = {
  title: "Cancelled Orders",
  showPagination: true,
  showPaginationTop: true,
  showPaginationBottom: true,
};

export default withRouter(CancelledOrdersTable);

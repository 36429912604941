import React, { Component } from "react";

import Modal from "react-bootstrap/Modal";

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import General from "../../../utils/General";

export default class EditMenuItemModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this._getState(props),
      show: props.show,
      isLoading: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps));
  }

  _getState(props) {
    return {
      ...props,
      data: {
        ...General.clone(props.data),
        on_sale: props.data?.price_sale != null,
      },
    };
  }

  _handleChange(e) {
    const { data } = this.state;

    let value = e.target.value;

    data[e.target.name] = value;

    this.setState({ data });
  }

  _isFormValid() {
    const { data } = this.state;

    const { price, price_sale, on_sale } = data;

    let error = null;

    if (price === null) {
      error = "Please enter a valid price.";
    } else if (on_sale && price_sale === null) {
      error = "Please enter a sale price.";
    }

    if (error) {
      Notify.error(error);
      return false;
    }

    return true;
  }

  _editMenuItem() {
    let { data } = this.state;

    if (!this._isFormValid()) {
      return;
    }

    data = {
      id: data.id,
      price: data.price,
      price_sale: data.price_sale,
    };

    this.setState({ isLoading: true });

    Backend.editMenuItem(data)
      .then((data) => {
        this.setState({ data, isLoading: false });
        Notify.success("Menu item has been updated successfully.");
        this.props.onUpdated();
      })
      .catch((error) => {
        Notify.error(error.message);
        this.setState({ isLoading: false });
      });
  }

  render() {
    let { show, data, isLoading } = this.state;

    if (!data) {
      return null;
    }

    return (
      <Modal
        show={show}
        onHide={() => {
          if (!isLoading) {
            this.props.onHide();
          }
        }}
      >
        <div className="modal-content">
          <Modal.Header closeButton>
            <Modal.Title> Edit Item</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div></div>
            <div className="form-group row">
              <div data-repeater-list className="col-lg-12">
                <div
                  data-repeater-item
                  className="form-group row align-items-center"
                >
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Item Price *</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">€</span>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          name="price"
                          value={data?.price != null ? data?.price / 100 : null}
                          onChange={(e) => {
                            data.price =
                              e.target.value != ""
                                ? Math.round(
                                    parseFloat(e.target.value).toFixed(2) * 100
                                  )
                                : null;

                            this.setState({ data });
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-9 mb-3 mt-15">
                    <label>Is this item on sale?</label>
                  </div>
                  <div className="col-md-3 mb-3 mt-10">
                    <label
                      className="checkbox checkbox-lg"
                      style={{ position: "initial" }}
                    >
                      <input
                        type="checkbox"
                        className="salebox"
                        name="on_sale"
                        checked={data?.on_sale}
                        onClick={(e) => {
                          data.on_sale = e.target.checked;
                          if (!data.on_sale) {
                            data.price_sale = null;
                          }
                          this.setState({ data });
                        }}
                      />
                      <span />
                    </label>
                  </div>
                  {data?.on_sale && (
                    <div className="col-md-12 sale-item mb-3">
                      <label>Sale Price</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text">€</span>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          name="price_sale"
                          placeholder={0.0}
                          value={
                            data?.price_sale != null
                              ? data?.price_sale / 100
                              : null
                          }
                          onChange={(e) => {
                            data.price_sale =
                              e.target.value != ""
                                ? Math.round(
                                    parseFloat(e.target.value).toFixed(2) * 100
                                  )
                                : null;

                            this.setState({ data });
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button
              type="button"
              className="btn btn-outline-primary font-weight-bold"
              onClick={() => this.props.onHide()}
            >
              Close
            </button>

            <button
              type="button"
              className="btn btn-primary font-weight-bold"
              disabled={isLoading}
              onClick={() => this._editMenuItem()}
            >
              Save Changes
            </button>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }
}

import React from "react";

import moment from "moment";

import { withRouter } from "react-router-dom";

import LocationFormat from "../../../../utils/LocationFormat";
import Currency from "../../../../utils/Currency";
import BaseTable from "../BaseTable";
import General from "../../../../utils/General";

class PaymentsHistoryTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.table = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  _renderAddress(address) {
    return <span>{address ? LocationFormat.fullAddress(address) : "-"}</span>;
  }

  _getColumns() {
    return [
      {
        Header: () => <strong className="header__clr">INVOICE NO.</strong>,
        id: "invoice_number",
        Cell: (rowInfo) => {
          let payment = rowInfo.original;

          let invoiceNumber = payment?.id;

          if (!invoiceNumber) {
            return "-";
          }

          return (
            <>
              <span className="text-dark-75 font-weight-bold line-height-sm">
                {invoiceNumber}
              </span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">VENDOR</strong>,
        id: "title",
        Cell: (rowInfo) => {
          let payment = rowInfo.original;

          let company = General.toTitleCase(payment?.company?.title);

          return (
            <>
              <span>{company}</span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">STATUS</strong>,
        id: "status",
        sortable: false,
        Cell: (rowInfo) => {
          let payment = rowInfo.original;

          let labelClass = "";
          let status = "";

          if (payment?.paid_at !== null) {
            labelClass = "label-light-success";
            status = "Paid";
          }

          if (payment?.refunded_at !== null) {
            labelClass = "label-danger";
            status = "Refunded";
          }

          if (!status) {
            return "-";
          }

          return (
            <span className={`label ${labelClass} label-inline label-pill`}>
              {status}
            </span>
          );
        },
      },

      {
        Header: () => <strong className="header__clr">PAID</strong>,
        id: "paid_at",
        Cell: (rowInfo) => {
          let payment = rowInfo.original;
          let paidAt = `${moment(payment?.paid_at).format(
            "DD MMM YYYY - hh:mm A"
          )}`;

          if (!payment?.paid_at) {
            return "-";
          }

          return (
            <>
              <span className="text-dark-75 font-weight-bold line-height-sm">
                {paidAt}
              </span>
            </>
          );
        },
      },
      {
        Header: () => <strong className="header__clr">REFUNDED</strong>,
        id: "refunded_at",
        Cell: (rowInfo) => {
          let payment = rowInfo.original;

          let refundedAt = `${moment(payment?.refunded_at).format(
            "DD MMM YYYY - hh:mm A"
          )}`;

          if (!payment?.refunded_at) {
            return "-";
          }

          return (
            <>
              <span className="text-dark-75 font-weight-bold line-height-sm">
                {refundedAt}
              </span>
            </>
          );
        },
      },

      {
        Header: () => <strong className="header__clr">AMOUNT</strong>,
        id: "amount",
        sortable: false,
        Cell: (rowInfo) => {
          let payment = rowInfo.original;

          let amount = Currency.format(payment?.total);

          if (!amount) {
            return "-";
          }

          return (
            <>
              <span>{amount}</span>
            </>
          );
        },
      },
    ];
  }

  render() {
    const { isLoading } = this.state;

    const columns = this._getColumns();

    let tableProps = {};

    return (
      <>
        <BaseTable
          {...tableProps}
          ref={this.table}
          title={this.props.title}
          loading={isLoading}
          endpoint={`${window.Api.Payments}`}
          noDataMessage={"No payment history found"}
          columns={columns}
          showPagination={true}
          defaultSorted={[
            {
              id: "created_at",
              desc: true,
            },
          ]}
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              style: {
                paddingLeft: 10,
                overflow: column.Header === "Actions" ? "visible" : "hidden",
              },
            };
          }}
        />
      </>
    );
  }
}

PaymentsHistoryTable.defaultProps = {
  title: "Payment History",
  showPagination: true,
  showPaginationTop: true,
  showPaginationBottom: true,
};

export default withRouter(PaymentsHistoryTable);

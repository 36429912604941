import React, { Component } from "react";

import General from "../../../utils/General";

export default class StatsCard extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div
        className="card card-custom bgi-no-repeat card-stretch gutter-b"
        style={{
          backgroundPosition: "right top",
          backgroundSize: "30% auto",
          backgroundImage: "url(../assets/media/svg/shapes/abstract-1.svg)",
        }}
      >
        <div className="card-body">
          {this.props.data?.map((item) => {
            return (
              <div key={General.uuid()}>
                <span className="svg-icon svg-icon-primary svg-icon-2x">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    {item?.icon}
                  </svg>
                </span>

                <h3 className="card-title font-weight-bolder mb-0 mt-6 d-block">
                  {item?.statsInfo}
                </h3>
                <span className="font-weight-bolder font-size-lg">
                  {item?.title}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
